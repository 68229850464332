import { useState } from "react";
// import Breadcrumb from "../../../../../components/console/customer-services/customer-mngmnt/Breadcrumb";
import WrapperCard from "../../../../../components/console/customer-services/customer-mngmnt/WrapperCard";
import UserListTabs from "./UserListTabs";
import UserAccStatus from "../../../../../components/console/customer-services/customer-mngmnt/UserAccStatus";
import Breadcrumb from "../../../../../components/common/Breadcrumb";

export function Layout() {
  const [state, setState] = useState("Customer details");
  const [userDetails, setUserDetails] = useState("");

  return (
    <>
      <div className="">
        <div className="w-full flex  justify-center items-center ">
          <div className="w-[calc(100vw-200px)] space-y-4 ">
            {/* <Breadcrumb parent={"User list"} child={userDetails?.fullName} /> */}
            {/* <Breadcrumb
                module={"Dashboard"}
                subModule={"User management"}
                parent={"UserList"}
                child={userDetails?.fullName || "user"}
              /> */}

            <WrapperCard name={userDetails?.fullName || " New User"} />
          </div>
        </div>

        <div className="  flex justify-center mx-auto mt-4  ">
          <UserListTabs
            setState={setState}
            state={state}
            setUserDetails={setUserDetails}
          />
        </div>
        {/* {state == "Customer details" && (
          <div className="w-full flex  justify-center items-center mt-10 ">
            <div className="w-[calc(100vw-300px)] space-y-4 ">
              <UserAccStatus/>
            </div>
          </div>
        )} */}
      </div>
    </>
  );
}
