import React from "react";
import { Card } from "@material-tailwind/react";

import CustomPagination from "../../../components/common/CustomPagination";
import { SurveyListing } from "./SurveyListing";

const AppAnnouncementsSurveys = () => {
  return (
      <div className="surveys-page-wrap flex justify-center items-center mx-auto   ">
        <div className="">
          <Card className=" overflow-x-scroll hide_scroll  rounded-none rounded-t-2xl ">
            <div className="py-4">
              <div className="flex justify-between px-4 ">
                <div className="flex items-center gap-3">
                  <h1 className="text-[24px] font-semibold">
                     Surveys
                  </h1>
                </div>

                <div className="flex gap-5 justify-between  "></div>
              </div>
            </div>
          </Card>
          {<SurveyListing />}
          <Card className="py-5 rounded-none  rounded-b-2xl ">
            <span className="bg-[#E6E6E6] py-3  px-2 w-72 items-center ml-3  rounded-xl ">
              <CustomPagination />
            </span>
          </Card>
        </div>
      </div>
  );
};

export default AppAnnouncementsSurveys;
