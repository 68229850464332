import React from "react";
import { Editor } from "@tinymce/tinymce-react";
// TinyMCE so the global var exists
import "tinymce/tinymce";
// DOM model
import "tinymce/models/dom/model";
// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles
import "tinymce/skins/ui/oxide/skin";

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autosave";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/codesample";
import "tinymce/plugins/directionality";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/help";
import "tinymce/plugins/help/js/i18n/keynav/en";
import "tinymce/plugins/image";
import "tinymce/plugins/importcss";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/preview";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/table";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/wordcount";

// importing plugin resources
import "tinymce/plugins/emoticons/js/emojis";

// Content styles, including inline UI like fake cursors
import "tinymce/skins/content/default/content";
import "tinymce/skins/ui/oxide/content";
import { uploadImage } from "../../helper";

const CustomEditor = ({
  initialValue = "",
  onChange = () => {},
  readonly = false,
}) => {
  return (
    <div className="editor mb-3">
      <Editor
        // apiKey={process.env.REACT_APP_EDITOR_TOKEN}
        plugins="advlist autolink lists image media charmap preview anchor
                      searchreplace visualblocks code fullscreen tabfocus template
                      insertdatetime media table paste code help wordcount link autosave nonbreaking autoresize directionality"
        initialValue={initialValue}
        init={{
          branding: false,
          height: 300,
          readonly: true,

          toolbar:
            "undo redo | formatselect | h1 h2 h3 h4 h5 h6 | bold italic backcolor | \
                                  alignleft aligncenter alignright alignjustify | \
                                  bullist numlist outdent indent | removeformat | image | media",
          browser_spellcheck: true,
          image_title: true,
          template_mdate_format: "%m/%d/%Y : %H:%M",
          templates: [
            {
              title: "Date modified example",
              description:
                "Adds a timestamp indicating the last time the document modified.",
              content:
                '<p>Last Modified: <time class="mdate">This will be replaced with the date modified.</time></p>',
            },
          ],
          automatic_uploads: true,
          file_picker_types: "image media",
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*,video/*,pdf/*");
            input.onchange = function () {
              var file = this.files[0];
              var reader = new FileReader();
              reader.onload = async function () {
                  let response = await uploadImage(file)
                cb(response, { title: file.name });
              };
              reader.readAsDataURL(file);
            };
            input.click();
          },
          color_picker_callback: function (callback, value) {
            // Customize the color picker to use hexadecimal color codes
            if (value) {
              // If a color is selected, convert it to hexadecimal
              if (value.indexOf("rgb") === 0) {
                // Parse the RGB value
                const rgb = value.match(/(\d+),\s*(\d+),\s*(\d+)/);
                if (rgb) {
                  const hexColor = `#${parseInt(rgb[1], 10)
                    .toString(16)
                    .padStart(2, "0")}${parseInt(rgb[2], 10)
                    .toString(16)
                    .padStart(2, "0")}${parseInt(rgb[3], 10)
                    .toString(16)
                    .padStart(2, "0")}`;
                  callback(hexColor);
                }
              } else {
                // If it's already in hexadecimal format, pass it through
                callback(value);
              }
            }
          },
          promotion: false,
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomEditor;
