import React, { useEffect, useState } from "react";
// import Breadcrumb from "../../../../components/console/customer-services/customer-mngmnt/Breadcrumb";
import ContainerHeading from "../../../../components/common/container-heading/ContainerHeading";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Error from "../../../../components/common/Error";
import { fetchCategoriesList } from "../../../../api/categories/categories";
import { addFaq, editFAQ, viewFAQ } from "../../../../api/content-mgmt/faq";
import CustomEditor from "../../../../components/common/CustomEditor";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import { errorToast, successToast } from "../../../../utils/toast";

// schema

const addFaqSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  category: yup
    .array()
    .nullable()
    .of(yup.string().required("Category is required"))
    .min(1, "Select at least one category")
    .required("Category is required"),
  question: yup.string().required("Question is required"),
  answer: yup.string().required("Answer is required"),
});

const AddNewFAQ = () => {
  // router functions
  const navigate = useNavigate();
  const location = useLocation();
  // faq id from params
  const { id } = useParams();
  console.log(id, "params");
  console.log(location);

  //   check path
  const isEdit = location?.pathname?.indexOf("edit-faq") > -1 ? true : false;

  // local state
  const [categories, setCategories] = useState([]);

  // sideEffect
  useEffect(() => {
    if (isEdit) {
      getFAQ();
    }
    getCategories();
  }, []);

  useEffect(() => {
    setValue("category", []);
  }, []);

  //useform functions
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addFaqSchema),
  });

  //   get faq from id
  const getFAQ = async () => {
    let response = await viewFAQ(id);
    console.log(response, "single faq data");
    setValue("title", response?.data?.title);
    setValue("category", response?.data?.category);
    setValue("question", response?.data?.question);
    setValue("answer", response?.data?.answer);
  };

  console.log(getValues(), "val ");
  // handle edit faq
  const handleEditFAQ = async (values) => {
    let payload = {
      id,
      title: values.title,
      category: values.category,
      question: values.question,
      answer: values.answer.replace(/<[^>]+>/g, ""),
      section_description: "",
      section_heading: "",
    };

    const response = await editFAQ(payload);
    if (response.status_code === 200) {
      successToast(response?.message);
      navigate("/content/page-mgmt/faq");
    } else if (response.status_code === 400) {
      errorToast(response?.message);
    }
  };

  // handle add new faq
  const handleAddFAQ = async (values) => {
    let payload = {
      title: values.title,
      category: values.category,
      question: values.question,
      answer: values.answer.replace(/<[^>]+>/g, ""),
      section_description: "",
      section_heading: "",
    };

    const response = await addFaq(payload);
    if (response.status_code === 200) {
      successToast(response?.message);
      navigate("/content/page-mgmt/faq");
    } else if (response.status_code === 400) {
      errorToast(response?.message);
    }
  };

  // form submit function
  const onSubmit = handleSubmit((values) => {
    if (isEdit) handleEditFAQ(values);
    else handleAddFAQ(values);
  });

  // get categories from api
  const getCategories = async () => {
    let response = await fetchCategoriesList();
    setCategories(response?.data);
  };

  // console
  console.log(errors, "errors");
  // console.log(getValues(), "useform values");
  // console.log(categories, "Categories");

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div className=" w-full  hide_scroll  overflow-y-auto">
          {/* <Breadcrumb
              module={"content management"}
              subModule={"Page Management"}
              parent={"FAQ"}
              child={isEdit ? "Edit FAQ" : "Add FAQ"}
            /> */}
          <div className="mt-0">
            <ContainerHeading
              className={"rounded-lg"}
              title={isEdit ? "Upadte FAQ Section" : " Add New FAQ Section"}
            />
          </div>
          {/* forms */}
          <form onSubmit={onSubmit}>
            <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4 pl-4  ">
              {/* title */}
              <>
                <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 ">
                  FAQ Title
                </div>
                <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                  <input
                    className="  w-[697px] max-w-full outline-none bg-white"
                    placeholder="FAQ Title"
                    {...register("title")}
                  />
                </div>
                <Error error={errors?.title} message={errors?.title?.message} />
              </>

              {/* category */}
              <>
                <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                  Category
                </div>
                <>
                  <div className="items-start content-start flex-wrap flex justify-between gap-5 pr-20 pb-4 max-md:pr-5 mt-4">
                    <div className="self-stretch flex grow basis-[0%] flex-row flex-wrap items-stretch gap-3">
                      {categories?.map((category, category_index) => {
                        return (
                          <>
                            {/* <div
                              key={category?._id}
                              className="items-stretch flex justify-between gap-3"
                            >
                              <div className="items-center border border-[color:var(--Grey-800,#141414)] bg-neutral-50 flex aspect-[1.04] flex-col justify-center w-[26px] h-[26px] px-2.5 rounded-md border-solid">
                                <input
                                  type="radio"
                                  name="category"
                                  value={category?._id}
                                  {...register("category")}
                                />
                              </div>
                              <div className="text-neutral-900 text-base leading-6 grow whitespace-nowrap self-start">
                                {category?.name}
                              </div>
                            </div> */}
                            {/*  */}

                            {/* checkbox */}
                            <div class="flex gap-10">
                              <div class="inline-flex items-center">
                                <label
                                  class="relative flex items-center p-3 rounded-full cursor-pointer"
                                  htmlFor={`html${category_index}`}
                                >
                                  <div
                                    // className="items-center border border-[color:var(--Grey-800,#141414)] bg-neutral-50 flex  w-[39px] h-[21px] px-2.5  border-solid"
                                    className="flex items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border-2 border-black text-gray-900 transition-all before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:border-gray-900  hover:before:opacity-100 after:rounded-full after:top-4"
                                      // className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-black text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-100"
                                      id={`html${category_index}`}
                                      name="category"
                                      value={category?._id}
                                      {...register("category")}
                                    />

                                    <span class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-3.5 w-3.5"
                                        viewBox="0 0 16 16"
                                        fill="currentColor"
                                      >
                                        <circle
                                          data-name="ellipse"
                                          cx="8"
                                          cy="8"
                                          r="8"
                                        ></circle>
                                      </svg>
                                    </span>
                                  </div>
                                </label>
                                <label
                                  class="mt-px font-light text-gray-700 cursor-pointer select-none"
                                  htmlFor={`html${category_index}`}
                                >
                                  {category?.name}
                                </label>
                              </div>
                            </div>

                            {/* radio */}
                            {/* <div class="flex gap-10">
                              <div class="inline-flex items-center">
                                <label
                                  class="relative flex items-center p-3 rounded-full cursor-pointer"
                                  htmlFor={`html${category_index}`}
                                >
                                  <div className="items-center border border-[color:var(--Grey-800,#141414)] bg-neutral-50 flex  w-[21px] h-[21px] px-2.5 rounded-md border-solid">
                                    <input
                                      type="radio"
                                      className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-black text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-100"
                                      id={`html${category_index}`}
                                      name="category"
                                      value={category?._id}
                                      {...register("category")}
                                    />

                                    <span class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-3.5 w-3.5"
                                        viewBox="0 0 16 16"
                                        fill="currentColor"
                                      >
                                        <circle
                                          data-name="ellipse"
                                          cx="8"
                                          cy="8"
                                          r="8"
                                        ></circle>
                                      </svg>
                                    </span>
                                  </div>
                                </label>
                                <label
                                  class="mt-px font-light text-gray-700 cursor-pointer select-none"
                                  htmlFor={`html${category_index}`}
                                >
                                  {category?.name}
                                </label>
                              </div>
                            </div> */}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <Error
                    error={errors?.category}
                    message={errors?.category?.message}
                  />
                </>
              </>

              {/* add new title */}
              {/* <>
                <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 ">
                  Add new category
                </div>
              </> */}

              {/* question */}
              <>
                <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                  Question
                </div>
                <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                  <input
                    className="  w-[697px] max-w-full bg-white outline-0"
                    placeholder="Question"
                    {...register("question")}
                  />
                </div>
                <Error
                  error={errors?.question}
                  message={errors?.question?.message}
                />
              </>

              {/* answer */}
              <>
                <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                  Answer
                </div>
                <>
                  {" "}
                  <div className="w-3/4 pt-2">
                    <CustomEditor
                      initialValue={getValues().answer}
                      onChange={(value) => {
                        setValue("answer", value.target.getContent());
                        trigger("answer");
                      }}
                    />
                  </div>
                  <Error
                    error={errors?.answer}
                    message={errors?.answer?.message}
                  />
                </>
              </>

              {/* footer-button */}
              <div className="flex flex-row">
                <>
                  <div
                    className=" bg-white flex flex-col gap-1 w-full  items-start mr-2 rounded-lg mb-2  cursor-pointer"
                    onClick={() => navigate(-1)}
                  >
                    <div className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white text-black px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 ">
                      Cancel
                    </div>
                  </div>
                </>

                <>
                  <div className=" bg-white flex flex-col gap-1 w-full  items-start mr-2 rounded-lg mb-2  ">
                    <button
                      type="submit"
                      className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-black text-white px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 cursor-pointer"
                    >
                      {isEdit ? "Update" : "Save"}
                    </button>
                  </div>
                </>
              </div>

              {/*  */}

              {/*  */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddNewFAQ;
