import { useEffect, useState } from "react";
import { Card, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import Toggle from "react-toggle";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import "react-toggle/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAllPilots,
  ondeletePilots,
} from "../../../api/flight-management/flight";
import NoDataFound from "../../../components/common/NoDataFound";
import { generateConfirm } from "../../../utils/reactConfirmAlert";
import { errorToast, successToast } from "../../../utils/toast";
import CustomPagination from "../../../components/common/CustomPagination";
import ContainerHeading from "../../../components/common/container-heading/ContainerHeading";
import { useSelector } from "react-redux";

const TABLE_HEAD = [
  "Full Name",
  // "Email",
  // "Mobile number",
  // "Membership",
  "Action",
];

const PilotProfile = () => {
  const { loader } = useSelector((state) => state.loader);
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(1);
  const [pilotList, setPilotList] = useState([]);
  const [skip, setSkip] = useState(1);

  useEffect(() => {
    getPilotsList();
  }, [skip]);

  const getPilotsList = async () => {
    let payload = {
      skip,
      limit: 10,
      search: "",
    };
    const response = await getAllPilots(payload);
    if (response?.status_code === 200) {
      setPilotList(response?.data || []);
      setPageCount(Math.ceil(response?.totalItems / payload.limit) || 0);
    }
  };

  const deletePilot = (id) => {
    generateConfirm(
      "Are you sure?",
      `You want to delete this Pilot`,
      1,
      async () => {
        let payload = {
          id,
        };
        const response = await ondeletePilots(payload);
        if (response?.status_code === 200) {
          successToast(response?.message);
          getPilotsList();
        } else {
          errorToast(response?.message);
        }
      }
    );
  };

  return (
    <>
        <div className="common-bg rounded-t-2xl">
          {/* <div className="p-3 d-flex justify-content-between">
            <h1 className="common-heading">Pilot Profile</h1>
            <button
              className="add-user-button"
              onClick={() => navigate("/flights/pilot-profile/add-new-pilot")}
            >
              Add Pilot
            </button>
          </div> */}
          {/* header */}
          <ContainerHeading
            className="rounded-t-lg"
            redirect={"/flights/pilot-profile/add-new-pilot"}
            title={"Pilot Profiles"}
            button={"Pilot"}
          />
          <Card className=" !z-5 overflow-x-auto rounded-none">
            <table className="w-full min-w-max table-auto text-left">
              <thead className="w-full">
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-t border-blue-gray-100 px-3 text-start py-3 bg-[#d3d3d3]"
                    >
                      <Typography
                        variant="small"
                        color="black"
                        className="font-normal leading-none font-semibold"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {pilotList?.map((item, index) => {
                  const isLast = index === pilotList.length - 1;
                  const classes = isLast
                    ? "py-2 px-3"
                    : "py-2 px-3 border-b border-blue-gray-50";

                  return (
                    <tr key={index}>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.first_name + " " + item?.last_name || "N/A"}
                        </Typography>
                      </td>
                      {/* <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.email || "N/A"}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.phone || "N/A"}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className={`font-normal ${
                            item?.isMember === true
                              ? "bg-[#90CAF9]"
                              : "bg-[#cccbca]"
                            // : status == "In Progress"
                            // ? "bg-[#B9F6CA]"
                            // : "bg-[#EAEAEA]"
                          }  py-1 rounded-[90px] text-center w-[90px]`}
                        >
                          {item?.isMember == false ? "Free Preview" : "Member"}
                        </Typography>
                      </td> */}
                      <td className={classes}>
                        <div class="action-toggle">
                          <FontAwesomeIcon
                            onClick={() => {
                              deletePilot(item?._id);
                            }}
                            className="delete-btn ms-3"
                            icon={faTrash}
                          />
                          <FontAwesomeIcon
                            onClick={() => {
                              navigate(
                                `/flights/pilot-profile/edit-pilot/${item?._id}`
                              );
                            }}
                            className="edit-btn ms-3"
                            icon={faPen}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {pilotList.length === 0 && !loader && <NoDataFound />}
            </table>
          </Card>
        </div>
      {/* pagination */}

      <div className="bg-white flex flex-col gap-1 w-full   items-end pt-2 px-2   rounded-b-lg   ">
        {pageCount > 1 && (
          <Card className="py-2 rounded-none   ">
            <span className="bg-[#E6E6E6] py-3  px-2 w-auto items-center ml-3  rounded-xl ">
              <CustomPagination
                page={skip}
                setPage={setSkip}
                count={pageCount}
              />
            </span>
          </Card>
        )}
      </div>
    </>
  );
};

export default PilotProfile;
