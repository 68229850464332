import React, { useState, useEffect, useRef } from "react";
// import Breadcrumb from "../../components/console/customer-services/customer-mngmnt/Breadcrumb";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ReactPhoneInput from "react-phone-input-2";
import parsePhoneNumber from "libphonenumber-js";
import { Country } from "country-state-city";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getAdminById,
  getAllRoles,
  onAddSubadmin,
  updateAdmin,
} from "../../../api/subadmin/subadmin";
import Error from "../../../components/common/Error";
import { errorToast, successToast } from "../../../utils/toast";
import Breadcrumb from "../../../components/common/Breadcrumb";
import ContainerHeading from "../../../components/common/container-heading/ContainerHeading";
import {
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllUsers } from "../../../api/users";
import ImageUpload from "../../../components/common/ImageUpload";
import { uploadImageTemplate } from "../../../helper";
import { getAnnouncement, sendAnnouncement } from "../../../api/notifications";

const announcementSchmea = yup.object().shape({
  title: yup
    .string()
    .required("TItle is Required")
    .max(50, "Max 50 characters required"),
  message: yup
    .string()
    .required("Descroption is Required")
    .max(200, "Max 200 characters required"),

  user: yup.string().required("This is required field"),
  user_ids: yup
    .array()
    .of(yup.object())
    .min(1, "Minimum 1 required")
    .required("This field is required"),
  type: yup.string().required(" Type is Required"),
  image: yup.mixed(),
  // userList: yup.string(),
});

const AddAnnouncement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState(false);
  const [viewData, setViewData] = useState({});
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(false);

  const editRoute =
    location?.pathname?.indexOf(`/dashboard/announcements/edit-announcements`) >
    -1
      ? true
      : false;
  const viewRoute =
    location?.pathname?.indexOf(`/dashboard/announcements/view-announcements`) >
    -1
      ? true
      : false;

  useEffect(() => {
    getUserList();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(announcementSchmea),
  });

  // fn to  select  user
  const handleUserSelect = (id) => {
    if (id) {
      let data = userList.map((item) =>
        item._id === id ? { ...item, checked: !item.checked } : item
      );
      // setSelectedUserList(data);
      setUserList(data);
      setValue("user_ids", data);
    } else {
      let data = userList.map((item) => ({
        ...item,
        checked: !item.chceked,
      }));
      setUserList(data);
      setValue("user_ids", data);
    }

    // setValue("user_ids", data);
  };

  // view announcement on id
  const viewAnnouncement = async (userList) => {
    let response = await getAnnouncement({ id });
    if (response?.status_code === 200) {
      userList.forEach((item) => {
        response?.data?.user_ids.forEach((userId) => {
          if (item._id === userId._id) {
            item.checked = true;
          }
        });
      });
      setViewData(response?.data);
      setValue("title", response?.data.title);
      setValue("message", response?.data?.message);
      setValue("type", response?.data?.type);
      setValue("image", response?.data?.image);
      setValue("user_ids", response?.data?.user_ids);
      if (userList?.length === response?.data?.user_ids?.length) {
        setValue("user", "ALL");
      } else {
        setValue("user", "SELECTED");
        setSelectedUser(true);
      }
    }
  };

  // function to add new announcement
  const addNewAnnouncement = async (values) => {
    console.log(values, "submited values");
    const formData = new FormData();
    if (editRoute) formData.append("_id", id);
    Object.keys(values).forEach((key) => {
      if (key !== "user") {
        if (key === "user_ids") {
          let valueData = userList
            .filter((item) => item.checked)
            .map((item) => item._id);
          let dataToString = valueData.toString();
          formData.append("user_ids", dataToString);
        } else if (key === "image") {
          formData.append(key, viewData?.image || values[key][0]);
        } else {
          formData.append(key, values[key]);
        }
      }
    });

    const response = await sendAnnouncement(formData);
    if (response.status_code === 200) {
      successToast("Added successfully");
      navigate("/dashboard/announcements");
    }
  };

  const onSubmit = handleSubmit((values) => {
    if (!viewRoute) addNewAnnouncement(values);
  });

  const getUserList = async () => {
    let payload = {
      skip: 1,
      limit: 1000,
      isMember: "",
      search: "",
    };
    const response = await getAllUsers(payload);
    if (response?.status_code === 200) {
      let data = response?.data?.map((item) => ({
        ...item,
        _id: item.id,
        checked: false,
      }));
      // setSelectedUserList(data);
      // setUserList(response?.data || []);
      setUserList(data);
      if (editRoute || viewRoute) {
        viewAnnouncement(data);
      }
    }
  };

  // log
  console.log(errors, "errors");
  // console.log(userList, "userlist");
  // console.log(selectedUserList, "selectedList");
  console.log(userList, "usr list from api");

  return (
    <>
        <form onSubmit={onSubmit}>
          <div className="common-bg">
            {/* <Breadcrumb parent={"Subadmin"} child={"Add-Subadmin"} /> */}
            {/* <Breadcrumb
              module={"Dashboard"}
              subModule={"Announcements"}
              parent={viewRoute ? "View" : editRoute ? "Edit" : "Add"}
            /> */}
            <div
              id="WrapperRoot"
              className="bg-white flex flex-row justify-between w-full h-20 items-start p-3 rounded-lg"
            >
              {/* left container */}
              <div>
                <div className="text-2xl font-['Hauora'] font-bold text-[#2e2e2e]">
                  {viewRoute
                    ? "View Announcements"
                    : editRoute
                    ? " Edit Announcements"
                    : " Add Announcements"}
                  {}
                </div>
              </div>

              {/* right container */}
              <div className="flex flex-row justify-center align-middle items-center gap-2">
                {/* button */}
                {true && (
                  <>
                    {/* cancel button */}
                    <button
                      className=" bg-black justify-center items-stretch bg-neutral-900 flex gap-2 px-3 py-2.5 rounded-lg cursor-pointer"
                      onClick={() => navigate(-1)}
                      type="button"
                    >
                      <span className="text-white text-center text-sm font-medium leading-5 self-center grow whitespace-nowrap my-auto">
                        {"Cancel"}
                      </span>
                    </button>

                    {/* save buttton */}
                    {!viewRoute && (
                      <button className=" bg-black justify-center items-stretch bg-neutral-900 flex gap-2 px-4 py-2.5 rounded-lg cursor-pointer  ">
                        <span
                          className="text-white text-center text-sm font-medium leading-5 self-center grow whitespace-nowrap my-auto "
                          type="submit"
                        >
                          {editRoute ? "Update" : "Add"}
                        </span>
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="ml-3">
              {/* image */}
              <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 mb-1 ">
                Logo
              </div>
              <div className="row">
                <div className="col-md-5 ">
                  <ImageUpload
                    className="profile_Image "
                    inputId={`announcement_profileimage`}
                    imgId={`announcement_profilepic`}
                    src={viewData?.image || uploadImageTemplate}
                    name={`image`}
                    setValue={setValue}
                    register={register}
                    type="upload"
                    disabled={viewRoute ? true : false}
                    // trigger={trigger}
                  />
                </div>
                <div className="col-md-7"></div>
              </div>

              {/* title */}
              <>
                <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                  Title
                </div>
                <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[600px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                  <input
                    className="  w-[697px] max-w-full outline-none bg-white"
                    placeholder="Title"
                    {...register("title")}
                    disabled={viewRoute ? true : false}
                  />
                </div>
                <Error error={errors?.title} message={errors?.title?.message} />
              </>
              {/* description */}
              <>
                <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                  Description
                </div>
                <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[600px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                  <input
                    className="  w-[697px] max-w-full outline-none bg-white"
                    placeholder="description"
                    {...register("message")}
                    disabled={viewRoute ? true : false}
                  />
                </div>
                <Error
                  error={errors?.message}
                  message={errors?.message?.message}
                />
              </>

              {/* announcement_type */}
              <>
                <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                  Announcement Type
                </div>
                <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[600px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                  <select
                    className="  w-[697px] max-w-full outline-none bg-white"
                    placeholder="Enter job name"
                    {...register("type")}
                    disabled={viewRoute ? true : false}
                  >
                    <option disabled>select cateogry</option>
                    <option className="capitalize">announcement</option>
                    <option className="capitalize">short-message</option>
                  </select>
                </div>
                <Error error={errors?.type} message={errors?.type?.message} />
              </>
            </div>

            {/*  */}

            {/* <div className="row px-3">
              <div className="col-md-4 mb-3">
                <input
                  className="form-control"
                  placeholder="Title"
                  {...register("first_name")}
                />
                <Error
                  error={errors?.first_name}
                  message={errors?.first_name?.message}
                />
              </div>
              <div className="col-md-4 mb-3">
                <input
                  className="form-control"
                  placeholder="Description"
                  {...register("last_name")}
                />
                <Error
                  error={errors?.last_name}
                  message={errors?.last_name?.message}
                />
              </div>
            </div> */}
            <div className="text-2xl font-['Hauora'] font-semibold text-[#2e2e2e] mb-3 mt-4 ps-5">
              User Type
            </div>
            <div className="row">
              <div className="col-md-6 border-shadow ">
                <div className="row px-3">
                  <div className="col-md-6">
                    <div class=" user-type flex-col w-fit">
                      <Form className="check-label">
                        {["radio"].map((type) => (
                          <div
                            key={`block-${type}`}
                            className="mb-3 user-type flex-col"
                          >
                            <div>
                              <Form.Check
                                block
                                label="All Users"
                                name="group1"
                                type={type}
                                id={`block-${type}-1`}
                                value="ALL"
                                disabled={viewRoute ? true : false}
                                // {...register("user_ids")}
                                {...register("user")}
                                onChange={(e) => {
                                  setSelectedUser(false);
                                  handleUserSelect();
                                }}
                                // onChange={handleUserSelect}
                              />
                            </div>
                            <div>
                              <Form.Check
                                block
                                label="Individual User"
                                name="group1"
                                type={type}
                                id={`block-${type}-2`}
                                value="SELECTED"
                                disabled={viewRoute ? true : false}
                                // {...register("user_ids")}
                                {...register("user")}
                                onChange={() => {
                                  setSelectedUser(true);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </Form>
                      {selectedUser && (
                        <div className="notification-select col-md-10 ps-3 mb-2 ">
                          <div className="position-relative select-d mb-10">
                            <input
                              placeholder="Select Users"
                              className="form-control"
                              disabled={true}
                            />
                            <div className="eyeicon">
                              {users != true ? (
                                <FontAwesomeIcon
                                  icon={faAngleDown}
                                  onClick={() => setUsers(true)}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faAngleUp}
                                  onClick={() => setUsers(false)}
                                />
                              )}
                            </div>
                          </div>
                          {users ||
                            (selectedUser && (
                              <>
                                <div className="search">
                                  <FontAwesomeIcon
                                    className="search-icon"
                                    icon={faSearch}
                                  />
                                  <input
                                    placeholder="search"
                                    className="form-control"
                                    onChange={(e) => setSearch(e.target.value)}
                                  />
                                </div>
                                <div className="table">
                                  <table className="table">
                                    {userList?.map((item, index) => (
                                      <tr>
                                        <td>
                                          <Form className=" check-label p-0">
                                            {["checkbox"].map((type) => (
                                              <div
                                                key={`block-${type}`}
                                                className=""
                                              >
                                                <Form.Check
                                                  block
                                                  label={index + 1}
                                                  name="group1"
                                                  type={type}
                                                  id={`block-${type}-1`}
                                                  checked={
                                                    item?.checked === true
                                                      ? true
                                                      : false
                                                  }
                                                  disabled={
                                                    viewRoute ? true : false
                                                  }
                                                  // onChange={(e) => multipleUser(e, index)}
                                                  onChange={() =>
                                                    handleUserSelect(item?.id)
                                                  }
                                                />
                                              </div>
                                            ))}
                                          </Form>
                                        </td>
                                        <td>{item?.fullName}</td>
                                        <td>
                                          {item?.preferredFirstName || "N/A"}
                                        </td>
                                      </tr>
                                    ))}
                                  </table>
                                </div>
                              </>
                            ))}
                        </div>
                      )}
                    </div>
                    <Error
                      error={errors?.user}
                      message={errors?.user?.message}
                    />
                    {!errors?.user && (
                      <Error
                        error={errors?.user_ids}
                        message={errors?.user_ids?.message}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
    </>
  );
};

export default AddAnnouncement;
