import React, { useEffect, useState } from "react";
import ContainerHeading from "../../../../../components/common/container-heading/ContainerHeading";
import CustomPagination from "../../../../../components/common/CustomPagination";
import { Card, Tooltip, Typography } from "@material-tailwind/react";
import { faEye, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getUserJobList,
  userJobsList,
} from "../../../../../api/content-mgmt/careers";
import PopUpLayout from "../../../../dashboard/user-mgmt/PopUpLayout";
import NoDataFound from "./../../../../../components/common/NoDataFound";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// table heading
const TABLE_HEAD = ["Id", "Name", "Phone No.", "Email", "Status", "Action"];

const JobApplicationListing = () => {
  // values from redux
  const { loader } = useSelector((state) => state.loader);
  // local state
  const [jobApplicationList, setJobApplicationList] = useState([]);
  const [open, setOpen] = useState(false);
  const [skip, setSkip] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState("");
  const [uniqueUserDetails, setUniqueUserDetails] = useState();

  // side effect
  useEffect(() => {
    getJobApplicationList();
  }, [skip, search]);

  // user detail on id
  const getUserDetail = async (id) => {
    let response = await getUserJobList(id);
    setUniqueUserDetails(response?.data);
    setOpen(true);
  };

  // function for job application list
  const getJobApplicationList = async () => {
    let payload = {
      skip: skip,
      limit,
      search: search,
    };
    let response = await userJobsList(payload);
    setJobApplicationList(response?.data);
    const count = Math.ceil(response?.itemsCount / limit);
    setPageCount(count);
  };
  return (
    <>
      <div className="bg-white flex flex-col gap-1 w-[calc(100vw-220px)]  items-start pt-2 max-h-[calc(100vh-190px)]  rounded-none   hide_scroll  overflow-y-auto">
        <ContainerHeading
          title={"Job Application List"}
          search={true}
          searchValue={search}
          setSearchValue={setSearch}
        />
        {jobApplicationList?.length ? (
          <>
            <div className="bg-white flex flex-col  w-full  items-start pt-2 px-2 rounded-lg ">
              <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-2  ">
                {/* lisitng table */}
                <div>
                  <div
                    className=" !z-5 
              overflow-x-scroll hide_scroll rounded-none 
                w-[calc(100vw-220px)] 
               "
                  >
                    {/*   */}
                    <table className="w-full min-w-max  table-auto text-left">
                      <thead>
                        <tr>
                          {TABLE_HEAD.map((head) => (
                            <th
                              key={head}
                              className="border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]"
                            >
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-semibold leading-none "
                              >
                                {head}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {jobApplicationList?.map((user, index) => {
                          const isLast = index === 1;
                          const classes = isLast
                            ? "py-2 px-3"
                            : "py-2 px-3 border-b  border-blue-gray-50";

                          return (
                            <tr
                              key={user?._id}
                              className={
                                user?.isRead === "false" ? "bg-[#F0F0F2]" : ""
                              }
                            >
                              <td className={classes + " cursor-pointer"}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {user?._id}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {`${user?.first_name} ${user?.last_name}`}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {`${user?.phone_code} ${user?.phone}`}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {user?.email}
                                </Typography>
                              </td>

                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className={`font-normal ${
                                    user?.status === "Scheduled"
                                      ? "bg-[#90CAF9]"
                                      : user?.status === "Published"
                                      ? "bg-[#B9F6CA]"
                                      : user?.status === "Draft"
                                      ? "bg-[#FFF8E1]"
                                      : user?.status === "active"
                                      ? "bg-[#B9F6CA]"
                                      : "bg-[#EAEAEA]"
                                  }  px-3 py-1 rounded-[100px] text-center`}
                                >
                                  {user?.status}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <div className="flex flex-row gap-2">
                                  <div className="action-icon">
                                    <Tooltip content="View">
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          getUserDetail(user?._id);
                                        }}
                                        className="edit-btn"
                                        icon={faEye}
                                      />
                                    </Tooltip>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          !loader && <NoDataFound />
        )}

        {/* view Job modal */}
        {open && (
          <PopUpLayout setShowModal={setOpen}>
            <>
              <div className="bg-white w-[700px] h-[550px] p-5 overflow-y-auto hide_scroll rounded-xl ">
                <div className="flex justify-between ">
                  <h1 className="taxt-[#303637] text-[20px] font-semibold ">
                    View User Application
                  </h1>
                  <svg
                    className="cursor-pointer"
                    onClick={() => {
                      setOpen(false);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z"
                      fill="#212121"
                    />
                  </svg>
                </div>
                {/* heading */}

                {/*  */}
                {/* <div className="flex flex-row gap-4">
                  <div className="text-neutral-900 text-base font-bold leading-4 mt-6 ">
                    Name:
                  </div>
                  <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 ">
                    {uniqueUserDetails?.first_name}{" "}
                    {uniqueUserDetails?.last_name || "NA"}
                  </div>
                </div> */}

                {/* body */}
                <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                  <>
                    <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                      <div>Name</div>
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="text-balance">
                        {`${uniqueUserDetails?.first_name} ${uniqueUserDetails?.last_name} ` ||
                          "NA"}
                      </p>
                    </div>
                  </>
                  <>
                    <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                      <div>Phone</div>
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="text-balance">
                        {`${uniqueUserDetails?.phone_code} ${uniqueUserDetails?.phone} ` ||
                          "NA"}
                      </p>
                    </div>
                  </>

                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      Email
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                        {uniqueUserDetails?.email || "NA"}
                      </p>
                    </div>
                  </>
                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      Current Salary
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                        {uniqueUserDetails?.salary || "NA"}
                      </p>
                    </div>
                  </>

                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      Desired Salary
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  outline-none bg-white txt-wrap-balance">
                        {uniqueUserDetails?.desired_salary || "NA"}
                      </p>
                    </div>
                  </>

                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      CV
                    </div>
                    <div className=" whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px]  max-w-full flex flex-row gap-2 justify-between mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <div>
                        <p className=" text-neutral-400 text-base  outline-none bg-white  txt-wrap-balance">
                          {uniqueUserDetails?.cv || "NA"}
                        </p>
                      </div>
                      <div>
                        <Link
                          className="hover:text-blue-gray-600"
                          to={uniqueUserDetails?.cv}
                          target="_blank"
                        >
                          {" "}
                          <FontAwesomeIcon
                            className=""
                            icon={faUpRightFromSquare}
                          />
                        </Link>{" "}
                      </div>
                    </div>
                  </>
                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      VISA sponsorship
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                        {uniqueUserDetails?.is_visa_sponsorship === true
                          ? "Yes"
                          : "No"}
                      </p>
                    </div>
                  </>
                </div>

                {/*  */}
              </div>
            </>
          </PopUpLayout>
        )}
      </div>
      {/* pagination */}
      <div className="bg-white flex flex-col gap-1 w-[calc(100vw-220px)] h-[calc(100vh-60px)]  items-end pt-2 px-2 rounded-b-2xl   ">
        {pageCount > 1 && (
          <Card className="py-2 rounded-none   ">
            <span className="bg-[#E6E6E6] py-3  px-2  items-center ml-3  rounded-xl ">
              <CustomPagination
                page={skip}
                setPage={setSkip}
                count={pageCount}
              />
            </span>
          </Card>
        )}
      </div>
    </>
  );
};

export default JobApplicationListing;
