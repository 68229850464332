import * as yup from 'yup';
import Breadcrumb from '../../../../components/common/Breadcrumb';
import CustomPagination from '../../../../components/common/CustomPagination';
import NoDataFound from '../../../../components/common/NoDataFound';
import PriceForm from './PriceForm';
import addIcon from '../../../../assets/images/comp_imgs/addnewlayer.png';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Typography } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addPricing,
  getBoutiqueDetails,
  getItemDetails,
  getMembershipDetails,
  getPricingList,
} from '../../../../api/membership';
import { successToast } from '../../../../utils/toast';

// import Breadcrumb from "../../../../components/console/customer-services/customer-mngmnt/Breadcrumb";
// import NextScheduledPriceChange from "../../../../components/members&pricing/unlimited/next-schedule-price-change/NextScheduledPriceChange";
// import deleteicon from "../../../../assets/images/comp_imgs/delete-icon.jpg";

const priceSchema = (isInitiationFeeOptional) => {
  const initiationFees = !isInitiationFeeOptional
    ? yup.string()
    : yup.string().required('Fees is required');
  return yup.object().shape({
    schedule_prices: yup.array().of(
      yup
        .object()
        .shape({
          price: yup.string().required('Price is required'),
          initiationFees: initiationFees,
          effectiveDate: yup.string().required('Date is required'),
          effectiveEndDate: yup.string().default(null).nullable(),
        })
        .test('unique-effective-dates', 'Effective dates must be unique', function (value) {
          const isDuplicate = this.parent?.find(
            (price, index) =>
              price?.effectiveDate === value?.effectiveDate &&
              this.path !== `schedule_prices[${index}]`,
          );
          return isDuplicate ? false : true;
        }),
    ),
  });
};

const EditKeys = {
  price: true,
  initiationFees: true,
  effectiveDate: true,
  effectiveEndDate: true,
  _id: true,
};

const typeObj = {
  membership: 'Membership Price',
  items: 'Item Price',
  boutique: 'Boutique Price',
};

const UnlimitedPricing = () => {
  const navigate = useNavigate();
  const { id, name, type } = useParams();
  const [priceList, setPriceList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [skip, setSkip] = useState(1);
  const [activePrices, setActivePrices] = useState([]);
  const [locale, setLocale] = useState('en-us');

  // for Unlimited Elite
  const [isUnlimitedElite, setUnlimitedElite] = useState(false);

  // For item pricing
  const [isItemPricing, setIsItemPricing] = useState(false);

  const showInitiationFees = () => {
    if (isUnlimitedElite || isItemPricing) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    getPrices();
  }, [skip, name]);

  useEffect(() => {
    initialisePrices();
    if (type === 'membership' && name === 'Unlimited Elite') {
      setUnlimitedElite(true);
    }
    if (type === 'items') {
      setIsItemPricing(true);
    }
  }, []);

  const initialisePrices = () => {
    if (type === 'membership') {
      initialiseMembership();
    } else if (type === 'items') {
      initialiseItems();
    } else if (type === 'boutique') {
      initialiseBoutique();
    }
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      schedule_prices: [
        {
          effectiveDate: moment().toISOString(),
          price: '',
          initiationFees: '',
        },
      ],
    },
    resolver: yupResolver(priceSchema(showInitiationFees())),
  });

  const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray({
    control,
    name: 'schedule_prices',
  });

  const onSubmit = handleSubmit((values) => {
    handlePrice(values);
  });

  useEffect(() => {
    activePrices?.forEach((field, index) => {
      let obj = {};
      Object.keys(field).forEach((key) => {
        if (EditKeys[key]) {
          obj[key] = field[key];
        }
      });
      update(index, obj);
    });
  }, [activePrices, update]);

  const initialiseMembership = async () => {
    let response = await getMembershipDetails({ id });
    if (response?.status_code === 200) {
      setActivePrices(response?.data?.schedule_prices || []);
    }
  };

  const initialiseItems = async () => {
    let response = await getItemDetails({ id });
    if (response?.status_code === 200) {
      setActivePrices(response?.data?.schedule_prices || []);
    }
  };

  const initialiseBoutique = async () => {
    let response = await getBoutiqueDetails({ id });
    if (response?.status_code === 200) {
      setActivePrices(response?.data?.schedule_prices || []);
    }
  };

  const handlePrice = async (values) => {
    let dynamicKey = type;
    const pricesMap = {};
    values?.schedule_prices?.forEach((item) => {
      if (type !== 'boutique') {
        delete item.effectiveEndDate;
      }
      if (item?._id) {
        pricesMap[item?._id] = true;
      }
    });
    let delete_ids = '';
    activePrices?.forEach((price) => {
      delete_ids += !pricesMap[price?._id] ? (delete_ids ? `,${price?._id}` : `${price?._id}`) : '';
    });
    let payload = {
      type: type === 'items' ? 'item' : type,
      [dynamicKey]: id,
      schedule_prices: values.schedule_prices,
      delete_ids,
    };
    const response = await addPricing(payload);
    if (response?.status_code === 200) {
      successToast(response?.message);
      initialisePrices();
      getPrices();
      reset();
    }
  };

  console.log(errors);

  const getPrices = async () => {
    let payload = {
      skip,
      limit,
      type: type === 'membership' ? 1 : type === 'items' ? 2 : 3,
      id,
    };
    const response = await getPricingList(payload);
    if (response?.status_code === 200) {
      setPriceList(response?.data || []);
      setPageCount(Math.ceil(response?.totalItems / limit) || 0);
    }
  };

  // For Unlimited Price member ship
  const TABLE_HEAD = [
    'Effective date',
    isUnlimitedElite ? 'Price Delta' : 'Price',
    showInitiationFees() ? 'Initiation fee' : '',
  ];
  return (
    <>
      {/* copy */}
      <div>
        <form onSubmit={onSubmit}>
          <div className='flex flex-col justify-center items-center'>
            <Breadcrumb
              module={'prices'}
              subModule={'Membership'}
              parent={typeObj[type]}
              child={name}
            />
            {/* <Breadcrumb
                parent={"Pricing"}
                child={typeObj[type]}
                subchild={name}
              /> */}

            <div className='boutique-price-wrap flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4   hide_scroll  overflow-y-auto'>
              <div className='bg-white flex flex-col  w-full  items-start pt-2  rounded-lg '>
                <>
                  <div className='bg-white flex flex-col  w-full  items-start pt-2 px-4 rounded-lg '>
                    {/* <b className='unlimited-heading mb-2 text-2xl '>{typeObj[type]} {name}</b> */}
                    <b className='unlimited-heading mb-2 text-2xl '>{name}</b>

                    <div className='tiers-data mt-3'>
                      <div class="text-neutral-900 text-lg font-bold font-['Hauora']">Price</div>

                      <div className='flex flex-row items-center gap-2'>
                        <img
                          className='add-next-icon cursor-pointer'
                          alt='next-layer'
                          src={addIcon}
                          onClick={() => {
                            append('schedule_prices', {
                              effectiveDate: '',
                              price: '',
                              initiationFees: '',
                            });
                          }}
                        />
                        <div class="text-neutral-900 text-lg font-bold font-['Hauora']">
                          Next Scheduled Price Change
                        </div>
                      </div>
                      {fields?.map((field, index) => (
                        <>
                          <PriceForm
                            key={field?.id}
                            field={field}
                            index={index}
                            remove={remove}
                            errors={errors}
                            setValue={setValue}
                            type={type}
                            register={register}
                            activePrices={activePrices}
                            showdelete={fields?.length > 1}
                            isUnlimitedElite={isUnlimitedElite}
                            showInitiationFees={showInitiationFees()}
                          />
                        </>
                      ))}
                    </div>

                    <div className='text-neutral-800 text-base font-semibold leading-5 max-md:max-w-full mt-3'>
                      Price History
                    </div>

                    <div className=' w-[-webkit-fill-available]  rounded-none commom-react-table-wrap'>
                      <table className='w-full  mx-4 table-auto text-left'>
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head) => {
                              return (
                                <th
                                  key={head}
                                  className='border-b border-blue-gray-100   px-14 text-start py-6'
                                >
                                  <Typography
                                    variant='small'
                                    color='blue-gray'
                                    className='font-normal leading-none opacity-70'
                                  >
                                    {head}
                                  </Typography>
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {priceList.map((item, index) => {
                            const isLast = index === priceList.length - 1;
                            const classes = isLast ? 'py-6 px-14' : 'py-6 px-14 border-none';

                            return (
                              <tr key={index}>
                                <td className={classes + ' cursor-pointer '}>
                                  <Typography
                                    variant='small'
                                    color='blue-gray'
                                    className='font-normal flex  flex-row  gap-5'
                                  >
                                    <p>
                                      {moment(item?.effectiveDate).format(`YYYY-MM-DD  HH:mm`) ||
                                        'N/A'}
                                    </p>
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography
                                    variant='small'
                                    color='blue-gray'
                                    className='font-normal'
                                  >
                                    $ {item?.price || 'N/A'}
                                  </Typography>
                                </td>
                                {showInitiationFees() && (
                                  <td className={classes}>
                                    <Typography
                                      variant='small'
                                      color='blue-gray'
                                      className='font-normal'
                                    >
                                      {item?.initiationFees || 'N/A'}
                                    </Typography>
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {priceList?.length === 0 && <NoDataFound />}
                      {pageCount > 1 && (
                        <>
                          <div className='bg-white flex flex-col gap-1 w-full  items-end pt-2 px-2   rounded-lg   '>
                            <Card className='py-2 rounded-none'>
                              <span className='bg-[#E6E6E6] py-3  px-2 w-auto items-end ml-3  rounded-xl '>
                                {pageCount > 1 && (
                                  <CustomPagination
                                    page={skip}
                                    setPage={setSkip}
                                    count={pageCount}
                                  />
                                )}
                              </span>
                            </Card>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              </div>

              {/* <>
                  <div className="footer-container">
                    <div className="footer-body">
                      <div onClick={() => reset()} className="f-delete div-3">
                        Delete
                      </div>
                      <div
                        onClick={() => navigate(-1)}
                        className="f-cancel div-4"
                      >
                        Cancel
                      </div>

                      <button type="submit" className="f-apply div-6">
                        Apply Pricing
                      </button>
                    </div>
                  </div>
                </> */}
              {/* footer-button */}
              <div className=' bg-white flex flex-col gap-1 w-full  items-end   rounded-lg mt-4   '>
                <div className='flex flex-row py-3 justify-center items-center px-4 gap-4 '>
                  <div className=' bg-white flex flex-col gap-1 w-full  items-start  rounded-lg   '>
                    <div
                      className='text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white text-black px-16 py-3 rounded-lg border-solid   cursor-pointer'
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </div>
                  </div>

                  <div className='  flex flex-col gap-1 w-full  items-start rounded-lg   '>
                    <button
                      typr='submit'
                      className='text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch  bg-[#8C2323] text-white px-16 py-3 rounded-lg border-solid  cursor-pointer'
                    >
                      {'Apply Pricing'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UnlimitedPricing;
