import React, { useState, useEffect, useRef } from 'react';
// import Breadcrumb from "../../components/console/customer-services/customer-mngmnt/Breadcrumb";
import { Button } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ReactPhoneInput from 'react-phone-input-2';
import parsePhoneNumber from 'libphonenumber-js';
import { Country } from 'country-state-city';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAdminById, getAllRoles, onAddSubadmin, updateAdmin } from '../../api/subadmin/subadmin';
import Error from '../../components/common/Error';
import { errorToast, successToast } from '../../utils/toast';
import Breadcrumb from '../../components/common/Breadcrumb';
import ContainerHeading from '../../components/common/container-heading/ContainerHeading';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSelector } from 'react-redux';
import { ADMIN } from '../../constants/constant';
let countryCode = 'IN';

const subAdminSchema = yup.object().shape({
  first_name: yup
    .string()
    .required('First Name is required')
    .min(1, 'Min 1 characters allowed')
    .max(20, 'Max 20 characters allowed')
    .matches(/^[aA-zZ\s]+$/, 'Invalid name')
    .test('test', 'Name is required', (val) => val.trim() !== ''),
  last_name: yup
    .string()
    .required('Last Name is required')
    .min(1, 'Min 1 characters allowed')
    .max(20, 'Max 20 characters allowed')
    .matches(/^[aA-zZ\s]+$/, 'Invalid name')
    .test('test', 'Name is required', (val) => val.trim() !== ''),
  email: yup.string().email().required('Email is required'),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one special character, and one number. It should be between 8 and 15 characters.',
    ),
  phone: yup.string(),
  roles: yup.string(),
  country_code: yup.string(),
  // admin_type: yup.string().required("Admin Type is required")
});

const AddSubadmin = () => {
  const navigate = useNavigate();
  const data = useSelector((state) => state.auth);
  const location = useLocation();
  const { id } = useParams();
  const phoneInput = useRef(null);
  const [viewData, setViewData] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [previousPassword, setPreviousPassword] = useState('');
  const editRoute =
    location?.pathname?.indexOf(`/dashboard/sub-admin/edit-sub-admin`) > -1 ? true : false;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(subAdminSchema),
  });

  useEffect(() => {
    let tempCountries = Country.getAllCountries();
    let newCountries = [];
    tempCountries.forEach((item) => {
      let obj = {
        value: item.phonecode,
        label: `+${item.phonecode} ${item.name} ${item.flag}`,
      };
      newCountries.push(obj);
    });
  }, []);

  useEffect(() => {
    getRolesList();
  }, []);

  const getRolesList = async () => {
    const response = await getAllRoles();
    if (response?.status_code == 200) {
      const res =
        response?.data?.map((item) => ({
          ...item,
          checked: false,
          value: '',
        })) || [];
      setPermissions(res);
      if (editRoute) {
        getAdminDataById(res);
      }
    }
  };

  const getAdminDataById = async (permissions) => {
    let payload = {
      id,
    };
    const response = await getAdminById(payload);
    if (response.status_code === 200) {
      delete response?.data?.password;
      setViewData({ ...response?.data } || {});
      Object.keys(response?.data).forEach((key) => {
        setValue(key, response?.data[key]);
      });
      setPreviousPassword(response?.data?.password || '');
      let tempPermissionsData = [];
      permissions?.forEach((item) => {
        response?.data?.roles_array?.find((ii) => {
          if (item?._id === ii?.role_id) {
            item.checked = true;
            item.value = ii?.role_status === 'read' ? 'read' : 'edit';
          }
        });
        tempPermissionsData.push(item);
      });
      setPermissions(tempPermissionsData);
    }
  };

  const addSubadmin = async (values) => {
    if (values.password.length === 0) {
      errorToast('Please Enter your Password');
    } else {
      const response = await onAddSubadmin(values);
      if (response.status_code === 200) {
        navigate(-1);
      } else {
        errorToast(response?.message || 'Something Went Wrong');
      }
    }
  };

  const updateSubadmin = async (values) => {
    const response = await updateAdmin(values);
    if (response.status_code === 200) {
      navigate(-1);
    } else {
      errorToast(response?.message || 'Something Went Wrong');
    }
  };

  const onSubmit = handleSubmit((values) => {
    let payload = { ...values, roles_array: '' };
    delete payload.phone_code;
    delete payload._id;
    payload.roles_array = permissions
      ?.filter((item) => item.checked)
      .map((item) => ({
        role_id: item?._id,
        role_status: item?.value === 'read' ? 'read' : 'write',
      }));
    if (editRoute) {
      payload.id = id;
    }
    if (editRoute) {
      updateSubadmin(payload);
    } else {
      addSubadmin(payload);
    }
  });

  const handlePermissionCheck = async (e, ind) => {
    let tempPermissions = [];
    permissions.forEach((ii, i) => {
      if (i === ind) {
        ii.checked = e.target.checked;
        if (e.target.checked) {
          ii.value = 'read';
        } else {
          ii.value = '';
        }
      }
      tempPermissions.push(ii);
    });
    setPermissions(tempPermissions);
  };

  const handlePermissionRead = async (e, ind) => {
    let tempPermissions = [];
    permissions?.forEach((ii, i) => {
      if (i == ind) {
        ii.value = 'read';
        ii.checked = true;
      }
      tempPermissions.push(ii);
    });
    setPermissions(tempPermissions);
  };

  const handlePermissionWrite = async (e, ind) => {
    let tempPermissions = [];
    permissions?.forEach((ii, i) => {
      if (i == ind) {
        ii.value = 'edit';
        ii.checked = true;
      }
      tempPermissions.push(ii);
    });
    setPermissions(tempPermissions);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className='common-bg'>
        {/* <Breadcrumb parent={"Subadmin"} child={"Add-Subadmin"} /> */}
        {/* <Breadcrumb
              module={"Dashboard"}
              subModule={"Sub Admin"}
              parent={editRoute ? "Edit" : "Add"}
            /> */}
        {/* <div
              id="WrapperRoot"
              className="bg-white flex flex-row justify-between w-full h-20 items-start pt-6 px-6 rounded-lg"
            >
              <div className="text-2xl font-['Hauora'] font-semibold text-[#2e2e2e]">
                Add Subadmin
              </div>
              <div className="flex ">
                <div className="px-3">
                  <Button
                    onClick={() => navigate(-1)}
                    size="sm"
                    variant="outlined"
                    className="add-user-button cancel-btn"
                  >
                    Cancel
                  </Button>
                </div>
                <div className="w-100">
                  <Button
                    type="submit"
                    size="sm"
                    variant="filled"
                    className="add-user-button"
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div> */}
        {/* header */}

        <div
          id='WrapperRoot'
          className='bg-white flex flex-row justify-between w-full h-20 items-start p-3 rounded-lg'
        >
          {/* left container */}
          <div>
            <div className="text-2xl font-['Hauora'] font-bold text-[#2e2e2e]">
              {' Add Subadmin'}
            </div>
          </div>

          {/* right container */}
          <div className='flex flex-row justify-center align-middle items-center gap-2'>
            {/* button */}
            {true && (
              <>
                {/* cancel button */}
                <button
                  className=' bg-black justify-center items-stretch bg-neutral-900 flex gap-2 px-3 py-2.5 rounded-lg cursor-pointer'
                  onClick={() => navigate(-1)}
                  type='button'
                >
                  <span className='text-white text-center text-sm font-medium leading-5 self-center grow whitespace-nowrap my-auto'>
                    {'Cancel'}
                  </span>
                </button>

                {/* save buttton */}
                <button className=' bg-black justify-center items-stretch bg-neutral-900 flex gap-2 px-4 py-2.5 rounded-lg cursor-pointer  '>
                  <span
                    className='text-white text-center text-sm font-medium leading-5 self-center grow whitespace-nowrap my-auto '
                    type='submit'
                  >
                    {editRoute ? 'Update' : 'Add'}
                  </span>
                </button>
              </>
            )}
          </div>
        </div>

        {/*  */}

        <div className='row px-3'>
          <div className='col-md-4 mb-3'>
            <input
              className='form-control'
              placeholder='Enter Your First Name'
              {...register('first_name')}
            />
            <Error error={errors?.first_name} message={errors?.first_name?.message} />
          </div>
          <div className='col-md-4 mb-3'>
            <input
              className='form-control'
              placeholder='Enter Your Last Name'
              {...register('last_name')}
            />
            <Error error={errors?.last_name} message={errors?.last_name?.message} />
          </div>
          <div className='col-md-4 mb-3'>
            <input
              className='form-control'
              placeholder='Enter Your Email ID'
              {...register('email')}
              disabled={data?.auth?.type !== ADMIN ? true : false}
            />
            <Error error={errors?.email} message={errors?.email?.message} />
          </div>
          {/* {!editRoute && */}
          <div className='col-md-4 mb-3'>
            <div className='relative'>
              <input
                className='form-control'
                placeholder='Enter Your Password'
                id='password-sub-admin'
                {...register('password')}
                type={showPassword ? 'text' : 'password'}
                disabled={data?.auth?.type !== ADMIN ? true : false}
              />
              <span className='absolute top-[15%] right-[2%] cursor-pointer'>
                {showPassword ? (
                  <RemoveRedEyeIcon onClick={() => setShowPassword(false)} />
                ) : (
                  <VisibilityOffIcon onClick={() => setShowPassword(true)} />
                )}
              </span>
            </div>
            <Error error={errors?.password} message={errors?.password?.message} />
          </div>
          {/* } */}
          <div className='col-md-4'>
            {/* <input
              className="form-control"
              placeholder="Enter Your Phone Number"
              {...register("phone")}
            /> */}
            <Controller
              control={control}
              name='phone'
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <ReactPhoneInput
                  ref={phoneInput}
                  value={`${viewData?.country_code}${viewData?.phone}`}
                  onChange={(value, data, event, formattedValue) => {
                    onChange(value.slice(data.dialCode.length));
                  }}
                  inputClass='form-control'
                  inputExtraProps={{
                    required: true,
                    autoFocus: true,
                  }}
                  isValid={(value, country) => {
                    setValue('country_code', country.dialCode);
                    countryCode = country.iso2.toUpperCase();
                    if (value.match(/12345/)) {
                      return 'Invalid value: ' + value + ', ' + country.name;
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  country={'au'}
                  countryCodeEditable={false}
                  specialLabel={'Player Mobile Number'}
                  enableSearch={true}
                />
              )}
            />
          </div>
        </div>
        <div
          id='sub-admin-add-edit-permission'
          className="text-2xl font-['Hauora'] font-semibold text-[#2e2e2e] mb-3 mt-4 ps-5"
        >
          Permissions
        </div>
        <div className='row'>
          {permissions?.map((item, ind) => (
            <div key={ind} className='col-md-6 border-shadow '>
              <div className='row px-3'>
                <div className='col-md-6'>
                  <div class='form-check'>
                    <div className='check-input-add'>
                      <input
                        class='form-check-input cursor-pointer'
                        type='checkbox'
                        checked={item?.checked}
                        onChange={(e) => {
                          handlePermissionCheck(e, ind);
                        }}
                        id={item?.name + ind}
                      />
                      <label class='form-check-label' for={item?.name + ind}>
                        {item?.name || 'NA'}
                      </label>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div class='form-check'>
                        <div className='check-input-add'>
                          <input
                            class='form-check-input cursor-pointer'
                            onChange={(e) => {
                              handlePermissionRead(e, ind);
                            }}
                            type='radio'
                            name={item?.name}
                            checked={item?.value === 'read' ? true : false}
                            id={`${item?.name}${ind}1`}
                          />
                          <label
                            class='form-check-label cursor-pointer'
                            for={`${item?.name}${ind}1`}
                          >
                            Read Only
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div class='form-check'>
                        <div className='check-input-add'>
                          <input
                            class='form-check-input cursor-pointer'
                            type='radio'
                            onChange={(e) => {
                              handlePermissionWrite(e, ind);
                            }}
                            name={item?.name}
                            id={`${item?.name}${ind}2`}
                            checked={item?.value === 'edit' ? true : false}
                          />
                          <label
                            class='form-check-label cursor-pointer'
                            for={`${item?.name}${ind}2`}
                          >
                            Read & Write
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </form>
  );
};

export default AddSubadmin;
