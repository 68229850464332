import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/common/Breadcrumb'
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Typography } from "@material-tailwind/react";
import moment from 'moment';
import CustomPagination from '../../../components/common/CustomPagination';
import NoDataFound from '../../../components/common/NoDataFound';
import { getUserMebershipHistoryDetails } from '../../../api/membership';
const TABLE_HEAD = ["Effective Date", "Price Range", "Price Change"];

const MembershipHistoryDetails = () => {
    const { user_membership_id } = useParams();
    const navigate = useNavigate()
    const [skip, setSkip] = useState(1);
    const [pageCount, setPageCount] = useState(2);
    const [membershipList, setMembershipList] = useState([]);


    useEffect(() => {
        getMembershipList()
    }, [])

    const getMembershipList = async () => {
        let payload = {
            user_membership_id,
        }
        const response = await getUserMebershipHistoryDetails(payload);
        if (response?.status_code === 200) {
            setMembershipList(response?.data || []);
        }
    };

    return (
        <>
                <div className="flex flex-col justify-center items-center">
                    <Breadcrumb
                        module={"prices"}
                        subModule={"Membership"}
                        parent="Existing Member Price Change"
                        child={"Price history for User membership"}
                    />
                    <div className="flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4   hide_scroll  overflow-y-auto">
                        <div className="bg-white flex flex-col  w-full  items-start pt-2  rounded-lg ">
                            <>
                                {/* heading */}
                                <div className="bg-white flex flex-col  w-full  items-start pt-2 px-4 rounded-lg ">
                                    <b className="unlimited-heading mb-2 text-2xl ">
                                        Membership history for user
                                    </b>                
                                    {/* price-history-table */}
                                    <div className=" w-[-webkit-fill-available]  rounded-none  ">
                                        <table className="w-full  mx-4 table-auto text-left">
                                            <thead>
                                                <tr>
                                                    {TABLE_HEAD.map((head) => (
                                                        <th
                                                            key={head}
                                                            className="border-b border-blue-gray-100   px-14 text-start py-6"
                                                        >
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal leading-none opacity-70"
                                                            >
                                                                              {head}
                                                            </Typography>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {membershipList.map((item, index) => {
                                                    const isLast = index === membershipList.length - 1;
                                                    const classes = isLast
                                                        ? "py-6 px-14"
                                                        : "py-6 px-14 border-none";

                                                    return (
                                                        <tr key={index}>
                                                            <td className={classes + " cursor-pointer "}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal flex  flex-row  gap-5"
                                                                >
                                                                    <p>
                                                                        {moment(item?.effectiveDate).format(
                                                                            "YYYY-MM-DD"
                                                                        ) || "N/A"}
                                                                    </p>
                                                                    {/* <p>{itw}</p> */}
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal"
                                                                >
                                                                    {item?.price || "N/A"}
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal"
                                                                >
                                                                    {item?.initiationFees || "N/A"}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        {membershipList?.length === 0 && <NoDataFound />}
                                        {pageCount > 1 && (
                                            <>
                                                <div className="bg-white flex flex-col gap-1 w-full  items-end pt-2 px-2   rounded-lg   ">
                                                    <Card className="py-2 rounded-none">
                                                        <span className="bg-[#E6E6E6] py-3  px-2 w-auto items-end ml-3  rounded-xl ">
                                                            {pageCount > 1 && (
                                                                <CustomPagination
                                                                    page={skip}
                                                                    setPage={setSkip}
                                                                    count={pageCount}
                                                                />
                                                            )}
                                                        </span>
                                                    </Card>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    {/*  */}

                                    {/* hrline */}
                                    {/* <div className=" my-4 flex border border-black h-[1px] w-full flex-col" /> */}
                                    {/* hrline */}
                                </div>
                            </>
                        </div>

                        {/* footer */}
                        {/* <>
                            <div className="footer-container">
                                <div className="footer-body">
                                    <div onClick={() => reset()} className="f-delete div-3">
                                        Delete
                                    </div>
                                    <div
                                        onClick={() => navigate(-1)}
                                        className="f-cancel div-4"
                                    >
                                        Cancel
                                    </div>
                                    <div className="f-draft div-5">Save Draft</div>
                                    <button type="submit" className="f-apply div-6">
                                        Apply Pricing
                                    </button>
                                </div>
                            </div>
                        </> */}
                        {/*  */}
                    </div>
                </div>
        </>
    )
}

export default MembershipHistoryDetails