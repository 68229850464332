// Card
import { Card, Typography } from '@material-tailwind/react';

// React js
import { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Style
import '../../style/pages/payment-gateways/PaymentGateway.css';

// Components
import NoDataFound from '../../components/common/NoDataFound';
import { getPaymentListing, updatePaymentListing } from '../../api/payment';

// Utils
import { errorToast, successToast } from '../../utils/toast';

// Material UI
import { Switch } from '@mui/material';

// Table heading
const TABLE_HEAD = ['', 'Powerboard', 'Airwallex'];

// Image map
const imageMap = [
  {
    cardName: 'Mastercard',
    img: '/asset/mastercard.svg',
  },
  {
    cardName: 'Visa',
    img: '/asset/visa.svg',
  },
  {
    cardName: 'Amex',
    img: '/asset/amex.svg',
  },
];

// Table rows
const TABLE_ROWS = [
  {
    name: 'Card',
  },
];

/**
 * Renders the PaymentGateway component.
 *
 * This component displays the payment gateways and allows the user to enable/disable
 * each gateway. It fetches the payment gateways from the server using the getPaymentListing
 * function and updates the state with the fetched data. The user can change the ratios
 * of each payment gateway and update the payment gateways using the updatePayments function.
 *
 * @return {JSX.Element} The PaymentGateway component.
 */
export function PaymentGateway() {
  const { loader } = useSelector((state) => state.loader);
  const [payments, setPayments] = useState(null);
  const [initPayments, setInitPayments] = useState(null);

  /**
   * Fetches the payment listing from the server and sets the payments and initial payments state based on the response data if the status code is 200.
   *
   * @return {Promise<void>} - Promise that resolves once the payment listing is fetched and state is updated.
   */
  const getListing = async () => {
    let response = await getPaymentListing();
    if (response?.status_code === 200) {
      setPayments(response?.data || {});
      setInitPayments(response?.data || []);
    }
  };

  /**
   * Updates the 'enabled' property of an object based on the payment type and the checked status.
   *
   * @param {Object} ele - The object to update.
   * @param {string} cardName - The name of the payment type to match.
   * @param {boolean} checked - The new value for the 'enabled' property.
   * @return {Object} - The updated object with the 'enabled' property set to the new value.
   */
  const checkedCard = (ele, cardName, checked) => {
    if (ele.paymentType.toLowerCase() === cardName) {
      return {
        ...ele,
        enabled: checked,
      };
    }
    return ele;
  };

  /**
   * Updates the 'enabled' property of the payment object based on the payment type and the checked status.
   *
   * @param {string} cardName - The name of the payment type to match.
   * @param {boolean} checked - The new value for the 'enabled' property.
   * @return {void}
   */
  const handleEnableChange = (cardName, checked) => {
    setPayments((prev) => {
      return {
        ...prev,
        australiaRegion: {
          ...prev.australiaRegion,
          gatewayPercentages: [...prev.australiaRegion.gatewayPercentages].map((ele) =>
            checkedCard(ele, cardName, checked),
          ),
        },
        nonAustraliaRegion: {
          ...prev.nonAustraliaRegion,
          gatewayPercentages: [...prev.nonAustraliaRegion.gatewayPercentages].map((ele) =>
            checkedCard(ele, cardName, checked),
          ),
        },
      };
    });
  };

  /**
   * Function to update payments asynchronously.
   *
   * @return {void}
   */
  const updatePayments = async () => {
    const requestBody = { ...payments };
    delete requestBody.updatedAt;
    delete requestBody.createdAt;
    try {
      let response = await updatePaymentListing(requestBody);
      if (response?.status_code === 200) {
        successToast(response?.message);
        getListing();
      }
    } catch (error) {
      errorToast(error?.message);
    }
  };

  useEffect(() => {
    getListing();
  }, []);

  if (!payments) return <NoDataFound />;

  return (
    <div className='common-bg p-3'>
      <div className=''>
        <h2 className='common-heading'> Payment Gateway</h2>

        <div className='methods'>
          <h2>Payment Methods </h2>
        </div>

        <div className='icon'>
          {imageMap?.map((img, ind) => {
            const cardName =
              payments?.australiaRegion?.gatewayPercentages[ind].paymentType.toLowerCase();
            console.log(cardName, typeof cardName, 'cardName');
            return (
              <>
                <img src={`/asset/${cardName}.svg`} alt='' width={'49.2px'} height={'20px'} />
                <div class='action-payment-toggle'>
                  <Switch
                    checked={payments?.australiaRegion?.gatewayPercentages[ind].enabled}
                    icons={false}
                    onChange={(e) => handleEnableChange(cardName, e.target.checked)}
                  />
                </div>
              </>
            );
          })}
        </div>
        <form>
          <Card className=' !z-5 overflow-x-auto rounded-none mt-3'>
            <table className='w-full  table-auto text-left payment-gateways-table'>
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className='border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]'
                    >
                      <Typography
                        variant='small'
                        color='black'
                        className='leading-none font-semibold'
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <PaymentTable
                  payments={payments}
                  region={'australiaRegion'}
                  setPayments={setPayments}
                />
                <PaymentTable
                  payments={payments}
                  region={'nonAustraliaRegion'}
                  setPayments={setPayments}
                />
              </tbody>
            </table>
            {TABLE_ROWS.length === 0 && !loader && <NoDataFound />}
          </Card>
        </form>
      </div>

      <div className='button-box'>
        <button className='cancel' onClick={() => setPayments({ ...initPayments })}>
          Cancel
        </button>

        <button class='apply' onClick={updatePayments}>
          Apply
        </button>
      </div>
    </div>
  );
}

/**
 * Renders the payment table for a specific region with detailed billing information.
 *
 * @param {Object} payments - The payment data for the region.
 * @param {string} region - The region for which the payments are being displayed.
 * @return {JSX.Element} The JSX element representing the payment table.
 */
const PaymentTable = ({ payments, region, setPayments }) => {
  if (!payments) return null;

  /**
   * Calculates the percentages for a given value, element, and type.
   *
   * @param {number} value - The value to calculate percentages for.
   * @param {object} ele - The element to calculate percentages for.
   * @param {string} type - The type of percentage to calculate.
   * @return {object} The updated percentages object.
   */
  const calPercentages = (value, ele, type) => {
    if (value < 0 || value > 100) {
      return ele.percentages;
    }
    if (type === 'powerboardPercentage') {
      return {
        ...ele.percentages,
        airwallexPercentage: Number(100 - value),
        powerboardPercentage: +value,
      };
    }
    return {
      ...ele.percentages,
      airwallexPercentage: +value,
      powerboardPercentage: 100 - Number(value),
    };
  };

  /**
   * Updates the 'percentages' property of a specific gateway in the payment object based on the new value and type.
   *
   * @param {Event} event - The event object from the input change event.
   * @param {Object} item - The gateway object that is being updated.
   * @param {string} type - The type of percentage being updated (either 'airwallexPercentage' or 'powerboardPercentage').
   * @return {void}
   */
  const handlePercentageChange = (event, item, type) => {
    const { value } = event.target;
    setPayments((prev) => {
      const newVal = {
        ...prev,
        [region]: {
          ...prev[region],
          gatewayPercentages: [...prev[region].gatewayPercentages].map((ele) => {
            if (ele._id === item._id) {
              return {
                ...ele,
                percentages: { ...calPercentages(value, ele, type) },
              };
            }
            return { ...ele };
          }),
        },
      };
      console.log(newVal, 'newVal');
      return newVal;
    });
  };

  return (
    <tr>
      <td colSpan={3}>
        <p className='table-heading'> {payments[region].region} Billing </p>
        <table className='dyanamic-table-td'>
          <tbody>
            {payments?.[region]?.gatewayPercentages?.map((item, index) => {
              const isLast = index === payments.length - 1;
              const classes = isLast ? 'py-2 px-3 ' : 'py-2 px-3 border-b border-blue-gray-50';

              return (
                <tr key={index}>
                  <td className={classes + ' cursor-pointer'}>
                    <Typography variant='small' color='blue-gray' className='font-normal'>
                      <div className='w-[128px]'>
                        <button
                          className='btn uppercase'
                          type='button'
                          id='dropdownMenuButton'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          {item?.paymentType}
                        </button>
                      </div>
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant='small' color='blue-gray' className='font-normal'>
                      <div className='input-group mb-3 w-[98px]'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text' id='basic-addon1'>
                            %
                          </span>
                        </div>
                        <input
                          type='text'
                          class='form-control'
                          placeholder='99.5'
                          aria-label='Username'
                          aria-describedby='basic-addon1'
                          disabled={!item?.enabled}
                          value={item?.percentages.powerboardPercentage}
                          onChange={(event) =>
                            handlePercentageChange(event, item, 'powerboardPercentage')
                          }
                        />
                      </div>
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant='small' color='blue-gray' className='font-normal'>
                      <div className='input-group mb-3 w-[98px]'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text' id='basic-addon1'>
                            %
                          </span>
                        </div>
                        <input
                          type='text'
                          class='form-control'
                          placeholder='99.5'
                          aria-label='Username'
                          aria-describedby='basic-addon1'
                          disabled={!item?.enabled}
                          value={item?.percentages.airwallexPercentage}
                          onChange={(event) =>
                            handlePercentageChange(event, item, 'airwallexPercentage')
                          }
                        />
                      </div>
                    </Typography>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    </tr>
  );
};
