import ContainerHeading from '../../../../components/common/container-heading/ContainerHeading';
import React, { useEffect, useState } from 'react';
import { Card, Tooltip, Typography } from '@material-tailwind/react';
import CustomPagination from '../../../../components/common/CustomPagination';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Toggle from 'react-toggle';
import { getDateFormat } from '../../../../helper';
import PopUpLayout from '../../../dashboard/user-mgmt/PopUpLayout';
import {
  apiGetFaqQuestions,
  deleteFaq,
  viewAllFAQs,
  viewFAQ,
} from '../../../../api/content-mgmt/faq';
import { generateConfirm } from '../../../../utils/reactConfirmAlert';
import NoDataFound from '../../../../components/common/NoDataFound';
import { errorToast, successToast } from '../../../../utils/toast';
import { useSelector } from 'react-redux';
import { Chip, Stack } from '@mui/material';
import useFetchCategories from '../../../../hooks/api/useFetchCategories';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { reOrder } from '../../../../api/categories/categories';

export const TABLE_HEAD = [
  'Question title',
  'Category',
  'Author',
  ' Date Published',
  'Status',
  'Action',
];
const FAQListing = () => {
  // navigation
  const navigate = useNavigate();

  // values from redux
  const { loader } = useSelector((state) => state.loader);

  // local state
  const [FAQList, setFAQList] = useState([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [uniqueFAQDetails, setUniqueFAQDetails] = useState();
  const [activeCategory, setActiveCategory] = useState('');

  const getFAQListData = async () => {
    let payload = {
      skip,
      limit,
      search,
    };
    let response = await viewAllFAQs(payload);
    if (response?.status_code === 200) {
      setFAQList(response?.data);
      const count = Math.ceil(response?.totalItems / limit);
      setPageCount(count);
    }
  };

  // details of single FAQ
  const FAQDetail = async (id) => {
    setOpen(true);
    let response = await viewFAQ(id);
    setUniqueFAQDetails(response?.data);
  };

  // delete faq on id
  const handleFAQDelete = async (id) => {
    generateConfirm('Are you sure', 'You want to delete', id, async () => {
      let payload = {
        id,
      };
      const response = await deleteFaq(payload);
      if (response?.status_code === 200) {
        successToast(response?.message);
      } else if (response?.status_code === 400) {
        errorToast(response?.message);
      }
      getFAQListData();
    });
  };

  // status toggle function
  const onActive = async (id, status) => {
    let payload = {
      id,
      status: status === 'active' ? 'inactive' : 'active',
    };
  };

  const getFAQFilterListData = async (categoryId) => {
    if (categoryId === '') {
      getFAQListData();
      return;
    }
    try {
      let response = await apiGetFaqQuestions(categoryId);
      if (response?.status_code === 200) {
        setFAQList(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) return;
    const updatedItems = [...FAQList];
    const [removed] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, removed);
    const _updatedData = updatedItems.map((ele, i) => {
      return {
        ...ele,
        order: i,
      };
    });
    const _updatedDataId = _updatedData.map((ele) => {
      return { _id: ele._id };
    });
    setFAQList(_updatedData);
    await reOrder({
      collectionName: 'faq',
      items: _updatedDataId,
    });
  };

  useEffect(() => {
    getFAQListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, search]);

  useEffect(() => {
    getFAQFilterListData(activeCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  return (
    <>
      <div className='flex flex-col justify-center items-center bg-white'>
        <div className='w-full hide_scroll  overflow-y-auto'>
          <ContainerHeading
            className='rounded-t-lg'
            title={'FAQ management'}
            button='FAQ'
            redirect={'/content/page-mgmt/faq/add-faq'}
            search={true}
            searchValue={search}
            setSearchValue={setSearch}
          />

          {/* <Stack direction='row' justifyContent={'flex-end'} mx={2}>
            <Button variant='contained' onClick={handleClickOpen} startIcon={<FilterListIcon />}>
              Filter
            </Button>
          </Stack> */}

          <Filters activeCategory={activeCategory} setActiveCategory={setActiveCategory} />

          {FAQList?.length ? (
            <>
              {/* <FaqDragDrop categories={FAQList} /> */}
              <div className='  flex flex-col gap-1 w-full  items-start pt-2  rounded-none '>
                {/* lisitng table */}
                <div>
                  <div
                    className=' !z-5 
              overflow-x-scroll hide_scroll rounded-none 
              w-[calc(100vw-220px)] 
               '
                  >
                    <TableContainer
                      component={Paper}
                      className='w-full min-w-max mx-2 table-auto text-left'
                    >
                      <Table aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            {TABLE_HEAD.map((head) => (
                              <TableCell
                                key={head}
                                className='border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]'
                              >
                                {' '}
                                <Typography
                                  variant='small'
                                  color='blue-gray'
                                  className=' leading-none font-semibold'
                                >
                                  {head}
                                </Typography>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId='droppable' isDropDisabled={!activeCategory}>
                            {(provided, snapshot) => (
                              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                                {FAQList?.map(
                                  (
                                    {
                                      _id,
                                      question,
                                      categoryNames,
                                      type,
                                      created_at,
                                      status,
                                      action,
                                    },
                                    index,
                                  ) => {
                                    const isLast = index === FAQList.length - 1;
                                    const classes = isLast
                                      ? 'py-2 px-3 '
                                      : 'py-2 px-3  border-b    border-blue-gray-50';

                                    return (
                                      <Draggable key={_id} draggableId={'q-' + _id} index={index}>
                                        {(provided, snapshot) => (
                                          <TableRow
                                            key={_id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            sx={{
                                              ...provided.draggableProps.style,
                                              bgcolor: snapshot.isDragging
                                                ? 'background.paper'
                                                : 'background.default',
                                              zIndex: 2,
                                              width: '100%',
                                            }}
                                          >
                                            <TableCell
                                              className={classes + ' cursor-pointer max-w-[300px]'}
                                            >
                                              <Typography
                                                variant='small'
                                                color='blue-gray'
                                                className='font-normal overflow-hidden whitespace-nowrap text-ellipsis'
                                              >
                                                {question}
                                              </Typography>
                                            </TableCell>
                                            <TableCell
                                              className={`${classes} flex gap-1 max-w-[450px] flex-wrap`}
                                            >
                                              {categoryNames?.map((item) => (
                                                <Typography
                                                  variant='small'
                                                  color='blue-gray'
                                                  className={` max-w-fit font-normal bg-[#F5F5F5] px-3 py-1 rounded-[100px] text-center`}
                                                >
                                                  {item}
                                                </Typography>
                                              ))}
                                            </TableCell>
                                            <TableCell className={classes}>
                                              <Typography
                                                variant='small'
                                                color='blue-gray'
                                                className='font-normal overflow-hidden whitespace-nowrap text-ellipsis'
                                              >
                                                {type}
                                              </Typography>
                                            </TableCell>

                                            <TableCell className={classes}>
                                              <Typography
                                                variant='small'
                                                color='blue-gray'
                                                className='font-normal overflow-hidden whitespace-nowrap text-ellipsis'
                                              >
                                                {getDateFormat(created_at)}
                                              </Typography>
                                            </TableCell>
                                            <TableCell className={classes}>
                                              <Typography
                                                variant='small'
                                                color='blue-gray'
                                                className={`font-normal ${
                                                  status === 'Scheduled'
                                                    ? 'bg-[#90CAF9]'
                                                    : status === 'Published'
                                                    ? 'bg-[#B9F6CA]'
                                                    : status === 'Draft'
                                                    ? 'bg-[#FFF8E1]'
                                                    : status === 'active'
                                                    ? 'bg-[#B9F6CA]'
                                                    : 'bg-[#EAEAEA]'
                                                }  px-3 py-1 rounded-[100px] text-center`}
                                              >
                                                {status}
                                              </Typography>
                                            </TableCell>
                                            <TableCell className={classes}>
                                              <div className='flex flex-row gap-2'>
                                                {/* 
                                                This feature enabled in future
                                                <div class='action-toggle'>
                                                  <Toggle
                                                    checked={status === 'active' ? 'active' : ''}
                                                    icons={false}
                                                    className={
                                                      status === 'inactive' ? 'isDisable' : 'active'
                                                    }
                                                    onChange={() => onActive(_id, status)}
                                                  />
                                                </div> */}
                                                <div className='action-icon'>
                                                  <Tooltip content={'View'}>
                                                    <FontAwesomeIcon
                                                      onClick={() => {
                                                        FAQDetail(_id);
                                                      }}
                                                      className='edit-btn'
                                                      icon={faEye}
                                                    />
                                                  </Tooltip>
                                                </div>
                                                <div className='action-icon'>
                                                  <Tooltip content={'Edit'}>
                                                    <FontAwesomeIcon
                                                      onClick={() => {
                                                        navigate(
                                                          `/content/page-mgmt/faq/edit-faq/${_id}`,
                                                        );
                                                      }}
                                                      className='edit-btn'
                                                      icon={faPen}
                                                    />
                                                  </Tooltip>
                                                </div>
                                                <div className='action-icon'>
                                                  <Tooltip content={'Delete'}>
                                                    <FontAwesomeIcon
                                                      onClick={() => {
                                                        handleFAQDelete(_id);
                                                      }}
                                                      className='edit-btn'
                                                      icon={faTrash}
                                                    />
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      </Draggable>
                                    );
                                  },
                                )}
                              </TableBody>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </>
          ) : (
            !loader && <NoDataFound />
          )}

          {/* pagination */}

          <div className='bg-white flex flex-col gap-1 w-full   items-end pt-2 px-2   rounded-b-lg   '>
            {pageCount > 1 && (
              <Card className='py-2 rounded-none   '>
                <span className='bg-[#E6E6E6] py-3  px-2 w-auto items-center ml-3  rounded-xl '>
                  <CustomPagination page={skip} setPage={setSkip} count={pageCount} />
                </span>
              </Card>
            )}
          </div>
        </div>
      </div>
      {/* view FAQ modal */}
      {open && (
        <PopUpLayout setShowModal={setOpen}>
          <>
            <div className='bg-white w-[700px] mt-5 h-[calc(100vh-60px)] p-5 overflow-y-auto hide_scroll rounded-xl '>
              <div className='flex justify-between '>
                <h1 className='taxt-[#303637] text-[20px] font-semibold '>View FAQ</h1>
                <svg
                  className='cursor-pointer'
                  onClick={() => {
                    setOpen(false);
                  }}
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    d='M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z'
                    fill='#212121'
                  />
                </svg>
              </div>
              {/* heading */}

              {/*  */}
              <div className='flex flex-row gap-4'>
                <div className='text-neutral-900 text-base font-bold leading-4 mt-6 '>Title:</div>
                <div className='text-neutral-900 text-sm font-medium leading-4 mt-6 '>
                  {uniqueFAQDetails?.title}
                </div>
              </div>
              {/* body */}

              {/*  */}
              <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                {/* sub-title */}
                <>
                  <div className='flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full'>
                    <div>Created</div>
                  </div>
                  <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                    <p className='text-balance'>{getDateFormat(uniqueFAQDetails?.created_at)}</p>
                  </div>
                </>

                <>
                  <div className='text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full'>
                    Id
                  </div>
                  <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                    <p className='  w-[697px] max-w-full outline-none bg-white txt-wrap-balance'>
                      {uniqueFAQDetails?._id}
                    </p>
                  </div>
                </>
                <>
                  <div className='text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full'>
                    Status
                  </div>
                  <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                    <p className='  w-[697px] max-w-full outline-none bg-white txt-wrap-balance'>
                      {uniqueFAQDetails?.status}
                    </p>
                  </div>
                </>
                <>
                  <div className='text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full'>
                    Type
                  </div>
                  <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                    <p className='  w-[697px] max-w-full outline-none bg-white txt-wrap-balance'>
                      {uniqueFAQDetails?.type}
                    </p>
                  </div>
                </>

                <>
                  <div className='text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full'>
                    Question
                  </div>
                  <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                    <p className='  w-[697px] max-w-full outline-none bg-white txt-wrap-balance'>
                      {uniqueFAQDetails?.question}
                    </p>
                  </div>
                </>
                <>
                  <div className='text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full'>
                    Answer
                  </div>
                  <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                    <p className='  w-[697px] max-w-full outline-none bg-white txt-wrap-balance'>
                      {uniqueFAQDetails?.answer}
                    </p>
                  </div>
                </>
              </div>

              {/*  */}
            </div>
          </>
        </PopUpLayout>
      )}
    </>
  );
};

export default FAQListing;

const Filters = ({ activeCategory, setActiveCategory }) => {
  const { categories, isLoading } = useFetchCategories();

  const handleChipClick = (id) => {
    setActiveCategory(id);
  };

  if (isLoading) {
    return <Stack mx={2}>Loading Categories...</Stack>;
  }
  return (
    <Stack mx={2}>
      <Typography variant='h6'>Categories: </Typography>
      <Stack gap={1} direction={'row'} flexWrap={'wrap'}>
        <Chip
          label='All Categories'
          variant={activeCategory === '' ? 'filled' : 'outlined'}
          onClick={() => handleChipClick('')}
        />
        {categories.map((category) => (
          <Chip
            label={category.name}
            variant={activeCategory === category._id ? 'filled' : 'outlined'}
            key={category._id}
            onClick={() => handleChipClick(category._id)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

