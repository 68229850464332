import React from 'react'
import Error from '../../components/common/Error'
import { successToast,errorToast } from '../../utils/toast';
import { forgotPassword } from '../../api/auth/login';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Box from "../layout/Box";
import { Button } from "@material-tailwind/react";



const schema = yup.object({
    email: yup.string().email("This Email is not valid").required("Email is required")
  });

const ForgotPassword = () => {
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        trigger,
        watch,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
      });

      const forgotApi = async (values) => {
        let payload = {};
        Object.keys(values).forEach((key) => {
          payload[key] = values[key];
        });
        const response = await forgotPassword(payload);
        if (response.status_code === 200) {
          successToast(response?.message);
          navigate("/");
        } else {
          errorToast(response?.message || "Something Went Wrong");
        }
      };
    
      const onSubmit = handleSubmit((values) => {
        forgotApi(values);
      });

  return (
    <>
     <Box>
        <form onSubmit={onSubmit}>
          <div className="m-0 h-[85vh] row  items-center ">
            <div className="z_index relative logo-sec col-md-6">
              <img src={logo} alt="logo" className=" w-[225px] mb-2" />
            </div>
            <div className="col-md-6 row justify-center items-center">
            <div className="h-12 w-[96] md:w-[440px] mt-10 flex flex-col justify-center rounded ">
            </div>
              <div className=" h-[340px] w-[696px] md:w-[440px] input_box_shadow mt-8 flex flex-col px-[24px] gap-8 rounded">
                <div className="flex gap-3 items-center text-center mt-4 ">
                </div>
                <div className=" flex w-[100%] flex-col items-end gap-8">
                  <input
                    className="form-control !h-[56px]"
                    type="text"
                    label="Email"
                    placeholder="Enter Email"
                    {...register("email")}
                  />
                  <Error
                    error={errors?.email}
                    message={errors?.email?.message}
                  />
                </div>

                <div className="flex items-center gap-3  w-full">
                  <Button
                    variant="gradient "
                    size="lg"
                    type="submit"
                    className="!w-[100%] !h-[56px]"
                  >
                   Send Password
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Box>
    </>
  )
}

export default ForgotPassword