import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = ({
  module = "",
  subModule = "",
  parent = "",
  child = "",
  subchild = "",
}) => {
  // from react router
  const { pathname } = useLocation();
  // link to redirect
  let redirect = "";
  // array from props
  let propsArray = [module, subModule, parent, child, subchild]
    .filter((item) => item)
    .map((item) => ({ label: item, value: "" }));

  // path array from url
  const pathnameArray = pathname.split("/").filter((item) => item);

  // navArray.forEach((ob, indx) => {
  //   pathnameArray.forEach((item, ind) => {
  //     if (ind === indx) {
  //       navArray[ind].value = item;
  //     } else return;
  //   });
  // });

  // function to create lable value for view and routing
  propsArray.forEach((obj, index) => {
    pathnameArray.forEach((item, ind) => {
      if (ind === index) {
        propsArray[ind].value = item;
      } else return;
    });
  });

  return (
    <>
      <div
        id="BreadcrumbSmallTextRoot"
        className="bg-white flex flex-row gap-1 w-full h-10 items-start pt-2 px-4 rounded-lg"
      >
        {/* dynamic */}
        {propsArray.map((pathObj, index) => {
          const isLast = index === propsArray.length - 1;
          redirect += `/${pathObj.value}`;
          return (
            <>
              <>
                {index === propsArray.length - 2 && (
                  <div className="flex flex-row justify-center items-start">
                    <div
                      to={redirect}
                      key={redirect}
                      id="Label"
                      className="text-xs font-['Hauora'] leading-[20px] text-[#212121] mt-px hover:text-blue-gray-300"
                    >
                      {pathObj?.value?.includes("edit") ? (
                        <span className="cursor-pointer">{pathObj?.label}</span>
                      ) : (
                        <div
                          to={redirect}
                          className="text-xs font-['Hauora'] leading-[20px] text-[#212121] hover:text-blue-gray-300
                    p-0"
                        >
                          {" "}
                          {pathObj?.label}
                        </div>
                      )}
                    </div>
                    {!isLast && (
                      <img
                        src="https://file.rendit.io/n/QqFombN9z93Zc5JoYtBC.svg"
                        alt="Next"
                        id="Next"
                        className="w-[21px] h-[21px] "
                      />
                    )}
                  </div>
                )}
              </>
              <>
                {index === propsArray.length - 1 && (
                  <div className="flex flex-row justify-center items-start">
                    <div
                      to={redirect}
                      key={redirect}
                      id="Label"
                      className="text-xs font-['Hauora'] leading-[20px] text-[#212121] mt-px hover:text-blue-gray-300"
                    >
                      {pathObj?.value?.includes("edit") ? (
                        <span className="cursor-pointer">{pathObj?.label}</span>
                      ) : (
                        <div
                          to={redirect}
                          className="text-xs font-['Hauora'] leading-[20px] text-[#212121] hover:text-blue-gray-300
                    p-0"
                        >
                          {" "}
                          {pathObj?.label}
                        </div>
                      )}
                    </div>
                    {!isLast && (
                      <img
                        src="https://file.rendit.io/n/QqFombN9z93Zc5JoYtBC.svg"
                        alt="Next"
                        id="Next"
                        className="w-[21px] h-[21px] "
                      />
                    )}
                  </div>
                )}
              </>
            </>
          );
        })}

        {/* static */}
        {/* <>
          <div
            id="Label"
            className="text-xs font-['Hauora'] leading-[20px] text-[#212121] mt-px"
          >
            {parent || "User list"}
          </div>
          <img
            src="https://file.rendit.io/n/QqFombN9z93Zc5JoYtBC.svg"
            alt="Next"
            id="Next"
            className="w-6"
          />
          <div
            id="Label1"
            className="text-xs font-['Hauora'] leading-[20px] text-[#212121] mt-px"
          >
            {child || "Alfonso Philips"}
          </div>
          {subchild && (
            <>
              <img
                src="https://file.rendit.io/n/QqFombN9z93Zc5JoYtBC.svg"
                alt="Next"
                id="Next"
                className="w-6"
              />
              <div
                id="Label1"
                className="text-xs font-['Hauora'] leading-[20px] text-[#212121] mt-px"
              >
                {subchild || "Alfonso Philips"}
              </div>
            </>
          )}
        </> */}
      </div>
    </>
  );
};

export default Breadcrumb;
