import React from "react";
import Breadcrumb from "../../../../components/console/customer-services/customer-mngmnt/Breadcrumb";
import NextScheduledPriceChange from "../../../../components/members&pricing/unlimited/next-schedule-price-change/NextScheduledPriceChange";
import { Typography } from "@material-tailwind/react";
const TABLE_HEAD = ["Effective date", "Price"];

const TABLE_ROWS = [
  {
    date: "23/11/13",
    time: "00:00",
    price: "$ 129",
   
  },
  {
    date: "23/11/01",
    time: "00:00",
    price: "$ 119",
   
  },
  {
    date: "23/10/01",
    time: "00:00",
    price: "$ 99",
    
  },
  {
    date: "23/09/01",
    time: "00:00",
    price: "$ 80",
    
  },
  {
    date: "23/08/01",
    time: "00:00",
    price: "$ 75",
    
  },
  {
    date: "23/07/01",
    time: "00:00",
    price: "$ 60",
    
  },
];
const PetPass = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center px-4 w-[calc(100vw-264px)]    mt-5   ">
        <Breadcrumb
          parent={"Pricing"}
          child={"Items Price"}
          subchild={"Reset Voucher"}
        />
        <div className="flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4   hide_scroll max-h-[calc(100vh-200px)] overflow-y-auto">
          <div className="bg-white flex flex-col  w-full  items-start pt-2 px-4 rounded-lg ">
            <b className="unlimited-heading mb-7 text-2xl ">Reset Voucher </b>
            <b className="p-price">Price </b>
            {/* next price change component */}
            <NextScheduledPriceChange layer={1} inflation={false} />
            {/* price history */}
            <p className="text-lg font-bold mb-3">Price History</p>
            {/* price-history-table */}
            <div className=" w-[-webkit-fill-available]   rounded-none  ">
              <table className="w-full  mx-4 table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100   px-14 text-start py-6"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {TABLE_ROWS.map(
                    ({ date, time, price, initation_fee }, index) => {
                      const isLast = index === TABLE_ROWS.length - 1;
                      const classes = isLast
                        ? "py-6 px-14"
                        : "py-6 px-14 border-none";

                      return (
                        <tr key={time}>
                          <td className={classes + " cursor-pointer "}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal flex  flex-row  gap-5"
                            >
                              <p>{date}</p>
                              <p>{time}</p>
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {price}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {initation_fee}
                            </Typography>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* footer */}
          <>
            <div className="footer-container">
              <div className="footer-body">
                <div className="f-delete div-3">Delete</div>
                <div className="f-cancel div-4">Cancel</div>
                <div className="f-draft div-5">Save Draft</div>
                <div className="f-apply div-6">Apply Pricing</div>
              </div>
            </div>
          </>
          {/*  */}
        </div>
      </div>
    </>
  );
};

export default PetPass;
