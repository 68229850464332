import api from "../interceptor";

// view all blog posted list
export const blogList = async (payload) =>
  await api.get(
    `/blogs_list?skip=${payload.skip}&limit=${payload.limit}&search=${payload.search}`
  );

// view blog on id
export const getBlog = async (payload) =>
  await api.get(`/get_blog?id=${payload}`);

// add new blog
export const addBlog = async (payload) => await api.post(`/add_blog`, payload);

// edit blog
export const editBlog = async (payload) =>
  await api.post(`/edit_blog`, payload);

// delete blog
export const deleteBlog = async (payload) =>
  await api.post(`/delete_blog`, payload);

// change blog status
export const updateBlogStatus = async (payload) =>
  await api.post(`/update_blog_status`, payload);
