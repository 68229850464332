import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Spinner } from "@material-tailwind/react";
import { errorToast } from "../../../utils/toast";

const UploadVideo = ({
  title = "",
  inputId = "",
  src = "",
  alt = "",
  imgId = "",
  type = "upload",
  name = "",
  setValue = () => { },
  register,
  accept = "image/png, image/gif, image/jpeg, image/tiff,",
  name2 = "",
  isLoading,
  setLoading,
  extraName = "",
  index = 0,
  preview = [],
  setPreview = () => { },
  trigger = () => { },
  isVideo = false,
  isEditable = true,
}) => {
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    $(`#${inputId}`).change(function () {
      var curElement = $(`#${imgId}`);
      var reader = new FileReader();
      reader.onload = async function (e) {
        // if(e?.target?.result?.indexOf("video/")>-1){
        //     curElement.attr("src",defaultVideo);
        if (name2) {
          setValue(name2, "video");
        }
        //  else if (e?.target?.result?.indexOf("video/") > -1) {
        //   curElement.attr("src", defaultVideo);
        // }

        // }
        // else if(e?.target?.result?.indexOf("/json")>-1){
        //     curElement.attr("src",json);
        //     if(name2){
        //         setValue(name2,'jsonData')
        //     }
        // }
        else if (isVideo) {
          await curElement.attr("src", e.target.result);
          console.log(e.target);
          setLoader(false);
        } else {
          curElement.attr("src", e.target.result);
          if (name2) {
            setValue(name2, "image");
          }

          if (extraName) {
            document.getElementById(extraName).src = e.target.result;
          }
        }
      };
      reader.readAsDataURL(this.files[0]);
    });
  }, []);
  return (
    <>
      <>
        <div className=" mt-4 text-neutral-800 text-base font-semibold leading-6 self-stretch max-md:max-w-full">
          Video
        </div>

        <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5 text-balance flex flex-col">
          <label htmlFor={inputId}>
            <video
              className={`VideoInput_video ${loader && "image-upload-input"}`}
              id={imgId}
              width="100%"
              height={"400px"}
              controls
              src={src}
            />
            {title}
          </label>
          <input
            type="file"
            className="form-control image-upload-input"
            id={inputId}
            name={name}
            onChange={async (e) => {
              if (e.target || e.srcE) console.log(e, "main input");
              if (type === "single-upload") {
                let tempArr = [];
                preview?.map((item, i) => {
                  if (i === index) {
                    item = e.target.files[0];
                  }
                  tempArr.push(item);
                });
                setPreview(tempArr);
              }
              // setLoading(true)
              if (type === "upload") {
                setLoader(true);
                if (e.target.files[0].size / 1024 ** 2 < 1025) {
                  setValue(name, e.target.files);
                } else {
                  errorToast("File size cannot be more than 1 GB ");
                  setLoader(false);
                }
              } else if (type === "mix-file") {
                setValue(name, e.target.files);
              }
              // else{
              //     let response = await uploadImage(e.target.files[0])
              //     setValue(name,response)
              // }
              // setLoading(false)
              trigger(name);
            }}
            accept={accept}
          />
          {loader && (
            <div className="flex justify-center items-center self-center">
              <Spinner className="h-16 w-16 text-gray-900/50" />
            </div>
          )}

          <label
            for={!loader && isEditable ? inputId : ""}
            className={`text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-center self-stretch border border-black bg-white mt-4 px-16 py-3 rounded-lg border-solid max-md:max-w-full max-md:px-5 ${!loader && isEditable ? "cursor-pointer" : "cursor-not-allowed"
              }`}
          >
            Update Video
          </label>
        </div>
      </>
      {/*  */}
    </>
  );
};

export default UploadVideo;
