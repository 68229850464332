import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, Tooltip } from "@material-tailwind/react";
import { Card } from "react-bootstrap";
import Toggle from "react-toggle";
import { useNavigate } from "react-router-dom";
import {
  getAllAdmins,
  updateAdminStatus,
} from "../../../api/subadmin/subadmin";
import { faPen, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { errorToast, successToast } from "../../../utils/toast";
import CustomPagination from "../../../components/common/CustomPagination";
import ContainerHeading from "../../../components/common/container-heading/ContainerHeading";
import { useSelector } from "react-redux";
import { getAnnouncements } from "../../../api/notifications";
import NoDataFound from "../../../components/common/NoDataFound";

const Announcement = () => {
  const TABLE_HEAD = ["Message", "Title", "Sent To", "Action"];

  // values from redux
  const { loader } = useSelector((state) => state.loader);
  // react-router
  const navigate = useNavigate();
  // local state
  const [pageCount, setPageCount] = useState(1);
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [annoucementList, setAnnouncementList] = useState([]);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    getAnnouncement();
  }, [skip]);

  const getAnnouncement = async () => {
    let payload = {
      skip,
      limit,
    };
    const response = await getAnnouncements(payload);
    if (response?.status_code == 200) {
      setAnnouncementList(response?.data || []);
      setPageCount(Math.ceil(response?.totalItems / payload.limit) || 0);
    }
  };
  return (
    <>
      <div>
        <div className="common-bg rounded-t-2xl">
          {/* heading */}
          <ContainerHeading
            redirect={"/app-home-cards/announcements/add-announcements"}
            title={"Announcements"}
            button={"Announcements"}
          />
          <Card className=" !z-5 overflow-x-auto rounded-none">
            <table className="w-full  table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]"
                    >
                      <Typography
                        variant="small"
                        color="black"
                        className="font-normal leading-none font-semibold"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {annoucementList?.map((item, index) => {
                  const isLast = index === annoucementList.length - 1;
                  const classes = isLast
                    ? "py-2 px-3 "
                    : "py-2 px-3 border-b border-blue-gray-50";

                  return (
                    <tr key={index}>
                      <td
                        className={classes + " cursor-pointer"}
                        // onClick={() => {
                        //     navigate("/dashboard/customer-management/view-user-list");
                        // }}
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.message || "N/A"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.title || "N/A"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.userNames?.slice(0, 2).map((user, index) => (
                            <span key={index}>
                              {user}
                              {index < 1 && ", "}
                            </span>
                          ))}
                          {item?.userNames?.length > 2 &&
                            `+${item?.userNames?.length - 2} more`}
                          {/* {item?.users.map((item)=>item) || "N/A"} */}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div class="action-toggle !flex">
                          <Tooltip content={"View"}>
                            <FontAwesomeIcon
                              onClick={() => {
                                {
                                  navigate(
                                    `/app-home-cards/announcements/view-announcements/${item?._id}`
                                  );
                                  setEditable(false);
                                  setId(item?._id);
                                }
                              }}
                              className="edit-btn"
                              icon={faEye}
                            />
                          </Tooltip>
                          {/* <Tooltip content={"Delete"}>
                                                    <FontAwesomeIcon
                                                        // onClick={() => {
                                                        //     delete(item?._id);
                                                        // }}
                                                        className="delete-btn mx-2"
                                                        icon={faTrash}
                                                    />
                                                </Tooltip> */}
                          <Tooltip content={"Edit"}>
                            <FontAwesomeIcon
                              disabled={item?.status === "inactive"}
                              onClick={() => {
                                if (item?.status !== "inactive") {
                                  navigate(
                                    `/app-home-cards/announcements/edit-announcements/${item?._id}`
                                  );
                                  setEditable(true);
                                  setId(item?._id);
                                } else {
                                  errorToast(
                                    "Please active this subadmin first"
                                  );
                                }
                              }}
                              className="edit-btn mx-2"
                              icon={faPen}
                            />
                          </Tooltip>
                          {/* <div class="action-toggle gap-4">
                                                            <Toggle
                                                                checked={
                                                                    item?.status === "active" ? "active" : ""
                                                                }
                                                                icons={false}
                                                                className={
                                                                    item?.status === "active"
                                                                        ? "active"
                                                                        : "isDisable"
                                                                }
                                                                onChange={() => onActive(item?._id, item?.status)}
                                                            />
                                                        </div> */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {annoucementList.length === 0 && !loader && <NoDataFound />}
          </Card>
        </div>
        {/* pagination */}

        <div className="bg-white flex flex-col gap-1 w-full   items-end pt-2 px-2   rounded-b-lg   ">
          {pageCount > 1 && (
            <Card className="py-2 rounded-none   ">
              <span className="bg-[#E6E6E6] py-3  px-2 w-auto items-center ml-3  rounded-xl ">
                <CustomPagination
                  page={skip}
                  setPage={setSkip}
                  count={pageCount}
                />
              </span>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};

export default Announcement;
