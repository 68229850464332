import React from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import Test from "../pages/test/Test";
import CustomerLiveChat from "../pages/console/customer-services/customer-live-chat/CustomerLiveChat";
// import Inbox from "../pages/console/customer-services/inbox/Inbox";
import CustomerMgnt from "../pages/console/customer-services/customer-mgnt/CustomerMgnt";
import FAQs from "../pages/console/cms/faqs/FAQs";
// import Legal from "../pages/console/cms/legal/Legal";
// import PageManagement from "../pages/console/cms/page-management/PageManagement";
// import CommunicationTemplates from "../pages/console/cms/communication-templates/CommunicationTemplates";
// import AppAnnouncementsSurveys from "../pages/console/product-mngm/app-announcements-surveys/AppAnnouncementsSurveys";
import AppContent from "../pages/console/product-mngm/app-content/AppContent";
import Giveaways from "../pages/console/product-mngm/giveaways/Giveaways";
import Pricing from "../pages/console/product-mngm/pricing/Pricing";
import Plans from "../pages/console/product-mngm/membership-plans/Plans";
import AdminUserMgmt from "../pages/admin-mgmt/user-mgmt/AdminUserMgmt";
import RolesPermissions from "../pages/admin-mgmt/roles/RolesPermissions";
import Login from "../pages/login/Login";
import { Layout } from "../pages/console/customer-services/customer-mgnt/details/Layout";
import NewTest from "../pages/test/NewTest";
import { PaymentGateway } from "../pages/payment-gateways/PaymentGateway";
import UnlimitedPricing from "../pages/memberships&pricing/memberships-price/unlimited/UnlimitedPricing";
import MembershipsPricing from "../pages/memberships&pricing/MembershipsPricing";
import PageManagement from "../pages/content-mgmt-system/page-mgmt/PageManagement";
import CommunicationTemplates from "../pages/content-mgmt-system/communication-templates/CommunicationTemplates";
import AppAnnouncementsSurveys from "../pages/content-mgmt-system/app-announcement&surveys/AppAnnouncementsSurveys";
import CustomerManagement from "../pages/dashboard/user-mgmt/CustomerManagement";
import LiveChat from "../pages/dashboard/live-chat/LiveChat";
import PetPass from "../pages/memberships&pricing/items-pricing/pet-pass/PetPass";
import ResetVoucher from "../pages/memberships&pricing/items-pricing/reset-voucher/ResetVoucher";
import AddDiscount1 from "../pages/memberships&pricing/discounts/add-discount/AddDiscount1";
import AddDiscount2 from "../pages/memberships&pricing/discounts/add-discount/AddDiscount2";
import FAQ from "../pages/content-mgmt-system/page-mgmt/faq/FAQ";
import Legal from "../pages/content-mgmt-system/page-mgmt/legal/Legal";
import HomePage from "../pages/content-mgmt-system/page-mgmt/home-page/HomePage";
import Testimonail from "../pages/content-mgmt-system/page-mgmt/forms/testimonial/Testimonail";
import { TabTest } from "../pages/test/arun_tests/TabTest";
import LegalListing from "./../pages/content-mgmt-system/page-mgmt/legal/LegalListing";
import EditDiscount from "../pages/memberships&pricing/discounts/edit-discount/EditDiscount";
import EditGuestPasses from "../pages/memberships&pricing/botique/guest-passes/EditGuestPasses";
import EditGiftCard from "../pages/memberships&pricing/botique/gift-card/EditGiftCard";
import FAQListing from "../pages/content-mgmt-system/page-mgmt/faq/FAQListing";
import AddNewFAQ from "../pages/content-mgmt-system/page-mgmt/faq/AddNewFAQ";
import AddMembership from "../pages/memberships&pricing/AddMembership";
import AddNewLegal from "../pages/content-mgmt-system/page-mgmt/legal/add-legal/AddNewLegal";
import NotificationListing from "../pages/notification/NotificationListing";
import Career from "../pages/content-mgmt-system/page-mgmt/career/Career";
import CreateNewJob from "../pages/content-mgmt-system/page-mgmt/career/jobs/CreateNewJob";
import PilotProfile from "../pages/flightmangement/pilots/PilotProfile";
import { PilotLayout } from "../pages/flightmangement/pilots/add-pilot/PilotLayout";
import RouteList from "../pages/flightmangement/route/RouteList";
import FlightList from "../pages/flightmangement/flightschedules/FlightList";
import Investors from "../pages/content-mgmt-system/page-mgmt/investors/Investors";
import LocationListing from "../pages/flightmangement/location/LocationListing";
import AdminContactUsListing from "../pages/content-mgmt-system/communication-templates/contact-us/AdminContactUsListing";
import AddAdminContactUs from "../pages/content-mgmt-system/communication-templates/contact-us/AddAdminContactUs";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import Subadmin from "../pages/subadmin/Subadmin";
import AddSubadmin from "../pages/subadmin/AddSubadmin";
import NewsAndBlogListing from "../pages/content-mgmt-system/page-mgmt/news&blog/NewsAndBlogListing";
import AddNewsAndBLog from "../pages/content-mgmt-system/page-mgmt/news&blog/AddNewsAndBLog";
import ForgotPassword from "../pages/forgotpassword/ForgotPassword";
import AddDiscount3 from "../pages/memberships&pricing/discounts/add-discount/AddDiscount3";
import { ExistingMemberPriceChanges } from "../pages/memberships&pricing/existing-member-price-change/ExistingMemberPriceChanges";
import AddExistingMemberPriceChange from "../pages/memberships&pricing/existing-member-price-change/add-existing-member-price-change/AddExistingMemberPriceChange";
import AllMembership from "../pages/memberships&pricing/existing-member-price-change/AllMembership";
import MembershipHistoryDetails from "../pages/memberships&pricing/existing-member-price-change/MembershipHistoryDetails";
import DashboardGraph from "../pages/dashboard/graph/DashboardGraph";
import InboxListing from "../pages/dashboard/inbox/InboxListing";
import AboutUs from "../pages/content-mgmt-system/page-mgmt/about-us/AboutUs";
import MediaAndPress from "../pages/content-mgmt-system/page-mgmt/media/MediaAndPress";
import CommunicationTemplateListing from "../pages/content-mgmt-system/communication-templates/CommunicationTemplateListing";
import Announcement from "../pages/dashboard/announcements/Announcement";
import AddAnnouncement from "../pages/dashboard/announcements/AddAnnouncement";
import InboxView from "../pages/dashboard/inbox/InboxView";
import PrivateRouteIndependent from "./PrivateRouteIndependent";
import FBORostering from "../pages/FBO/FBO-rostering/FBORostering";
import FoodInventory from "../pages/FBO/food-inventory/FoodInventory";
import { Sales } from "../pages/memberships&pricing/sales/Sales";
import NavigationManagement from "../pages/content-mgmt-system/navigation-mgmt/NavigationManagement";
import MembershipPaymentGateway from "../pages/payment-gateways";
import BlacklistedPayments from "../pages/payment-gateways/blackListedPayments";
import ContactUsCategories from "../pages/dashboard/contact-us-categories";
import CustomerServiceSignatures from "../pages/dashboard/customer-service-signatures";
import FaqCategory from "../pages/content-mgmt-system/page-mgmt/faq/faq-category";

const Path = () => {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
          <Route element={<PrivateRouteIndependent />}>
            <Route path="/dashboard/live-chat" element={<LiveChat />} />
          </Route>
          <Route element={<MainLayout />}>
            {/* <Route index element={<CustomerLiveChat />} /> */}
            <Route path=":main" element={<Test />} />
            {/* for Customer service */}
            <Route path="/console/customer-live-chat" element={<PrivateRoute><CustomerLiveChat /></PrivateRoute>} />
            {/* <Route path="/console/inbox" element={<PrivateRoute><Inbox /></PrivateRoute>} /> */}
            <Route path="/console/customer-management" element={<PrivateRoute><CustomerMgnt /></PrivateRoute>} />
            <Route path="/console/customer-management/user-list" element={<PrivateRoute><Layout /></PrivateRoute>} />
            {/* for CMS */}
            <Route path="/console/faqs" element={<PrivateRoute><FAQs /></PrivateRoute>} />
            <Route path="/console/legal" element={<PrivateRoute><Legal /></PrivateRoute>} />
            <Route path="/console/page-management" element={<PrivateRoute><PageManagement /></PrivateRoute>} />
            <Route path="/console/communication-templates" element={<PrivateRoute><CommunicationTemplates /></PrivateRoute>} />
            {/* for product management */}
            <Route path="/console/app-announcements-surveys" element={<PrivateRoute><AppAnnouncementsSurveys /></PrivateRoute>} />
            <Route path="/console/app-content" element={<PrivateRoute><AppContent /></PrivateRoute>} />
            <Route path="/console/giveaways" element={<PrivateRoute><Giveaways /></PrivateRoute>} />
            <Route path="/console/pricing/:page" element={<PrivateRoute><Pricing /></PrivateRoute>} />
            <Route path="/console/plans" element={<PrivateRoute><Plans /></PrivateRoute>} />

            {/* dashboard */}
            <Route path="/dashboard/graph" element={<PrivateRoute pagename={"Dashboard"}><DashboardGraph /></PrivateRoute>} />
            {/* >> user mgmnt */}
            <Route path="/dashboard/user-management" element={<PrivateRoute pagename={"Dashboard"}><CustomerManagement /></PrivateRoute>} />
            <Route path="/dashboard/user-management/user-list" element={<PrivateRoute pagename={"Dashboard"}><CustomerManagement /></PrivateRoute>} />
            {/* user list table */}
            <Route path="/dashboard/user-management/user-list/view-user-list" element={<PrivateRoute pagename={"Dashboard"}><CustomerManagement /></PrivateRoute>} />
            {/* user details */}
            <Route path="/dashboard/user-management/user-list/view-user-list/:id" element={<PrivateRoute pagename={"Dashboard"}><Layout /></PrivateRoute>} />
            <Route path="/dashboard/user-management/user-list/edit-user-list/:id" element={<PrivateRoute pagename={"Dashboard"}><Layout /></PrivateRoute>} />
            <Route path="/dashboard/user-management/user-list/add-user-list" element={<PrivateRoute pagename={"Dashboard"}><Layout /></PrivateRoute>} />
            <Route path="/dashboard/sub-admin" element={<PrivateRoute pagename="Admin Management" ><Subadmin /></PrivateRoute>} />
            <Route path="/dashboard/sub-admin/add-sub-admin" element={<PrivateRoute pagename="Admin Management" ><AddSubadmin /></PrivateRoute>} />
            <Route path="/dashboard/sub-admin/edit-sub-admin/:id" element={<PrivateRoute pagename="Admin Management" ><AddSubadmin /></PrivateRoute>} />
            {/* <Route path="/dashboard/announcements" element={<PrivateRoute pagename={"Dashboard"}><Announcement /></PrivateRoute>} />
            <Route path="/dashboard/announcements/add-announcements" element={<PrivateRoute pagename={"Dashboard"}><AddAnnouncement /></PrivateRoute>} />
            <Route path="/dashboard/announcements/view-announcements/:id" element={<PrivateRoute pagename={"Dashboard"}><AddAnnouncement /></PrivateRoute>} />
            <Route path="/dashboard/announcements/edit-announcements/:id" element={<PrivateRoute pagename={"Dashboard"}><AddAnnouncement /></PrivateRoute>} /> */}

            {/* inbox */}
            <Route path="/dashboard/inbox" element={<PrivateRoute pagename={"Dashboard"}><InboxListing /></PrivateRoute>} />
            <Route path="/dashboard/inbox-view/:id" element={<PrivateRoute pagename={"Dashboard"}><InboxView /></PrivateRoute>} />

            {/* Contact us categories */}
            <Route path="/dashboard/contact-us-categories" element={<PrivateRoute pagename={"Dashboard"}><ContactUsCategories /></PrivateRoute>} />
            {/* Customer service signatures */}
            <Route path="/dashboard/customer-service-signatures" element={<PrivateRoute pagename={"Dashboard"}><CustomerServiceSignatures /></PrivateRoute>} />
            {/* Memberships & Pricing */}
            <Route exact={true} path="/prices/membership/payments-gateway" element={<PrivateRoute pagename="Membership&Pricing">
              <MembershipPaymentGateway />
            </PrivateRoute>} >
              <Route exact={true} path="" element={<PaymentGateway />} />
              <Route exact={true} path="blacklisted-payments" element={<BlacklistedPayments />} />

            </Route>
            <Route exact={true} path="/prices/membership/add-memberships" element={<PrivateRoute pagename="Membership&Pricing"><AddMembership /></PrivateRoute>} />
            <Route exact={true} path="/prices/membership/edit-memberships/:id" element={<PrivateRoute pagename="Membership&Pricing"><AddMembership /></PrivateRoute>} />
            <Route path="/prices/:type/:name/:id" element={<PrivateRoute pagename="Membership&Pricing"><UnlimitedPricing /></PrivateRoute>} />
            <Route exact={true} path="/prices/membership/:page" element={<PrivateRoute pagename="Membership&Pricing"><MembershipsPricing /></PrivateRoute>} />
            <Route exact={true} path="/prices/membership/discounts/add-discounts" element={<PrivateRoute pagename="Membership&Pricing"><AddDiscount1 /></PrivateRoute>} />
            <Route exact={true} path="/prices/membership/discounts/add-discount" element={<PrivateRoute pagename="Membership&Pricing"><AddDiscount3 /></PrivateRoute>} />
            <Route exact={true} path="/prices/membership/discounts/edit-discount/:id" element={<PrivateRoute pagename="Membership&Pricing"><AddDiscount3 /></PrivateRoute>} />
            <Route exact={true} path="/prices/membership/discounts/view-discount/:id" element={<PrivateRoute pagename="Membership&Pricing"><AddDiscount3 /></PrivateRoute>} />
            <Route path="/membership/unlimited" element={<PrivateRoute pagename="Membership&Pricing"><UnlimitedPricing /></PrivateRoute>} />
            <Route path="/membership/items-pricing/pet-pass" element={<PrivateRoute pagename="Membership&Pricing"><PetPass /></PrivateRoute>} />
            <Route path="/membership/add-discount2" element={<PrivateRoute pagename="Membership&Pricing"><AddDiscount2 /></PrivateRoute>} />
            <Route path="/membership/discount/edit-discount" element={<PrivateRoute pagename="Membership&Pricing"><EditDiscount /></PrivateRoute>} />
            <Route path="/membership/items-pricing/reset-voucher" element={<PrivateRoute pagename="Membership&Pricing"><ResetVoucher /></PrivateRoute>} />
            <Route path="/membership/boutique/guest-passes/edit" element={<PrivateRoute pagename="Membership&Pricing"><EditGuestPasses /></PrivateRoute>} />
            <Route path="/membership/boutique/gift-card/edit" element={<PrivateRoute pagename="Membership&Pricing"><EditGiftCard /></PrivateRoute>} />
            {/* existing-member-price-change */}
            <Route path="/membership/existing-member-price-change" element={<PrivateRoute pagename="Membership&Pricing"><ExistingMemberPriceChanges /></PrivateRoute>} />
            <Route path="/membership/existing-member-price-change" element={<PrivateRoute pagename="Membership&Pricing"><AllMembership /></PrivateRoute>} />
            <Route path="/prices/membership/existing-member-price-changes/edit/:id/:user_id" element={<PrivateRoute pagename="Membership&Pricing"><AddExistingMemberPriceChange /></PrivateRoute>} />
            <Route path="/prices/membership/existing-member-price-changes/view/:id/:user_id" element={<PrivateRoute pagename="Membership&Pricing"><AddExistingMemberPriceChange /></PrivateRoute>} />
            <Route path="/prices/membership/existing-member-price-changes/membership-history/:user_membership_id" element={<PrivateRoute pagename="Membership&Pricing"><MembershipHistoryDetails /></PrivateRoute>} />
            {/* for Payment Gateway */}
            {/* <Route path="/payments/" element={<PrivateRoute pagename="Payment Gateway"><PaymentGateway /></PrivateRoute>} /> */}


            {/* for App Home Cards */}
            {/* announcement & surveys from content mgmt */}
            <Route path="/app-home-cards/surveys" element={<PrivateRoute pagename={"App Home Cards"}><AppAnnouncementsSurveys /></PrivateRoute>} />
            {/* <Route path="/app-home-cards/surveys" element={<AppAnnouncementsSurveys />} /> */}
            {/* Announcement from dashboard */}
            <Route path="/app-home-cards/announcements" element={<PrivateRoute pagename={"App Home Cards"}><Announcement /></PrivateRoute>} />
            <Route path="/app-home-cards/announcements/add-announcements" element={<PrivateRoute pagename={"App Home Cards"}><AddAnnouncement /></PrivateRoute>} />
            <Route path="/app-home-cards/announcements/view-announcements/:id" element={<PrivateRoute pagename={"App Home Cards"}><AddAnnouncement /></PrivateRoute>} />
            <Route path="/app-home-cards/announcements/edit-announcements/:id" element={<PrivateRoute pagename={"App Home Cards"}><AddAnnouncement /></PrivateRoute>} />
            <Route path="/app-home-cards/special-sales" element={<PrivateRoute pagename={"App Home Cards"}><Sales /></PrivateRoute>} />

            {/* <Route path="/app-home-cards/announcements" element={<Announcement />} />
            <Route path="/app-home-cards/announcements/add-announcements" element={<AddAnnouncement />}/>
            <Route path="/app-home-cards/announcements/view-announcements/:id" element={<AddAnnouncement />}/>
            <Route path="/app-home-cards/announcements/edit-announcements/:id" element={<AddAnnouncement />}/>
            <Route path="/app-home-cards/special-sales" element={<Sales />} /> */}

            {/* for Flight's Management */}
            <Route path="/flights/pilot-profile" element={<PrivateRoute pagename={"Flight Management"}><PilotProfile /></PrivateRoute>} />
            <Route path="/flights/pilot-profile/add-new-pilot" element={<PrivateRoute pagename={"Flight Management"}><PilotLayout /></PrivateRoute>} />
            <Route path="/flights/pilot-profile/edit-pilot/:id" element={<PrivateRoute pagename={"Flight Management"}><PilotLayout /></PrivateRoute>} />
            <Route path="/flights/loaction" element={<PrivateRoute pagename={"Flight Management"}><LocationListing /></PrivateRoute>} />
            <Route path="/flights/route" element={<PrivateRoute pagename={"Flight Management"}><RouteList /></PrivateRoute>} />
            <Route path="/flights/flight-schedules" element={<PrivateRoute pagename={"Flight Management"}><FlightList /></PrivateRoute>} />

            {/* FBO */}

            {/*FBO-Rostering  */}
            <Route path="/fbo/fbo-rostering" element={<PrivateRoute pagename={"FBO"}><FBORostering /></PrivateRoute>} />
            <Route path="/fbo/food-inventory" element={<PrivateRoute pagename={"FBO"}><FoodInventory /></PrivateRoute>} />




            {/* for Content management system */}
            <Route path="/content/page-mgmt" element={<PrivateRoute pagename={"Content Management"}><PageManagement /></PrivateRoute>} />
            <Route path="/content/communication-templates" element={<PrivateRoute pagename={"Content Management"}><CommunicationTemplates /></PrivateRoute>} />
            {/* Page Management */}
            {/* homepage */}
            <Route path="/content/page-mgmt/home-page" element={<PrivateRoute pagename={"Content Management"}><HomePage /></PrivateRoute>} />
            {/* about us */}
            <Route path="/content/page-mgmt/about-us" element={<PrivateRoute pagename={"Content Management"}><AboutUs /></PrivateRoute>} />
            {/* Contact US */}
            {/* Legal */}
            <Route path="/content/page-mgmt/legal" element={<PrivateRoute pagename={"Content Management"}><LegalListing /></PrivateRoute>} />
            <Route path="/content/page-mgmt/legal/add-legal" element={<PrivateRoute pagename={"Content Management"}><AddNewLegal /></PrivateRoute>} />
            <Route path="/content/page-mgmt/legal/edit-legal/:id" element={<PrivateRoute pagename={"Content Management"}><AddNewLegal /></PrivateRoute>} />
            <Route path="/content/page-mgmt/legal/listing" element={<PrivateRoute pagename={"Content Management"}><Legal /></PrivateRoute>} />
            {/* FAQ */}
            <Route path="/content/page-mgmt/faq" element={<PrivateRoute pagename={"Content Management"}><FAQListing /></PrivateRoute>} />
            <Route path="/content/page-mgmt/faq-category" element={<PrivateRoute pagename={"Content Management"}><FaqCategory /></PrivateRoute>} />
            <Route path="/content/page-mgmt/faq/add-faq" element={<PrivateRoute pagename={"Content Management"}><AddNewFAQ /></PrivateRoute>} />
            <Route path="/content/page-mgmt/faq/edit-faq/:id" element={<PrivateRoute pagename={"Content Management"}><AddNewFAQ /></PrivateRoute>} />
            <Route path="/content/page-mgmt/faq/listing" element={<PrivateRoute pagename={"Content Management"}><FAQ /></PrivateRoute>} />
            {/* news and blog */}
            <Route path="/content/page-mgmt/news&blog" element={<PrivateRoute pagename={"Content Management"}><NewsAndBlogListing /></PrivateRoute>} />
            {/* add/edit news blog */}
            <Route path="/content/page-mgmt/news&blog/add" element={<PrivateRoute pagename={"Content Management"}><AddNewsAndBLog /></PrivateRoute>} />
            <Route path="/content/page-mgmt/news&blog/edit/:id" element={<PrivateRoute pagename={"Content Management"}><AddNewsAndBLog /></PrivateRoute>} />
            <Route path="/content/page-mgmt/faq/listing" element={<PrivateRoute pagename={"Content Management"}><FAQ /></PrivateRoute>} />
            {/* media press */}
            <Route path="/content/page-mgmt/media-press" element={<PrivateRoute pagename={"Content Management"}><MediaAndPress /></PrivateRoute>} />
            {/* investors */}
            <Route path="/content/page-mgmt/investors" element={<PrivateRoute pagename={"Content Management"}><Investors /></PrivateRoute>} />
            {/* Careers */}
            {/* job listing */}
            <Route path="/content/page-mgmt/careers/:page" element={<PrivateRoute pagename={"Content Management"}><Career /></PrivateRoute>} />
            {/* crate new job */}
            <Route path="/content/page-mgmt/careers/job-listing/create-new" element={<PrivateRoute pagename={"Content Management"}><CreateNewJob /></PrivateRoute>} />
            {/* edit job */}
            <Route path="/content/page-mgmt/careers/job-listing/edit-job/:id" element={<PrivateRoute pagename={"Content Management"}><CreateNewJob /></PrivateRoute>} />
            <Route path="/content/page-mgmt/testimonial" element={<PrivateRoute pagename={"Content Management"}><Testimonail /></PrivateRoute>} />
            {/* communication */}
            {/* admin contact us */}
            <Route path="/content/communication/contact-us" element={<PrivateRoute pagename={"Content Management"}><AdminContactUsListing /></PrivateRoute>} />
            {/* add new contact us */}
            <Route path="/content/communication/contact-us/add-contact" element={<PrivateRoute pagename={"Content Management"}><AddAdminContactUs /></PrivateRoute>} />
            {/* edit contact-us */}
            <Route path="/content/communication/contact-us/edit-contact/:id" element={<PrivateRoute pagename={"Content Management"}><AddAdminContactUs /></PrivateRoute>} />
            {/* communication templates */}
            <Route path="/content/communication/communication-templates" element={<PrivateRoute pagename={"Content Management"}><CommunicationTemplateListing /></PrivateRoute>} />
            <Route path="/content/navigation-mgmt" element={<PrivateRoute pagename={"Content Management"}><NavigationManagement /></PrivateRoute>} />

            {/* app announcement & surveys */}
            {/* <Route path="/content/app-announcements-surveys" element={<PrivateRoute pagename={"Content Management"}><AppAnnouncementsSurveys /></PrivateRoute>} /> */}
            {/* notification */}
            <Route path="/notifications" element={<PrivateRoute><NotificationListing /></PrivateRoute>} />
            {/* admin Mgmt */}
            <Route path="/admin-mgmt/admin-user-management" element={<PrivateRoute><AdminUserMgmt /></PrivateRoute>} />
            <Route path="/admin-mgmt/roles-permissions" element={<PrivateRoute><RolesPermissions /></PrivateRoute>} />
            {/* //PaymentGateway */}
            {/* <Route path="/payments-gateway" element={<PrivateRoute pagename={"Payment Gateway"}><PaymentGateway /></PrivateRoute>} exact /> */}
            {/* test routes */}
            <Route path="/test/tabs" element={<PrivateRoute><TabTest /></PrivateRoute>} exact />
            {/* </Route> */}
            <Route path="/testing" element={<PrivateRoute><NewTest /></PrivateRoute>} />
          </Route>
        </Routes>
      </HashRouter>
    </div>
  );
};

export default Path;
