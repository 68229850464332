const intisialState = {
  loader: false,
};

const loaderReducer = (state = intisialState, action) => {
  switch (action.type) {
    case "SET_LOADER":
      return { ...state, loader: action.payload };
    default:
      return state;
  }
};

export default loaderReducer;
