import "react-phone-input-2/lib/style.css";
import * as yup from "yup";
import CustomEditor from "../../../../../../components/common/CustomEditor";
import CustomLabel from "../../CustomLabal";
import CustomerManagementInput from "../../CustomerManagementInput";
import Error from "../../../../../../components/common/Error";
import Form from "react-bootstrap/Form";
import ImageUpload from "../../../../../../components/common/ImageUpload";
import PhoneInput from "react-phone-input-2";
import React, { useEffect, useRef, useState } from "react";
import ReactPhoneInput from "react-phone-input-2";
import Select from "react-select";
import SelectWithChips from "../../SelectWithChips";
import parsePhoneNumber from "libphonenumber-js";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@material-tailwind/react";
import { MenuItem } from "@mui/material";
import { Select as MUISelect } from "@mui/material/Select";
import { Country } from "country-state-city";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getDateFormat } from "../../../../../../utils/moment";
import { errorToast } from "../../../../../../utils/toast";

// import Button from "react-bootstrap/Button";
import {
  getAllExpertise,
  onAddUsers,
  onEditUsers,
  viewAllUsers,
} from "../../../../../../api/users";
import {
  imageValidation,
  uploadImageTemplate,
} from "../../../../../../helper/index";
let countryCode = "IN";

const userSchema = yup.object().shape({
  fullName: yup
    .string()
    .matches(
      /^[a-zA-Z\s']+$/,
      "Full Name should only contain letters and spaces"
    )
    .required("Full Name is required")
    .max(50, "Max 50 characters allwoed")
    .min(2, "Min 2 characters allowed"),
  preferredFirstName: yup
    .string()
    .matches(/^[a-zA-Z\s']+$/, "Name should only contain letters and spaces")
    .required("Name is required")
    .max(50, "Max 50 characters allwoed")
    .min(2, "Min 2 characters allowed"),
  gender: yup.string().required("Gender is required"),
  status: yup.string().required("Status is required"),
  email: yup
    .string()
    .email("This email is not valid")
    .required("Email is required"),
  phone_code: yup.string(),
  occupation: yup
    .string()
    .required("This field is required")
    .max(100, "Max 100 characters"),
  industries: yup
    .array()
    .of(yup.string())
    .min(1, "Minimum 1 required")
    .required("Min 1 is required"),
  annual_income: yup
    .number()
    .required("This field is required")
    .transform((originalValue, originalObject) => {
      // Convert empty string to NaN
      return originalObject.annual_income === "" ? NaN : originalValue;
    })
    .typeError("Annual income must be a number")
    .min(100000, "Annual income must be at least 1,00,000")
    .max(500000, "Annual income must not exceed 5,00,000"),
  birthday: yup.string().required("Birthday is required"),
  profile_pic: yup.mixed().required("Profile Image is required"),
  phone: yup
    .string()
    .required("Phone is required")
    .test("Check Indian Number", function () {
      let number = this.parent["phone"];
      if (!number) return false;
      const digits = number.replace(/\D/g, "") || "";
      const phoneNumber = parsePhoneNumber(digits, countryCode);
      return !!phoneNumber ? phoneNumber.isValid() : false;
    }),
  // code_phone: yup.string().default(""),
  // dial_code: yup.string().default(""),
});

const ItemsManagement = ({ setUserDetails }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const phoneInput = useRef(null);
  const { id } = useParams();

  const [userData, setUserData] = useState({});
  const editRoute =
    location?.pathname?.indexOf(
      `/dashboard/user-management/user-list/edit-user-list`
    ) > -1
      ? true
      : false;
  const viewRoute =
    location?.pathname?.indexOf(
      `/dashboard/user-management/user-list/view-user-list`
    ) > -1
      ? true
      : false;

  useEffect(() => {
    let tempCountries = Country.getAllCountries();
    let newCountries = [];
    tempCountries.forEach((item) => {
      let obj = {
        value: item.phonecode,
        label: `+${item.phonecode} ${item.name} ${item.flag}`,
      };
      newCountries.push(obj);
    });
    // console.log(tempCountries);
    // setCountries(newCountries);
    // setSelectedCountry(newCountries[0]);
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const addUser = async (values) => {
    let formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === "profile_pic") {
        formData.append("profile_pic", values.profile_pic[0]);
      } else if (key === "phone_code") {
        formData.append(
          "phone_code",
          values?.phone_code?.indexOf("+") > -1
            ? values?.phone_code
            : `+${values?.phone_code}`
        );
      } else if (key === "industries") {
        formData.append("industries", JSON.stringify(values.industries));
      } else {
        formData.append(key, values[key]);
      }
    });
    formData.append("country_code", countryCode);
    const response = await onAddUsers(formData);
    if (response.status_code === 200) {
      navigate("/dashboard/user-management");
    } else {
      errorToast(response?.message || "Something Went Wrong");
    }
  };

  const editUser = () => {};

  const onSubmit = handleSubmit((values) => {
    if (editRoute) {
      editUser(values);
    } else {
      addUser(values);
    }
  });

  const items = [
    { name: "Guest Pass" },
    { name: "Pet Pass" },
    { name: "User Pass" },
  ];

  return (
    <>
      <div className="py-10 pt-0 h-[500px] tab_scroll overflow-y-scroll">
        <form onSubmit={onSubmit}>
          <div className="px-2 pt-0">
            <h2 className="text-[20px] font-bold text-[#303637] py-4">
              Items Management
            </h2>
            <div className="flex flex-col gap-4 management-items-wrap">
              {items?.map((item) => {
                return (
                  <div
                  //   className="flex flex-row gap-6"
                  >
                    <div className=" mt-3 flex flex-row justify-start gap-10 items-center">
                      <CustomLabel
                        className="font-semibold"
                        label={item.name}
                      />
                      <input
                        className="form-control mt-2 w-52"
                        type="text"
                        placeholder="Enter Value"
                        // onKeyDown={(e) => alphabetValues(e)}
                        // {...register("city_name")}
                        // disabled={modalType === "view" ? true : false}
                      />
                    </div>
                    {/* <h2 className="text-[20px] font-bold text-[#303637]">
                      {item.name}
                    </h2>
                    <input placeholder="Value"></input> */}
                  </div>
                );
              })}
            </div>
          </div>
        </form>
        <div className=" bg-white flex flex-col gap-1 w-full  items-start mr-2 rounded-lg  mt-10  ">
          <button
            type="submit"
            className={`text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-black text-white px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 ${
              editRoute ? "cursor-pointer" : "cursor-not-allowed"
            }`}
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
};

export default ItemsManagement;
