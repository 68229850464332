// import 'moment/locale/de';
import moment from "moment";
import { useEffect, useState } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Error from '../Error';

const DateTimeSingle = ({ setValue = () => { }, errors = {}, name = "", minDateTime = null, maxDateTime = null, className = "", onChange = () => { }, data = {}, value = null }) => {
    const [locale, setLocale] = useState('en-us');
    const [date, setDate] = useState(null)
    const [min, setMin] = useState(null)
    const [max, setMax] = useState(null)
    useEffect(() => {
        setDate(value ? moment(value) : moment())
    }, [data])

    useEffect(() => {
        setMin(minDateTime ? moment(minDateTime) : null)
    }, [minDateTime])

    useEffect(() => {
        setMax(maxDateTime ? moment(maxDateTime) : null)
    }, [maxDateTime])

    if (moment.locale() !== locale) {
        moment.locale(locale);
    }
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
            <DateTimePicker className={className} value={date} onChange={e => {
                setDate(e)
                onChange(e);
            }} minDateTime={min} maxDateTime={max} />
            <Error error={errors?.[name]} />
        </LocalizationProvider>
    )
}

export default DateTimeSingle