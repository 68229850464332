import React, { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import MembershipDropdown from "../../../components/common/CustomDropdown";
import TextField from "@mui/material/TextField";
import { Button } from "@material-tailwind/react";
import InputAdornment from "@mui/material/InputAdornment";
import { AccountCircle, Search } from "@mui/icons-material";
import { AppUserManagement } from "./AppUserManagement";
import CustomPagination from "../../../components/common/CustomPagination";
import { getAllUsers } from "../../../api/users";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ContainerHeading from "../../../components/common/container-heading/ContainerHeading";
import { useSelector } from "react-redux";

const userType = [
  {
    name: "Member",
    value: "true",
  },
  {
    name: "Free",
    value: "false",
  },
];

const CustomerManagement = () => {
  // values from redux
  const { loader } = useSelector((state) => state.loader);
  // const { page } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [userList, setUserList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [member, setMember] = useState("");
  const [skip, setSkip] = useState(1);

  useEffect(() => {
    getUserList();
  }, [search, pageCount, member, skip]);

  const getUserList = async () => {
    let payload = {
      skip: skip,
      limit: limit,
      isMember: member ? member : "",
      search: search ? search : "",
    };
    const response = await getAllUsers(payload);
    if (response?.status_code === 200) {
      setUserList(response?.data || []);
      setPageCount(Math.ceil(response?.totalItems / limit) || 0);
    }
  };

  return (
      <div className="common-bg w-full  user-management">
        <div className="scroll-section">
          <ContainerHeading
            title={"App user management"}
            search={true}
            searchValue={search}
            setSearchValue={setSearch}
            dropdownSelect={true}
            options={userType}
            setSelected={setMember}
            button={"Add user"}
            redirect={`/dashboard/user-management/user-list/add-user-list`}
          />
          {
            <AppUserManagement
              userList={userList}
              getUserList={getUserList}
              loader={loader}
            />
          }
          {/* pagination */}

          <div className="bg-white flex flex-col gap-1 w-full   items-end pt-2 px-2   rounded-b-lg   ">
            {pageCount > 1 && (
              <Card className="py-2 rounded-none   ">
                <span className="bg-[#E6E6E6] py-3  px-2 w-auto items-center ml-3  rounded-xl ">
                  <CustomPagination
                    page={skip}
                    setPage={setSkip}
                    count={pageCount}
                  />
                </span>
              </Card>
            )}
          </div>
          {/* <Card className="py-5 rounded-none  rounded-b-2xl ">
            <span className="bg-[#E6E6E6] py-3  px-2 w-72 items-end ml-3  rounded-xl ">
              {count > 1 && (
                <CustomPagination count={count} page={page} setPage={setPage} />
              )}
            </span>
          </Card> */}
        </div>
      </div>
  );
};

export default CustomerManagement;
