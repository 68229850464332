import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getCategories, getEnquiryById, sendMessage, updateInboxStatus } from '../../../api/inbox';
import { Select, Option } from "@material-tailwind/react";
import { errorToast, successToast } from '../../../utils/toast';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { List } from '@mui/material';
import CustomEditor from '../../../components/common/CustomEditor';

const InboxView = () => {
    const { id } = useParams();
    const [enquiry, setEnquiry] = useState({});
    const [type, setType] = useState("");
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("");

    const handleClose = () => {
        onClose(false);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const onClose = (type) => {
        setOpen(type)
    }


    const [options, setOptions] = useState([
        { label: "All", value: "" },
        { label: "Contact Us", value: "contact_us" },
        { label: "Media Press", value: "media_press" },
        { label: "Investors", value: "investors" },
        { label: "Careers", value: "careers" },
        { label: "FAQ", value: "faq" },
        { label: "About Us", value: "about_us" },
        { label: "Legal", value: "legal" },
    ]);
    useEffect(() => {
        getEnquiry();
        getOptions();
    }, [])

    const getOptions = async() => {
        let response = await getCategories();
        if(response?.status_code===200){
          let data = response?.data?.map((item)=>({label: item?.name, value: item?.type})) || []
          data.unshift({ label: "All", value: "" })
          setOptions(data)
        }
      }

    const getEnquiry = async () => {
        const response = await getEnquiryById({ id });
        if (response?.status_code === 200) {
            setEnquiry(response?.data || {});
            setType(response?.data?.type)
        }
    }

    const handleSelect = async (e) => {
        const type = e
        let response = await updateInboxStatus({ id, type, isRead: "", status: "" });
        if (response?.status_code === 200) {
            getEnquiry()
        } else {
            errorToast(response?.message)
        }
    }

    const sendMessageTrigger = async () => {
        let response = await sendMessage({id, message})
        if(response?.status_code===200){
            successToast(response?.message)
            setOpen(false)
        }else{
            errorToast(response?.message)
        }
    }
    return (
        <>
            <div className='flex bg-[#fff] rounded-md p-4 mt-8'>
                <div className='flex-[0.7]'>
                    <div className='flex justify-center items-center mb-[16px]'>
                        <div className='flex flex-[0.3]'>
                            <div className='mr-2 font-bold'>
                                Name:
                            </div>
                            <div className='mr-2 '>
                                {(enquiry?.firstName + " " + enquiry?.lastName) || "NA"}
                            </div>
                        </div>
                        <div className='flex flex-[0.7]'>
                            <div className='font-bold flex-[0.4] text-right mr-2'>
                                Acc Type:
                            </div>
                            <div className='flex-[0.6] capitalize'>
                                {enquiry?.acctType?.replaceAll("_", " ") || "NA"}
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center items-center mb-[16px]'>
                        <div className='flex flex-[0.3]'>
                            <div className='font-bold flex-[0.4]'>
                                Email:
                            </div>
                            <div className='flex-[0.6]'>
                                {enquiry?.email || "NA"}
                            </div>
                        </div>
                        <div className='flex flex-[0.7]'>
                            <div className='font-bold flex-[0.4] text-right mr-2'>
                                Contact Number:
                            </div>
                            <div className='flex-[0.6]'>
                                {enquiry?.phone || "NA"}
                            </div>
                        </div>
                    </div>
                    <hr className='mt-8' />
                    <p className='my-8' dangerouslySetInnerHTML={{ __html: enquiry?.enQuiry }}></p>
                    <hr className='mb-8' />
                    <div className='flex mb-[8px]'>
                        <div className='flex-[0.3]'>IP Address IPv4:</div>
                        <div className='flex-[0.7]'>{enquiry?.ipv4 || "NA"}</div>
                    </div>
                    <div className='flex mb-[8px]'>
                        <div className='flex-[0.3]'>IP Address IPv6:</div>
                        <div className='flex-[0.7]'>{enquiry?.ipv6 || "NA"}</div>
                    </div>
                    <div className='flex mb-[8px]'>
                        <div className='flex-[0.3]'>Device:</div>
                        <div className='flex-[0.7]'>{enquiry?.device || "NA"}</div>
                    </div>
                    <div className='flex mb-[8px]'>
                        <div className='flex-[0.3]'>Browser Window:</div>
                        <div className='flex-[0.7]'>{enquiry?.browserWindow || "NA"}</div>
                    </div>
                    <div className='flex mb-[8px]'>
                        <div className='flex-[0.3]'>Computer Screen:</div>
                        <div className='flex-[0.7]'>{enquiry?.computerScreen || "NA"}</div>
                    </div>
                </div>
                <div className='flex-[0.3]'>
                    <Select
                        className="capitalize rounded-lg "
                        label="Category"
                        onChange={handleSelect}
                        value={type}
                    >
                        {options?.map((option, option_index) => {
                            return (
                                <Option
                                    className="capitalize"
                                    key={option_index}
                                    value={option?.value}
                                >
                                    {option?.label || option?.name}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
            </div>
            <div className='bg-[#fff] rounded-md p-2 mt-2 flex justify-end'>
                <button className='rounded-md px-3 py-2'>Cancel</button>
                <button className='rounded-md px-3 py-2 bg-[#333] text-[#fff] w-[200px]' onClick={() => setOpen(true)}>Reply</button>
                <Dialog onClose={handleClose} open={open}>
                    <div className='p-4'>
                        <h2 className='font-bold text-[20px] mb-3 ml-2'>Reply</h2>
                        <CustomEditor onChange={(e) => setMessage(e.target.getContent())} />
                        <div className='flex justify-end'>
                            <button className='rounded-md px-3 py-2 bg-[#333] text-[#fff]' onClick={() => sendMessageTrigger()}>Reply</button>
                        </div>
                    </div>
                    {/* <List sx={{ pt: 0 }}>
                    </List> */}
                </Dialog>
            </div>
        </>
    )
}

export default InboxView