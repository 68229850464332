import React from "react";

const CustomLabel = ({ label }) => {
  return (
    <>
      <h2 className="text-[#141414] text-[14px] font-medium">{label}</h2>
    </>
  );
};

export default CustomLabel;
