import React from 'react'
import { Card, Typography } from "@material-tailwind/react";
import NoDataFound from '../../../components/common/NoDataFound'
import ContainerHeading from '../../../components/common/container-heading/ContainerHeading';

const DashboardGraph = () => {
  return (
    <>
      <div className="common-bg w-full">
        <div className="scroll-section">
          <ContainerHeading
            title={"Dashboard"}
          />
          <Card className=" mb-6 h-[calc(100vh-250px)] !z-5 overflow-x-scroll hide_scroll rounded-none   w-[calc(100vw-300px)]">
            <table className="w-full min-w-max table-auto text-left">
              <NoDataFound />
            </table>
          </Card>
        </div>
      </div>
    </>
  )
}

export default DashboardGraph