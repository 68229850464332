import { Card, Tooltip, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import CustomPagination from "../../../components/common/CustomPagination";
import ContainerHeading from "../../../components/common/container-heading/ContainerHeading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import Toggle from "react-toggle";
import NoDataFound from "../../../components/common/NoDataFound";
import { getCategories, getInbox, updateInboxStatus } from "../../../api/inbox";
import { useNavigate } from "react-router-dom";
import ActionTooltip from "../../../components/members&pricing/ActionTooltip";
import InboxTooltip from "./InboxToolTip";
import { errorToast } from "../../../utils/toast";
import { ButtonBase } from "@mui/material";

const TABLE_HEAD = [
  "",
  "",
  "First Name",
  "Last Name",
  "Email",
  "Contact Number",
  "Related To",
  "Read by",
  "Action"
];

const InboxListing = () => {
  const [pageCount, setPageCount] = useState();
  const [filter, setFilter] = useState({
    search: "",
    type: "",
    limit: 10,
    skip: 1
  });
  const navigate = useNavigate()
  const [options, setOptions] = useState([
    { label: "All", value: "" },
    { label: "Contact Us", value: "contact_us" },
    { label: "Media Press", value: "media_press" },
    { label: "Investors", value: "investors" },
    { label: "Careers", value: "careers" },
    { label: "FAQ", value: "faq" },
    { label: "About Us", value: "about_us" },
    { label: "Legal", value: "legal" },
  ]);
  const [inboxList, setInboxList] = useState([]);

  useEffect(() => {
    getInboxList()
  }, [filter])

  useEffect(() => {
    getOptions()
  }, [])

  const getInboxList = async () => {
    let response = await getInbox(filter);
    if (response?.status_code === 200) {
      setInboxList(response?.data || [])
      setPageCount(Math.floor(response?.totalItems / filter.limit))
    } else {
      setInboxList([])
    }
  }

  const getOptions = async () => {
    let response = await getCategories();
    if (response?.status_code === 200) {
      let data = response?.data?.map((item) => ({ label: item?.name, value: item?.type })) || []
      data.unshift({ label: "All", value: "" })
      setOptions(data)
    }
  }

  const handleSearch = (value) => {
    setFilter({ ...filter, search: value })
  }

  const handleType = (value) => {
    setFilter({ ...filter, type: value })
  }

  const handleSkip = (value) => {
    setFilter({ ...filter, skip: value })
  }

  const handleStatus = async (id, type, isRead, status) => {
    let response = await updateInboxStatus({ id, type, isRead, status });
    if (response?.status_code === 200) {
      getInboxList()
    } else {
      errorToast(response?.message)
    }
  }

  const leftSidebarMenuItem = [
    { label: 'Contact', menuItems: [{ label: 'Initial Contacts', count: 12 }], },
    {
      label: 'Email', menuItems: [
        { label: 'Inbox', count: 1298 },
        { label: 'Starred', count: 10 },
        { label: 'Draft', count: 219 },
        { label: 'Junk', count: 15 },
        { label: 'Sent mail', count: 6 },
        { label: 'Trash', count: 120 },
      ],
    }]

  const [selectedMenuItem, setSelectedMenuItem] = useState(leftSidebarMenuItem[0].menuItems[0].label)

  return (
    <div className="inbox-container">
      <div className="header-container">
        <ContainerHeading
          title={"Inbox"}
          search={true}
          searchValue={filter.search}
          setSearchValue={handleSearch}
          dropdownSelect={true}
          options={options}
          setSelected={handleType}
        />
      </div>
      <div className="main-wrapper">
        <div className="left-bar-wrapper">
          <div className="items-container">
            {leftSidebarMenuItem?.map(item => {
              return (
                <div key={item.label} className="item-container">
                  <span className="label">{item.label}</span>
                  <div className="items-menu-container">
                    {item?.menuItems?.map((menuItem) => {
                      return (
                        <ButtonBase onClick={() => setSelectedMenuItem(menuItem?.label)} className={`item-menu-container ${selectedMenuItem === menuItem?.label ? "active" : ""}`}>
                          <span className="label">{menuItem?.label}</span>
                          <span className="count">{menuItem?.count}</span>
                        </ButtonBase>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="main-content">
          <div className="custom-table">
            <table className="w-full text-left relative">
              <thead className="sticky top-0 bg-white">
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="px-3 text-start py-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {inboxList.map((item, index) => {
                  console.log(item)
                  const isLast = index === inboxList.length - 1;
                  const classes = isLast
                    ? "py-4 px-3"
                    : "py-4 px-3 border-b border-blue-gray-50 td-dot";

                  return (
                    <tr key={index}>
                      <td className={classes}>
                        <div className={`status-indicator ${!item.readBy ? 'new' : ''}`} />
                      </td>
                      <td className={classes}>
                        <svg className="fav-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg">
                          <path d="M463 192H315.9L271.2 58.6C269 52.1 262.9 48 256 48s-13 4.1-15.2 10.6L196.1 192H48c-8.8 0-16 7.2-16 16 0 .9.1 1.9.3 2.7.2 3.5 1.8 7.4 6.7 11.3l120.9 85.2-46.4 134.9c-2.3 6.5 0 13.8 5.5 18 2.9 2.1 5.6 3.9 9 3.9 3.3 0 7.2-1.7 10-3.6l118-84.1 118 84.1c2.8 2 6.7 3.6 10 3.6 3.4 0 6.1-1.7 8.9-3.9 5.6-4.2 7.8-11.4 5.5-18L352 307.2l119.9-86 2.9-2.5c2.6-2.8 5.2-6.6 5.2-10.7 0-8.8-8.2-16-17-16zm-127.2 92.5c-10 7.2-14.2 20.2-10.2 31.8l30.1 87.7c1.3 3.7-2.9 6.8-6.1 4.6l-77.4-55.2c-4.9-3.5-10.6-5.2-16.3-5.2-5.7 0-11.4 1.7-16.2 5.2l-77.4 55.1c-3.2 2.3-7.4-.9-6.1-4.6l30.1-87.7c4-11.8-.2-24.8-10.3-32l-81-57.1c-3.2-2.2-1.6-7.3 2.3-7.3H196c12 0 22.7-7.7 26.5-19.1l29.6-88.2c1.2-3.6 6.4-3.6 7.6 0l29.6 88.2c3.8 11.4 14.5 19.1 26.5 19.1h97.3c3.9 0 5.5 5 2.3 7.2l-79.6 57.5z"></path>
                        </svg>
                      </td>
                      <td className={classes + " cursor-pointer"}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {(item?.firstName) || "N/A"}
                        </Typography>
                      </td>
                      <td className={classes + " cursor-pointer"}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {(item?.lastName) || "N/A"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.email || "N/A"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.phone || "N/A"}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal capitalize"
                        >
                          {item?.type?.replaceAll("_", " ") || "N/A"}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal capitalize"
                        >
                          {item?.readBy || "N/A"}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <div class="action-toggle">
                          <InboxTooltip id={item?._id} options={options} handleStatus={handleStatus} handleView={() => navigate("/dashboard/inbox-view/" + item?._id)} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {inboxList.length === 0 && <NoDataFound />}
          <div className="pagination-wrapper">
            <CustomPagination
              page={filter.skip}
              setPage={handleSkip}
              count={pageCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InboxListing;
