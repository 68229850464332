import DateTimeSingle from '../../../../components/common/dateTime/DateTimeSingle';
import Error from '../../../../components/common/Error';
import React, { useState } from 'react';
import deleteicon from '../../../../assets/images/icons/delete_icon.png';
import { numberValidation } from '../../../../utils/regex';

const PriceForm = ({
  field,
  index,
  errors,
  setValue,
  type,
  register,
  activePrices,
  showdelete,
  remove,
  isUnlimitedElite,
  showInitiationFees,
}) => {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const handleStartTimeChange = (e) => {
    setValue(`schedule_prices.${index}.effectiveDate`, e?.toISOString());
    setStart(e);
  };
  const handleEndTimeChange = (e) => {
    setValue(`schedule_prices.${index}.effectiveEndDate`, e?.toISOString());
    setEnd(e);
  };
  const price = isUnlimitedElite ? 'Delta Price' : 'Price';
  return (
    <div key={field.id} className='mt-2 flex justify-around w-full items-center'>
      <div className='flex flex-row gap-24'>
        <div class=' flex-col justify-start items-start gap-1 inline-flex'>
          <div className='price2'>Effective date</div>
          <div class='justify-start items-center gap-1 flex'></div>
          <DateTimeSingle
            maxDateTime={end}
            key={`schedule_prices.${index}.effectiveDate`}
            className='p-0'
            setValue={setValue}
            errors={errors}
            name={`schedule_prices.${index}.effectiveDate`}
            onChange={handleStartTimeChange}
            data={activePrices}
            value={field?.effectiveDate || activePrices[index]?.effectiveDate}
          />
          <Error error={errors?.['schedule_prices']?.[index]} />
        </div>
        {type === 'boutique' && (
          <div class=' flex-col justify-start items-start gap-1 inline-flex'>
            <div className='price2'>Effective End Date</div>
            <div class='justify-start items-center gap-1 flex'></div>
            <DateTimeSingle
              minDateTime={start}
              key={`schedule_prices.${index}.effectiveEndDate`}
              className='p-0'
              setValue={setValue}
              errors={errors}
              name={`schedule_prices.${index}.effectiveEndDate`}
              onChange={handleEndTimeChange}
              data={activePrices}
              value={field?.effectiveEndDate || activePrices[index]?.effectiveEndDate}
            />
          </div>
        )}

        <div className='flex gap-4'>
          <div class=' flex-col justify-start items-start gap-1 inline-flex'>
            <div className='price2'>{price}</div>
            <div class='self-stretch w-36  px-3 py-3 bg-white rounded-lg border border-stone-300 flex-col justify-start items-start gap-2.5 flex'>
              <div class='self-stretch  justify-start items-center gap-2 inline-flex'>
                <div class='justify-start items-center gap-1 flex'>
                  <div class="text-black text-opacity-20 text-base font-normal font-['Hauora']">
                    $
                  </div>
                </div>
                <div className='text'>
                  <input
                    name='effective-time'
                    placeholder='00:00'
                    className='text outline-none w-[100%]'
                    onKeyDown={numberValidation}
                    {...register(`schedule_prices.${index}.price`)}
                  />
                </div>
              </div>
            </div>
            <Error error={errors?.['schedule_prices']?.[index]?.['price']} />
          </div>
          {showInitiationFees && (
            <div class='initial-price-wrap flex-col justify-start items-start gap-1 inline-flex'>
              <div className='price2'>Initiation Fee</div>
              <div class='self-stretch w-36 px-3 py-3 bg-white rounded-lg border border-stone-300 flex-col justify-start items-start gap-2.5 flex'>
                <div class='self-stretch  justify-start items-center gap-2 inline-flex'>
                  <div class='justify-start items-center gap-1 flex'>
                    <div class="text-black text-opacity-20 text-base font-normal font-['Hauora']">
                      $
                    </div>
                  </div>
                  <div className='text'>
                    <input
                      name='effective-time'
                      placeholder='00:00'
                      className='text outline-none w-[100%]'
                      onKeyDown={numberValidation}
                      {...register(`schedule_prices.${index}.initiationFees`)}
                    />
                  </div>
                </div>
              </div>
              <Error error={errors?.['schedule_prices']?.[index]?.['initiationFees']} />
            </div>
          )}
        </div>
      </div>
      <div className='flex gap-4'>
        {showdelete && (
          <div className='delete-icon-wrap'>
            <img
              className='delete-next-icon cursor-pointer'
              alt='next-layer'
              src={deleteicon}
              onClick={() => {
                remove(index);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceForm;
