import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import ContainerHeading from '../../../../../components/common/container-heading/ContainerHeading';
import Error from '../../../../../components/common/Error';
import { addLegal, editLegal, viewLegal } from '../../../../../api/content-mgmt/legal';
import { errorToast, successToast } from '../../../../../utils/toast';
import CustomEditor from '../../../../../components/common/CustomEditor';

// schema
const addLegalSchema = yup.object().shape({
  legal_title: yup.string().required('Title is required').max(200, 'Max charcter limit reached'),
  legalContent: yup.string().required('Content is required'),
});

const AddNewLegal = () => {
  // router functions
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location?.pathname?.indexOf('edit-legal') > -1 ? true : false;
  const [loading, setLoading] = useState(true && isEdit);
  // faq id from params
  const { id } = useParams();

  //useform functions
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      legalContent: '',
      legal_title: '',
    },
    resolver: yupResolver(addLegalSchema),
  });

  //  subtitle usefieldArray
  const {
    fields: subtitle,
    append: subtitleAppend,
    prepend: subtitlePrepend,
    remove: subtitleRemove,
    swap: subtitleSwap,
    move: subtitleMove,
    insert: subtitleInsert,
    update: subtitleUpdate,
  } = useFieldArray({
    control,
    name: 'subtitle',
  });

  console.log(subtitle, 'subtitle');

  //
  const getLegal = async () => {
    setLoading(true);
    try {
      let response = await viewLegal(id);
      console.log(response, 'response');
      setValue('legalContent', response?.data?.legalContent);
      setValue('legal_title', response?.data?.legal_title);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // edit legal function
  const handleEditLegal = async (values) => {
    console.log(values);
    let payload = {
      id: id,
      legalContent: values.legalContent,
      legalTitle: values.legal_title,
    };

    const response = await editLegal(payload);
    if (response.status_code === 200) {
      successToast(response?.message);
      navigate('/content/page-mgmt/legal');
    } else if (response.status_code === 400) {
      errorToast(response?.message);
    }
  };

  //  add new legal function
  const handleAddLegal = async (values) => {
    let payload = {
      legalTitle: values.legal_title,
      legalContent: values.legalContent,
    };
    console.log(payload, 'payload');
    try {
      const response = await addLegal(payload);
      if (response.status_code === 200) {
        successToast(response?.message);
        navigate('/content/page-mgmt/legal');
      } else if (response.status_code === 400) {
        errorToast(response?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // form submit function
  const onSubmit = handleSubmit((values) => {
    if (isEdit) handleEditLegal(values);
    else handleAddLegal(values);
  });

  // sideEffect
  useEffect(() => {
    if (isEdit) {
      getLegal();
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className='flex flex-col justify-center items-center'>
        {/* <Breadcrumb
            module={"Content Management"}
            subModule={"Page management"}
            parent={"Legal"}
            child={isEdit ? "Edit Legal" : "Add Legal"}
          /> */}
        <div className=' w-full  hide_scroll  overflow-y-auto'>
          <div className='mt-0'>
            <ContainerHeading
              className={'rounded-lg'}
              title={isEdit ? 'Update Legal Section' : ' Add New Legal Section'}
            />
          </div>
          {/* forms */}
          <form onSubmit={onSubmit}>
            <div className=' bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4 pl-4  '>
              {/* title */}
              <>
                <div className='text-neutral-900 text-sm font-medium leading-4 mt-6 '>
                  Legal Title
                </div>
                <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                  <input
                    className='  w-[697px] max-w-full outline-none bg-white'
                    placeholder='Legal Title'
                    {...register('legal_title')}
                  />
                </div>

                <Error error={errors?.legal_title} message={errors?.legal_title?.message} />
              </>
              {/* Add subtitle button */}
              <div>
                <div className=' flex gap-2 mt-4 max-md:max-w-full max-md:mt-10'>
                  <text className='text-neutral-800 text-lg font-bold'>Subtitle</text>
                </div>
              </div>

              <CustomEditor
                initialValue={getValues('legalContent')}
                // defaultValues={getValues('legalContent')}
                onChange={(value) => {
                  setValue('legalContent', value.target.getContent());
                  trigger('legalContent');
                }}
              />

              <Error error={errors?.legalContent} message={errors?.legalContent?.message} />

              {/* footer-button */}
              <div className='flex flex-row mt-4'>
                <>
                  <div
                    className=' bg-white flex flex-col gap-1 w-full  items-start mr-2 rounded-lg mb-2  cursor-pointer'
                    onClick={() => navigate(-1)}
                  >
                    <div className='text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white text-black px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 '>
                      Cancel
                    </div>
                  </div>
                </>

                <>
                  <div className=' bg-white flex flex-col gap-1 w-full  items-start mr-2 rounded-lg mb-2  '>
                    <button
                      type='submit'
                      className='text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-black text-white px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 cursor-pointer'
                    >
                      {isEdit ? 'Update' : 'Save'}
                    </button>
                  </div>
                </>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddNewLegal;
