import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import Select from "react-select";

import ContainerHeading from "../../../components/common/container-heading/ContainerHeading";

import { errorToast, successToast } from "../../../utils/toast";
import { addOrUpdateSavedLocation, getAllNavLocations, viewAllSavedLocation } from "../../../api/content-mgmt/navigationMgmt";


const NavigationManagement = () => {
  // router functions
  const navigate = useNavigate();

  // local state
const [options,setOptions] = useState([])
const [key,setKey] = useState()
  const [navigationState, setnavigationState] = useState({
    faqsLocationIds: [],
    legalLocationIds: [],
    aboutUsLocationIds: [],
    contactUsLocationIds: [],
    careersLocationIds: [],
    mediaPressLocationIds: [],
    investorsLocationIds: [],
    newsLocationIds: [],
  });


  // sideEffect
  useEffect(() => {
    fetchAllNavigations();
    fetchAllSavedLocations();
  }, []);

  const handleChange = (selected, type) => {
    setnavigationState({ ...navigationState, [type]: selected });

    // let selectedCityData = selected.map((item) => item.label);
  };

  //
  const fetchAllNavigations = async () => {
    let response = await getAllNavLocations();
    let optionsForNav = response?.data?.map(item=>({value:item._id,label:item.name}))
    // console.log(response,"resp")
    setOptions(optionsForNav)
  };

  const fetchAllSavedLocations = async() => {
    let response = await viewAllSavedLocation()
    setKey(response?.data?._id)
    let responseData = {
    }
    Object.keys(response?.data).forEach(key=>{
      if(key !== "_id" && key!== "status" && key!=="createdAt" && key!== "updatedAt")
      // console.log(key,"key")
      responseData[key] = response?.data?.[key]?.map(item=>({value:item._id,label:item.name}))
    })
    console.log(responseData,"rsdata")
    setnavigationState(responseData)

  }


  // function to handle navigation update
  const handleNavigationUpdate = async() => {
    // console.log(navigationState)
    let payload = {
      id:key
    }
    Object.keys(navigationState).forEach(key=>{
      payload[key] = navigationState[key].map(item=>item.value)
    })
    console.log(payload,"payload")
  let response = await addOrUpdateSavedLocation(payload)
  if(response?.status_code ===200) {
    successToast(response?.message);
    viewAllSavedLocation();
  }
  else {
    errorToast(response?.message);
  }

  }

  // form submit function
  // const onSubmit = handleSubmit((values) => {
  //   handleAddNewsAndBlogs(values);
  // });

  // console
  // console.log(navigationState, "nav");

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        {/* <Breadcrumb
            module={"Content Management"}
            subModule={"Page management"}
            parent={"News & Blog"}
            child={isEdit ? "Edit Blog" : "Add Blog"}
          /> */}
        <div className=" w-full  hide_scroll  overflow-y-auto">
          <div className="mt-0">
            <ContainerHeading
              className={"rounded-lg"}
              title={"Navigation Management"}
            />
          </div>
          {/* forms */}
          <form>
            <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4 pb-4 pl-4  ">
              <div className="text-neutral-900 text-2xl font-semibold leading-4 mt-6 ">
                FAQs
              </div>
              <>
                {/* title */}
                <div className="w-[746px]  items-end mt-3">
                  <div className="text-neutral-900 text-sm font-bold leading-4 mt-6 ">
                    Navigation Locations
                  </div>
                  <div className="flex flex-col">
                    <Select
                      options={options}
                      isMulti
                      closeMenuOnSelect={false}
                      value={navigationState.faqsLocationIds}
                      onChange={(selected) => handleChange(selected, "faqsLocationIds")}
                      placeholder="Select Navigations"
                      className="basic-multi-select multiple-select-2"
                      classNamePrefix="select"
                      defaultValue=""
                    />
                  </div>
                </div>
              </>

              <>{/* slug */}</>
            </div>

            {/* LEGAL */}
            <div className=" w-full bg-white flex flex-col gap-1  items-start pt-2  rounded-lg mt-4 pb-4 pl-4  ">
              <div className="text-neutral-900 text-2xl font-semibold leading-4 mt-6 ">
                Legal
              </div>
              {/* author title */}
              <div className="w-[746px]  items-end mt-3">
                <div className="text-neutral-900 text-sm font-bold leading-4 mt-6 ">
                  Navigation Locations
                </div>
                <div className="flex flex-col">
                  <Select
                    options={options}
                    isMulti
                    closeMenuOnSelect={false}
                    value={navigationState.legalLocationIds}
                    onChange={(selected) => handleChange(selected, "legalLocationIds")}
                    placeholder="Select Navigations"
                    className="basic-multi-select multiple-select-2"
                    classNamePrefix="select"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>

            {/*  About Us */}
            <div className=" w-full bg-white flex flex-col gap-1  items-start pt-2  rounded-lg mt-4 pb-4 pl-4  ">
              <div className="text-neutral-900 text-2xl font-semibold leading-4 mt-6 ">
                About us
              </div>
              {/* author title */}
              <div className="w-[746px]  items-end mt-3">
                <div className="text-neutral-900 text-sm font-bold leading-4 mt-6 ">
                  Navigation Locations
                </div>
                <div className="flex flex-col">
                  <Select
                    options={options}
                    isMulti
                    closeMenuOnSelect={false}
                    value={navigationState.aboutUsLocationIds}
                    onChange={(selected) => handleChange(selected, "aboutUsLocationIds")}
                    placeholder="Select Navigations"
                    className="basic-multi-select multiple-select-2"
                    classNamePrefix="select"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>

            {/*  Contact us */}
            <div className=" w-full bg-white flex flex-col gap-1  items-start pt-2  rounded-lg mt-4 pb-4 pl-4  ">
              <div className="text-neutral-900 text-2xl font-semibold leading-4 mt-6 ">
                Contact us
              </div>
              {/* author title */}
              <div className="w-[746px]  items-end mt-3">
                <div className="text-neutral-900 text-sm font-bold leading-4 mt-6 ">
                  Navigation Locations
                </div>
                <div className="flex flex-col">
                  <Select
                    options={options}
                    isMulti
                    closeMenuOnSelect={false}
                    value={navigationState.contactUsLocationIds}
                    onChange={(selected) =>
                      handleChange(selected, "contactUsLocationIds")
                    }
                    placeholder="Select Navigations"
                    className="basic-multi-select multiple-select-2"
                    classNamePrefix="select"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>

            {/*  Career */}
            <div className=" w-full bg-white flex flex-col gap-1  items-start pt-2  rounded-lg mt-4 pb-4 pl-4  ">
              <div className="text-neutral-900 text-2xl font-semibold leading-4 mt-6 ">
                Careers
              </div>
              {/*  title */}
              <div className="w-[746px]  items-end mt-3">
                <div className="text-neutral-900 text-sm font-bold leading-4 mt-6 ">
                  Navigation Locations
                </div>
                <div className="flex flex-col">
                  <Select
                    options={options}
                    isMulti
                    closeMenuOnSelect={false}
                    value={navigationState.careersLocationIds}
                    onChange={(selected) => handleChange(selected, "careersLocationIds")}
                    placeholder="Select Navigations"
                    className="basic-multi-select multiple-select-2"
                    classNamePrefix="select"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>

            {/*  Media press */}
            <div className=" w-full bg-white flex flex-col gap-1  items-start pt-2  rounded-lg mt-4 pb-4 pl-4  ">
              <div className="text-neutral-900 text-2xl font-semibold leading-4 mt-6 ">
                Media/Press
              </div>
              {/*  title */}
              <div className="w-[746px]  items-end mt-3">
                <div className="text-neutral-900 text-sm font-bold leading-4 mt-6 ">
                  Navigation Locations
                </div>
                <div className="flex flex-col">
                  <Select
                    options={options}
                    isMulti
                    closeMenuOnSelect={false}
                    value={navigationState.mediaPressLocationIds}
                    onChange={(selected) =>
                      handleChange(selected, "mediaPressLocationIds")
                    }
                    placeholder="Select Navigations"
                    className="basic-multi-select multiple-select-2"
                    classNamePrefix="select"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>

            {/*  Investors */}
            <div className=" w-full bg-white flex flex-col gap-1  items-start pt-2  rounded-lg mt-4 pb-4 pl-4  ">
              <div className="text-neutral-900 text-2xl font-semibold leading-4 mt-6 ">
                Investors
              </div>
              {/*  title */}
              <div className="w-[746px]  items-end mt-3">
                <div className="text-neutral-900 text-sm font-bold leading-4 mt-6 ">
                  Navigation Locations
                </div>
                <div className="flex flex-col">
                  <Select
                    options={options}
                    isMulti
                    closeMenuOnSelect={false}
                    value={navigationState.investorsLocationIds}
                    onChange={(selected) => handleChange(selected, "investorsLocationIds")}
                    placeholder="Select Navigations"
                    className="basic-multi-select multiple-select-2"
                    classNamePrefix="select"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>

            {/*  News */}
            <div className=" w-full bg-white flex flex-col gap-1  items-start pt-2  rounded-lg mt-4 pb-4 pl-4  ">
              <div className="text-neutral-900 text-2xl font-semibold leading-4 mt-6 ">
                News
              </div>
              {/*  title */}
              <div className="w-[746px]  items-end mt-3">
                <div className="text-neutral-900 text-sm font-bold leading-4 mt-6 ">
                  Navigation Locations
                </div>
                <div className="flex flex-col">
                  <Select
                    options={options}
                    isMulti
                    closeMenuOnSelect={false}
                    value={navigationState.newsLocationIds}
                    onChange={(selected) => handleChange(selected, "newsLocationIds")}
                    placeholder="Select Navigations"
                    className="basic-multi-select multiple-select-2"
                    classNamePrefix="select"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>

            {/* footer */}
            {/* footer-button */}
            <div className=" bg-white flex flex-col gap-1 w-full  items-start   rounded-lg mt-4   ">
              <div className="flex flex-row py-3 justify-center items-center ">
                {/* <div className=" bg-white flex flex-col gap-1 w-full  items-start  rounded-lg   ">
                  <div
                    className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white text-black px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 cursor-pointer"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </div>
                </div> */}

                <div className=" bg-white flex flex-col gap-1 w-full  items-start rounded-lg   ">
                  <button
                    typr="button"
                    className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-black text-white px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 cursor-pointer"
                    onClick={handleNavigationUpdate}
                  >
                    {"Update"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NavigationManagement;
