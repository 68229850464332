import { produce } from "immer";
import { SAVE_AUTH } from "../../constants/constant";

const intisialState = {
  auth: {},
  email: {},
  rolePermissions: []
};

const authReducer = (state = intisialState, action) => {
  switch (action.type) {
    case "USER":
      return produce(state, (draft) => { });
    case SAVE_AUTH:
      return { ...state, auth: action.payload };
    default:
      return state;
  }
};

export default authReducer;

