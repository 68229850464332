import React from "react";
import logo from "../../assets/images/logo.png";
import profile from "../../assets/images/profile.png";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveAuth } from "../../redux/action/action";

const Navbar = ({ navbarContentShow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let pathfromUrl = pathname.split("/");

  return (
    <>
      <div className="bg-[#242424] text-[#FFFFFF] h-[36px] text-center flex items-end px-4 navbar-header navbar_height ">
        <img src={logo} alt="logo"
        //  className="h-[30.97px] w-[125px] mb-2"
        className="h-[25.97px] w-[115px] mb-2"
          />
        {!navbarContentShow && (
          <>
            <div 
            className="flex justify-between w-full pr-2 ml-[130px]"
            // className="flex justify-between w-full pr-2 ml-[50px]"
            >
              <div className="flex gap-[2px]">
                <NavLink
                  to="/dashboard/user-management"
                  // className={({ isActive, isPending }) =>
                  //   isPending ? "pending" : isActive ? "active text-xs" : "text-xs"
                  // }
                  className={`text-xs ${pathfromUrl.includes("dashboard") ? "active" : ""
                }  `}
                >
                  Dashboard
                </NavLink>

                {/*  Memberships & Pricing */}
                <NavLink
                  to="prices/membership/new-memberships-price"
                  // className={({ isActive, isPending }) =>
                  //   isPending ? "pending" : isActive ? "active text-xs" : "text-xs"
                  // }
                  className={`text-xs ${pathfromUrl.includes("prices") ? "active" : ""
                }  `}
                >
                 Products & Pricing
                </NavLink>

                {/*  */}

                {/*App Home Cards  */}
                <NavLink
                  to="/app-home-cards/surveys"
                  // className={({ isActive, isPending }) =>
                  //   isPending ? "pending" : isActive ? "active text-xs" : "text-xs"
                  // }
                  className={`text-xs ${pathfromUrl.includes("app-home-cards") ? "active" : ""
                }`}
                >
                  App Home Cards
                </NavLink>

                {/* Payment Gateway */}
                {/* <NavLink
                  to="/payments-gateway"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  Payment Gateway
                </NavLink> */}

                {/* Flight's Management */}
                <NavLink
                  to="/flights/pilot-profile"
                  className={`text-xs ${pathfromUrl.includes("flights") ? "active" : ""
                }`}
                >
                  Flights Mgmt
                </NavLink>

                {/* FBO's Management */}
                <NavLink
                  to="/fbo/fbo-rostering"
                  // className={({ isActive, isPending }) =>
                  //   isPending ? "pending" : isActive ? "active text-xs" : "text-xs"
                  // }
                  className={`text-xs ${pathfromUrl.includes("fbo") ? "active" : ""
                }`}
                >
                  FBO
                </NavLink>

                {/* Content management system */}
                <NavLink
                  to="/content/page-mgmt/home-page"
                  // className={({ isActive, isPending }) =>
                  //   isPending ? "pending" : isActive ? "active text-xs" : "text-xs"
                  // }
                  className={`text-xs ${pathfromUrl.includes("content") ? "active" : ""
                }`}
                >
                  CMS
                </NavLink>

                {/*  */}
                {/* <NavLink
                  to="/careers"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  Careers
                </NavLink>
                <NavLink
                  to="/news-blog"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  News/Blog
                </NavLink>
                <NavLink
                  to="/operations"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  Operations
                </NavLink>
                <NavLink
                  to="/console"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  Console
                </NavLink> */}
                {/*  */}
              </div>

              {/* adminmgmt */}

              <div className="flex items-center w-[150px] justify-around">
                <div className="flex gap-[25px]">
                  {/* <NavLink
                  to="/admin-mgmt"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  Admin Mgmt
                </NavLink> */}
                  <NavLink
                    to="/notifications"
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "active text-xs" : "text-xs"
                    }
                  >
                    <FontAwesomeIcon icon={faBell} />
                  </NavLink>
                  {/* <NavLink
                  to=""
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                > */}
                  <img src={profile} alt="admin" className="h-[25px]  pb-1" />
                  {/* </NavLink> */}
                </div>
                <div className="header-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle></Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          dispatch(saveAuth({}));
                          navigate("/");
                        }}
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Navbar;
