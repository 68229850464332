import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UserMembership from "./UserMembership";
import { Card, Typography } from "@material-tailwind/react";
import { ExistingMemberPriceChanges } from "./ExistingMemberPriceChanges";

import {
  getHistoryMembershipList,
  getUserMembershipList,
} from "../../../api/membership";

const AllMembership = () => {
  const [membership, setMembership] = useState([]);
  const [membershipHistory, setMembershipHistory] = useState([]);

  const getMembershipList = async () => {
    let payload = {
      skip: 1,
      limit: 10,
      search: "",
      role: "",
    };
    const response = await getUserMembershipList(payload);
    if (response?.status_code == 200) {
      setMembership(response?.data || []);
    }
  };

  const getMembershipHistoryList = async () => {
    let payload = {
      skip: 1,
      limit: 10,
      search: "",
    };
    const response = await getHistoryMembershipList(payload);
    if (response?.status_code == 200) {
      setMembershipHistory(response?.data || []);
    }
  };

  return (
    <>
      <Card className="h-[calc(100vh-250px)] !z-5 overflow-x-scroll hide_scroll rounded-none   w-[calc(100vw-200px)]">
        <div className="flex justify-start  py-4 px-2 bg-white ">
          <div className="membership-tabs ps-2">
            {/* <h1 className="common-heading mb-0 ps-1">User Membership and Membership History</h1> */}
            <Tabs
              defaultActiveKey="user"
              id="uncontrolled-tab-example"
              className="mb-3 px-9"
            >
              <Tab
                eventKey="user"
                title="Current"
                onClick={() => getMembershipList()}
              >
                <UserMembership
                  getMembershipList={getMembershipList}
                  membership={membership}
                />
              </Tab>
              <Tab
                eventKey="history"
                title="Historic"
                onClick={() => getMembershipHistoryList()}
              >
                <ExistingMemberPriceChanges
                  getMembershipHistoryList={getMembershipHistoryList}
                  membershipHistory={membershipHistory}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </Card>
    </>
  );
};

export default AllMembership;
