import axios from "axios";
import Qs from "qs";
import store from "../redux/store/store";
import { saveAuth, saveLoader } from "../redux/action/action";
// import { errorToast } from "../helper";
// import { saveAuth } from "../redux/actions/auth";

const pendingRequest = new Map();

var BASE_URL = "";
var setupAPI = function () {
  BASE_URL = process.env.REACT_APP_API_URL;
};

setupAPI();

function generateReqKey(config) {
  const { method, url, params, data } = config;
  return [method, url, Qs.stringify(params), Qs.stringify(data)].join("&");
}

function addPendingRequest(config) {
  const requestKey = generateReqKey(config);
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel);
      }
    });
}

function removePendingRequest(config) {
  const requestKey = generateReqKey(config);
  if (pendingRequest.has(requestKey)) {
    const cancelToken = pendingRequest.get(requestKey);
    cancelToken(requestKey);
    pendingRequest.delete(requestKey);
  }
}

const fetchClient = () => {
  const defaultOptions = {
    baseURL: BASE_URL,
    timeout: 20000,
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  instance.interceptors.response.use(
    function (response) {
      if (
        response?.data?.status_code === 401 ||
        response?.data?.status_code === 501
      ) {
        // store.dispatch(saveAuth({}))
        console.log("Logged out");
        store.dispatch(saveAuth({}))
        window.location.href = process.env.NODE_ENV==="development" ? 'http://localhost:3000/#/' : process.env.REACT_APP_PUBLIC_URL;
      }
      store.dispatch(saveLoader(false));
      return response.data;
    },
    function (error) {
      if (error?.code === "ERR_CANCELED") {
        console.log("something went Wrong Please Try Again");
      }
      if (
        error?.response?.data?.status_code === 401 ||
        error?.response?.data?.status_code === 501
      ) {
        // store.dispatch(saveAuth({}))
        console.log("Logged out");
        store.dispatch(saveAuth({}))
        window.location.href = process.env.NODE_ENV==="development" ? 'http://localhost:3000/#/' : process.env.REACT_APP_PUBLIC_URL;
      }
      let data = error?.response?.data;
      store.dispatch(saveLoader(false));
      return data;
      // return Promise.reject(error?.response?.data ||  error.messsage);
    }
  );

  // Set the AUTH token for any request
  instance.interceptors.request.use(
    function (config) {
      const storeData = store.getState();
      const staticToken =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTRiMzU5Y2Q4YTEzMTc1ZjhmMzQ5Y2UiLCJpYXQiOjE3MDI5ODMzMjd9.k-9jaqlDQuM4cMw46B5pfeUBHO5E5kaSBhtIDSk0Ips" ||
        "";
      // // if (!!token && token !== "null") {
      //   config.headers.Authorization = token ? `Bearer ${token}` : '';
      //   // config.headers.Authorization = token ? `Bearer ${token}` : '';
      // // }
      const token = storeData?.auth?.auth?.token || "";
      // if (!!token && token !== "null") {
      config.headers.Authorization = token ? `Bearer ${token}` : staticToken;
      // }
      store.dispatch(saveLoader(true));
      config.timeout = 30000;

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default fetchClient();
