import React, { useEffect, useState } from "react";
import { Card, Tooltip, Typography } from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";
import plussvg from "../../../assets/images/icon.svg";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const TABLE_HEAD = [
  "User Name",
  " Membership Price",
  "Updated Membership Price",
  "Effective Date",
  "Action",
];

const TABLE_ROWS = [
  {
    name: "John Michael",
    job: "Manager",
    date: "23/04/18",
  },
];

export function ExistingMemberPriceChanges({
  getMembershipHistoryList,
  membershipHistory,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    getMembershipHistoryList();
  }, []);

  return (
    <>
      {/* <div className="flex justify-end  py-4 px-2 bg-white ">
        <Button
          variant="gradient "
          size="lg"
          type="submit"
          className="!capitalize"
        >
          <div
            className="flex items-center space-x-2 justify-center "
            onClick={() =>
              navigate("/prices/membership/existing-member-price-changes/add")
            }
          >
            <img src={plussvg} alt="" />
            Price Change for Existing Members
          </div>
        </Button>
      </div> */}
      <Card className="h-[calc(100vh-120px)] overflow-x-scroll hide_scroll rounded-none  rounded-b-2xl w-[calc(100vw-300px)]">
        <table className="w-full min-w-max table-auto text-left  ml-4">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className=" leading-none font-semibold"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {membershipHistory.map((item, index) => {
              const isLast = index === membershipHistory.length - 1;
              const classes = isLast
                ? "py-2 px-3"
                : "py-2 px-3 border-b    border-blue-gray-50";

              return (
                <tr key={index}>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item?.user_name || "N/A"}
                    </Typography>
                  </td>

                  <td className={classes}>
                    <Typography
                      as="a"
                      href="#"
                      variant="small"
                      color="blue-gray"
                      className="font-medium"
                    >
                      {item?.price || "N/A"}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item?.changed_price || "N/A"}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {moment(item?.change_date).format("YYYY-DD-MM") || "N/A"}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-row gap-2">
                      <div className="action-icon">
                        <Tooltip content={"View"}>
                          <FontAwesomeIcon
                            onClick={() => {
                              navigate(
                                `/prices/membership/existing-member-price-changes/membership-history/${item?._id}`
                              );
                            }}
                            className="edit-btn"
                            icon={faEye}
                          />
                        </Tooltip>
                      </div>
                      {/* <div className="action-icon">
                        <Tooltip content={"Edit"}>
                          <FontAwesomeIcon
                            onClick={() => {
                              navigate();
                              // `/content/page-mgmt/legal/edit-legal/${_id}`
                            }}
                            className="edit-btn"
                            icon={faPen}
                          />
                        </Tooltip>
                      </div>
                      <div className="action-icon">
                        <Tooltip content={"Delete"}>
                          <FontAwesomeIcon
                            onClick={() => {
                              // handleLegalDelete(_id);
                            }}
                            className="edit-btn"
                            icon={faTrash}
                          />
                        </Tooltip>
                      </div> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </>
  );
}
