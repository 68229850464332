import ContainerHeading from "../../../components/common/container-heading/ContainerHeading";
import Toggle from "react-toggle";
import moment from "moment";
import plussvg from "../../../assets/images/icon.svg";
import { faEye, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Tooltip, Typography } from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { activeDiscountApi, deleteDiscount, getDiscountList } from "../../../api/membership";
import { errorToast, successToast } from "../../../utils/toast";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

const TABLE_HEAD = [
  "Discount Alias",
  "Membership Name",
  "Standard Price",
  "Beginning Discount Price",
  "Ending Discount Price",
  "Seats",
  "Start Date",
  "End Date",
  "Action",
];

const TABLE_ROWS = [
  {
    name: "John Michael",
    job: "Manager",
    date: "23/04/18",
    status: "In Progress",
  },
  {
    name: "Alexa Liras",
    job: "Developer",
    date: "23/04/18",
    status: "Draft",
  },
  {
    name: "John Michael",
    job: "Manager",
    date: "23/04/18",
    status: "Scheduled",
  },
];

export function Discounts() {
  const navigate = useNavigate("");
  const [count, setCount] = useState("");
  const [discountList, setDiscountList] = useState([]);
  const [activeTab, setActiveTab] = useState("Current");

  useEffect(() => {
    getDiscounts();
  }, []);

  const getDiscounts = async () => {
    let payload = {
      skip: 1,
      limit: 10,
      search: "",
    };
    const response = await getDiscountList(payload);
    if (response?.status_code == 200) {
      setDiscountList(response?.data || []);
      setCount(parseInt(response?.totalItems / payload.limit) || 0);
    }
  };

  const handleAdd = () => {
    navigate("/prices/membership/discounts/add-discount");
  };

  const onActive = async (id, status) => {
    let payload = {
      id,
      status: status === "active" ? "inactive" : "active",
    };
    const response = await activeDiscountApi(payload);
    if (response?.status_code === 200) {
      successToast(response.message || "Success");
      getDiscounts();
    } else {
      errorToast(response?.message || "Error");
    }
  };

  const data = [
    {
      label: "Current",
      value: "Current",
      tableData : discountList
     
    },
    {
      label: "Historic",
      value: "Historic",
      tableData: discountList
      
    },
  ];

  const handleDelete = async(item) => {
    const response = await deleteDiscount({id: item?._id});
    if(response?.status_code===200){
      successToast(response?.message)
      getDiscounts();
    }else{
      errorToast(response?.message);
    }
  }

  return (
    <>
      <ContainerHeading
        title={"Discount listing"}
        button="Add Discount"
        redirect={"/prices/membership/discounts/add-discount"}
      />

      <div className="bg-white w-[calc(100vw-200px)]"> 
      <Tabs value={activeTab}>
      <TabsHeader 
      // className="w-[500px]"
      className="rounded-none border-b border-blue-gray-50 bg-transparent p-0 w-2/5"
        indicatorProps={{
          className:
            "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
        }}
      >
        {data.map(({ label, value }) => (
          <Tab key={value} value={value}
          onClick={() => setActiveTab(value)}
          className={activeTab === value ? "text-gray-900  text-sm" : "text-sm"}

          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, tableData }) => (
          <TabPanel key={value} value={value} className="mt-2 p-0">
         
      <Card className="h-[calc(100vh-120px)] overflow-x-scroll hide_scroll rounded-none  rounded-b-2xl ">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className=" leading-none font-semibold"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {discountList.map((item, index) => {
              const isLast = index === discountList.length - 1;
              const classes = isLast
                ? "py-2 px-3"
                : "py-2 px-3 border-b    border-blue-gray-50 hover:text-[#000]";

              return (
                <tr key={index}>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item?.discount_alias_name || "N/A"}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item?.membershipName || "N/A"}
                    </Typography>
                  </td>
                  {/* <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {job}
                    </Typography>
                  </td> */}
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      ${item?.standardPrice}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      // as="a"
                      // href="#"
                      variant="small"
                      color="blue-gray"
                      className="font-medium"
                    >
                      ${item?.beginingDiscountPrice}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      ${item?.endingDiscountPrice}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item?.indefinite_seats === true
                        ? "Indefinite Seats"
                        : item?.total_seats}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      as="a"
                      href="#"
                      variant="small"
                      color="blue-gray"
                      className="font-medium"
                    >
                      {moment(item?.start_date).format("YYYY-MM-DD")}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item?.indefinite_end_date === true
                        ? "No End Date"
                        : moment(item?.end_date).format("YYYY-MM-DD")}
                    </Typography>
                  </td>

                  <td className={classes}>
                    <div class="action-toggle flex flex-row gap-3">
                      <Toggle
                        checked={item?.status === "active" ? "active" : ""}
                        icons={false}
                        className={
                          item?.status === "inactive" ? "isDisable" : "active"
                        }
                        onChange={() => onActive(item?._id, item?.status)}
                      />
                      <Tooltip content={"View"}>
                        <FontAwesomeIcon
                          onClick={() => {
                            navigate(
                              `/prices/membership/discounts/view-discount/${item?._id}`
                            );
                          }}
                          className="edit-btn"
                          icon={faEye}
                        />
                      </Tooltip>
                      <Tooltip content={"Edit"}>
                        <FontAwesomeIcon
                          disabled={item?.status === "inactive"}
                          onClick={() => {
                            if (item?.status !== "inactive") {
                              navigate(
                                `/prices/membership/discounts/edit-discount/${item?._id}`
                              );
                            } else {
                              errorToast("Please active this subadmin first");
                            }
                          }}
                          className="edit-btn mx-2"
                          icon={faPen}
                        />
                      </Tooltip>
                      <Tooltip content={"Edit"}>
                        <FontAwesomeIcon
                          disabled={item?.status === "inactive"}
                          onClick={() => {
                            handleDelete(item)
                          }}
                          className="edit-btn mx-2"
                          icon={faTrash}
                        />
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
      </div>



    </>
  );
}
