import { Button, Input, ButtonBase } from "@mui/material"
import { useState, Fragment } from "react"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function ContactUsCategories() {
  const [categories, setCategories] = useState([
    { label: "Members", email: "member@blackjet.au" },
    { label: "General", email: "hello@help.blackjet.au" },
    { label: "Investors", email: "investors@help.blackjet.au" },
    { label: "Media/Press", email: "media@help.blackjet.au" },
    { label: "Partnerships", email: "partnerships@help.blackjet.au" },
    { label: "Sales", email: "sales@help.blackjet.au" },
    { label: "Something Else", email: "hello@help.blackjet.au" },
    { label: "Support", email: "support@help.blackjet.au" },
  ])

  return (
    <div className="contact-us-categories-wrapper">
      <div className="contact-us-categories-container">
        <span className="label">Contact Us Categories</span>
        <div className="categories-wrapper">
          {categories?.map(({ label, email }, index) => {
            return (
              <div key={index} className="category-container">
                <div className="left-conatiner">
                  <span className={`label ${index === 0 ? 'muted' : ''}`}>{index === 0 ? "Full member contact us detail" : "Categories"}</span>
                  <Input placeholder="category" onChange={({ target: { value } }) => {
                    const filteredArray = categories;
                    filteredArray.splice(index, 1, { label: value, email });
                    setCategories([...filteredArray])
                  }} value={label} disabled={index === 0} />
                </div>
                <div className="right-conatiner">
                  <span className={`email ${index === 0 ? 'muted' : ''}`}>Interacting email</span>
                  <Input placeholder="email" onChange={({ target: { value } }) => {
                    const filteredArray = categories;
                    filteredArray.splice(index, 1, { label, email: value });
                    setCategories([...filteredArray])
                  }} value={email} disabled={index === 0} type="email" />
                </div>
                <div className="category-action-container">
                  <ButtonBase>
                    {index === 0 ? <Fragment /> : <DeleteForeverIcon onClick={() => {
                      const filteredArray = categories;
                      filteredArray.splice(index, 1);
                      setCategories([...filteredArray])
                    }} />}
                  </ButtonBase>
                </div>
              </div>
            )
          })}
          <ButtonBase className="add-icon" onClick={() => setCategories((prevState) => [...prevState, { label: "", email: "" }])}>
            <img src="./asset/addCircle.svg" />
            <span>Category</span>
          </ButtonBase>
        </div >
      </div>
      <div className="action-container">
        <Button variant="outlined" className="secondary">Cancel</Button>
        <Button variant="contained" className="primary">Apply</Button>
      </div>
    </div>
  )
}