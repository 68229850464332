import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const MembershipPaymentGateway = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = location.pathname;

  const handleChange = (event, newValue) => {
    navigate(newValue);
  };
  return (
    <div className='common-bg'>
      <Box sx={{ width: '100%' }}>
        <Tabs value={activeTab} onChange={handleChange} aria-label='wrapped label tabs example'>
          <Tab value='/prices/membership/payments-gateway' label='Payment Gateways' />
          <Tab
            value='/prices/membership/payments-gateway/blacklisted-payments'
            label='Blacklisted Payments'
            disabled={true}
          />
        </Tabs>
      </Box>
      <Outlet />
    </div>
  );
};

export default MembershipPaymentGateway;
