import React from 'react';
import BlackListedPayment from './blackListedPayment';

const BlacklistedPayments = () => {
  return (
    <div className=''>
      <BlackListedPayment />
    </div>
  );
};

export default BlacklistedPayments;
