import React from "react";
import SelectWithChips from "../../../dashboard/user-mgmt/SelectWithChips";
import Footer from "../../../../components/common/footer/Footer";
import ContainerHeading from "../../../../components/common/container-heading/ContainerHeading";
import Breadcrumb from "../../../../components/console/customer-services/customer-mngmnt/Breadcrumb";

const Legal = () => {
  return (
    <>
        <div>
          <div className="flex flex-col justify-center items-center px-4 w-[calc(100vw-264px)] mt-5 ">
            <Breadcrumb parent={"Page management"} child={"Legal"} />
          </div>

          <div className="flex flex-col justify-center items-center px-4 w-[calc(100vw-264px)] mt-5 ">
            <div className="bg-white flex flex-col  w-full  items-start pt-2 px-4 rounded-lg hide_scroll max-h-[calc(100vh-100px)] overflow-y-auto ">
              <ContainerHeading
                title={"Legal"}
                chipName={"Published"}
                bgColor="#B9F6CA"
              />

              {/* dropdown chips */}

              <div className="w-2/4 my-4">
                <h2 className="text-[#141414] text-[14px] font-bold">
                  Navigation Locations
                </h2>

                <SelectWithChips />
              </div>
            </div>
            {/* footer */}
            <Footer
              bg={"black"}
              button1={"Cancel"}
              button2={"Preview"}
              button3={"Save draft"}
              button4={"Publish to staging"}
            />
            {/*  */}
          </div>
        </div>
    </>
  );
};

export default Legal;
