import React from "react";
import { Switch } from "@headlessui/react";
import { successToast } from "./../../utils/toast";

const CustomSwitch = ({ value, setValue, toast = false }) => {
  const handleSetValue = () => {
    setValue(!value);
    if (toast) {
      successToast(toast);
    }
  };
  return (
    <div>
      <div className="py-2">
        <Switch
          checked={value}
          onChange={handleSetValue}
          className={`${value ? "bg-black" : "bg-[#EAEAEA]"}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-[] transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${value ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
    </div>
  );
};

export default CustomSwitch;
