import React, { useEffect, useState } from "react";
import { Card, Tooltip, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpenReader, faEye } from "@fortawesome/free-solid-svg-icons";
import { getDateFormat } from "../../helper";
import CustomPagination from "../../components/common/CustomPagination";
import PopUpLayout from "../dashboard/user-mgmt/PopUpLayout";
import ContainerHeading from "../../components/common/container-heading/ContainerHeading";
import {
  getEnquiry,
  getSingleEnquiry,
  updateReadStatus,
} from "../../api/notifications";
import NoDataFound from "../../components/common/NoDataFound";
import { errorToast, successToast } from "../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { saveLoader } from "../../redux/action/action";

// table heading
const TABLE_HEAD = [
  "Name",
  "Subject",
  "Email",
  "Enquiry",
  "Phone",
  "Type",
  "Status",
  "Created At",
  "Action ",
];

// select type options
const options = [
  { name: "FAQ", value: "faq" },
  { name: "Media Press", value: "media_press" },
  { name: "Investors", value: "investors" },
  { name: "contact us", value: "contact_us" },
];

const NotificationListing = () => {
  // navigation
  const navigate = useNavigate();

  // values from redux
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.loader);

  // local state
  const [notificationsList, setNotificationsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [uniqueNotificationDetails, setUniqueNotificationDetails] = useState();
  const [type, setType] = useState("");

  useEffect(() => {
    getNotificationsList();
  }, [type, skip, search]);

  // function for notification list
  const getNotificationsList = async () => {
    dispatch(saveLoader(true));
    let payload = {
      type: type,
      skip: skip,
      limit: limit,
      search: search,
    };
    let response = await getEnquiry(payload);
    dispatch(saveLoader(false));
    setNotificationsList(response?.data);
    let count = Math.ceil(response?.totalItems / limit);
    setPageCount(count);
  };

  // details of single FAQ
  const notificationDetail = async (id) => {
    let payload = { id };
    let response = await getSingleEnquiry(payload);
    let objData = Object.keys(response?.data).map((key) => {
      return { [key]: response?.data[key] };
    });

    setUniqueNotificationDetails(response?.data);
    setOpen(true);
  };

  // read status  on each notification id
  const handleNotificationRead = async (id, isRead) => {
    let payload = {
      id,
      isRead: isRead === "true" ? "false" : "true",
    };
    const response = await updateReadStatus(payload);
    if (response?.status_code === 200) {
      successToast(response?.message);
      getNotificationsList();
    } else if (response?.status_code === 400) {
      errorToast(response?.message);
    }
  };

  // console
  // console.log(FAQList, "faqlist");
  // console.log(type, "type");
  // console.log(uniqueNotificationDetails, "ndetails");
  return (
    <>
      <>
          <div className="flex flex-col justify-center items-center common-bg ">
            <ContainerHeading
              title={"Notifications"}
              dropdownSelect={true}
              search={true}
              searchValue={search}
              setSearchValue={setSearch}
              options={options}
              setSelected={setType}
            />

            <div className=" bg-white w-full flex flex-col gap-1  items-start pt-2  rounded-t-lg mt-2  ">
              {/* lisitng table */}
              {notificationsList?.length ? (
                <>
                  <div
                    className=" !z-5 
              overflow-x-scroll hide_scroll rounded-none  w-full
               
               "
                  >
                    {/*   */}
                    <table className="w-full min-w-max  table-auto text-left">
                      <thead>
                        <tr>
                          {TABLE_HEAD.map((head) => (
                            <th
                              key={head}
                              className="border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]"
                            >
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-semibold leading-none "
                              >
                                {head}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {notificationsList?.map((notification, index) => {
                          const isLast = index === notificationsList.length - 1;
                          const classes = isLast
                            ? "py-2  px-3 max-w-[300px]"
                            : "py-2 px-3 max-w-[300px] border-b    border-blue-gray-50";

                          return (
                            <tr
                              key={notification?._id}
                              className={
                                notification?.isRead === "false"
                                  ? "bg-[#F0F0F2]"
                                  : ""
                              }
                            >
                              {/* <td className={classes + " cursor-pointer"}>
                                      <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal overflow-hidden whitespace-nowrap text-ellipsis"
                                      >
                                        {notification?._id}
                                      </Typography>
                                    </td> */}
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {`${notification?.firstName} ${notification?.lastName}`}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal overflow-hidden whitespace-nowrap text-ellipsis"
                                >
                                  {notification?.subject}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {notification?.email}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal overflow-hidden whitespace-nowrap text-ellipsis"
                                >
                                  {notification?.enQuiry.replace(
                                    /<[^>]+>/g,
                                    ""
                                  )}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {notification?.phone}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {notification?.type === "faq"
                                    ? "FAQ"
                                    : notification?.type === "media_press"
                                    ? "Media Press"
                                    : "Contact Us"}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className={`font-normal ${
                                    notification?.status === "Scheduled"
                                      ? "bg-[#90CAF9]"
                                      : notification?.status === "Published"
                                      ? "bg-[#B9F6CA]"
                                      : notification?.status === "Draft"
                                      ? "bg-[#FFF8E1]"
                                      : notification?.status === "active"
                                      ? "bg-[#B9F6CA]"
                                      : "bg-[#EAEAEA]"
                                  }  px-3 py-1 rounded-[100px] text-center`}
                                >
                                  {notification?.status}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {getDateFormat(notification?.created_at)}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <div className="flex flex-row gap-2">
                                  <div className="action-icon">
                                    <Tooltip content={"View"}>
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          notificationDetail(notification?._id);
                                        }}
                                        className="edit-btn"
                                        icon={faEye}
                                      />
                                    </Tooltip>
                                  </div>
                                  <div className="action-icon">
                                    <Tooltip
                                      content={
                                        notification?.isRead === "true"
                                          ? "Mark as Unread"
                                          : "Mark as Read"
                                      }
                                    >
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          handleNotificationRead(
                                            notification?._id,
                                            notification?.isRead
                                          );
                                        }}
                                        className="edit-btn"
                                        icon={faBookOpenReader}
                                      />
                                    </Tooltip>
                                  </div>
                                </div>
                              </td>

                              {/* <td className={classes}>
                              <div className="justify-center items-center border border-[color:var(--Grey-800,#141414)] bg-white flex flex-col w-[30px] h-[30px] rounded-lg border-solid">
                                <img
                                  loading="lazy"
                                  src={action_icon}
                                  className="aspect-square object-contain object-center w-full overflow-hidden"
                                  alt="action"
                                />
                              </div>
                            </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                !loader && <NoDataFound />
              )}
            </div>

            {/*  */}
          </div>
        {/* pagination */}

        <div className="bg-white flex flex-col gap-1 w-full  items-end pt-2 px-2   rounded-b-lg   ">
          {pageCount > 1 && (
            <Card className="py-2 rounded-none   ">
              <span className="bg-[#E6E6E6] py-3  px-2 w-auto items-center ml-3  rounded-xl ">
                <CustomPagination
                  page={skip}
                  setPage={setSkip}
                  count={pageCount}
                />
              </span>
            </Card>
          )}
        </div>
      </>
      {open && (
        <PopUpLayout setShowModal={setOpen}>
          <>
            <div className="bg-white w-[700px] p-5 h-[calc(100vh-0px)] overflow-y-auto hide_scroll rounded-xl ">
              <div className="flex justify-between ">
                <h1 className="taxt-[#303637] text-[20px] font-semibold ">
                  View Notification
                </h1>
                <svg
                  className="cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              {/* heading */}

              {/*  */}
              {/* <div className="flex flex-row gap-4">
                    <div className="text-neutral-900 text-base font-bold leading-4 mt-6 ">
                      Title:
                    </div>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 ">
                      {uniqueNotificationDetails?.title}
                    </div>
                  </div> */}
              {/* body */}

              {/*  */}
              <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                {/* id */}
                <>
                  <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                    <div>Id</div>
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="text-balance">
                      {uniqueNotificationDetails?._id}
                    </p>
                  </div>
                </>
                {/* name */}
                <>
                  <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                    <div>Name</div>
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="text-balance">
                      {`${uniqueNotificationDetails?.firstName} ${uniqueNotificationDetails?.lastName}`}
                    </p>
                  </div>
                </>
                {/* Email */}
                <>
                  <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                    <div>Email</div>
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="text-balance">
                      {uniqueNotificationDetails?.email}
                    </p>
                  </div>
                </>
                {/* Enquiry */}
                <>
                  <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                    <div>Enquiry</div>
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="text-balance">
                      {uniqueNotificationDetails?.enQuiry}
                    </p>
                  </div>
                </>
                {/* Phone */}
                <>
                  <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                    <div>Phone</div>
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="text-balance">
                      {uniqueNotificationDetails?.phone}
                    </p>
                  </div>
                </>
                {/* Status */}
                <>
                  <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                    <div>Status</div>
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="text-balance">
                      {uniqueNotificationDetails?.status}
                    </p>
                  </div>
                </>
                {/* Subject */}
                <>
                  <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                    <div>Subject</div>
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="text-balance">
                      {uniqueNotificationDetails?.subject}
                    </p>
                  </div>
                </>
                {/* Type */}
                <>
                  <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                    <div>Type</div>
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="text-balance">
                      {uniqueNotificationDetails?.type}
                    </p>
                  </div>
                </>
              </div>

              {/*  */}
            </div>
          </>
        </PopUpLayout>
      )}
    </>
  );
};

export default NotificationListing;
