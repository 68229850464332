import React from "react";

const NewTest = () => {
  return (
    <>
      <div className="bg-[#ffffff] flex flex-row justify-center w-full">
        <div className="bg-[#ffffff] w-[1440px] h-[2196px] relative">
          <div className="absolute w-[1440px] h-[280px] top-[80px] left-0 bg-[#f49d2b0a]">
            <div className="absolute top-[87px] left-[82px] [font-family:'Montserrat-SemiBold',Helvetica] font-semibold text-purplenormal text-[28px] tracking-[0] leading-[40px] whitespace-nowrap">
              New Business Registration
            </div>
            <p className="absolute w-[972px] top-[139px] left-[82px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#555555] text-[16px] tracking-[0] leading-[25.6px]">
              Lorem ipsum dolor sit amet consectetur. Facilisi tincidunt ut
              tincidunt fringilla accumsan posuere volutpat ultrices. Nisl proin
              vehicula fusce lectus adipiscing. Ut integer et at gravida.
            </p>
          </div>
          <div className="absolute w-[1290px] h-[492px] top-[1704px] left-[82px]">
            <div className="relative w-[1276px] h-[492px]">
              <p className="absolute w-[304px] top-[112px] left-0 [font-family:'Montserrat-Medium',Helvetica] font-medium text-[#333333] text-[14px] tracking-[0] leading-[22.4px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt
              </p>
              <div className="absolute top-[218px] left-0 [font-family:'Montserrat-Medium',Helvetica] font-medium text-[#333333] text-[14px] tracking-[0] leading-[22.4px] whitespace-nowrap">
                Follow us
              </div>
              <div className="absolute w-[1276px] h-[492px] top-0 left-0 border-t-[1.5px] [border-top-style:solid] border-[#0000001f]" />
              <p className="absolute top-[56px] left-0 [font-family:'Montserrat-Bold',Helvetica] font-bold text-transparent text-[32px] tracking-[0] leading-[40px] whitespace-nowrap">
                <span className="text-[#f49d2b]">TAX</span>
                <span className="text-[#4b369d]">Jugnoo</span>
              </p>
              <div className="absolute w-[160px] h-[32px] top-[248px] left-0">
                <div className="absolute w-[32px] h-[32px] top-0 left-0 rounded-[16px] border border-solid border-purplenormal">
                  <img
                    className="absolute w-[9px] h-[16px] top-[7px] left-[10px]"
                    alt="Image traced"
                    src="image-7-traced.svg"
                  />
                </div>
                <div className="absolute w-[32px] h-[32px] top-0 left-[128px] rounded-[16px] border border-solid border-purplenormal">
                  <img
                    className="w-[20px] top-[7px] left-[5px] absolute h-[16px]"
                    alt="Image traced"
                    src="image.svg"
                  />
                </div>
                <div className="absolute w-[32px] h-[32px] top-0 left-[64px] rounded-[16px] border border-solid border-purplenormal">
                  <img
                    className="w-[16px] top-[6px] left-[7px] absolute h-[16px]"
                    alt="Image traced"
                    src="image-7-traced-2.svg"
                  />
                </div>
              </div>
              <p className="absolute top-[112px] left-[540px] [font-family:'Montserrat-Medium',Helvetica] font-medium text-[#333333] text-[14px] tracking-[0] leading-[36.4px]">
                GST
                <br />
                Income Tax Guide
                <br />
                F&amp;O Trading
                <br />
                Terms &amp; Conditions
                <br />
                Privacy Policy
                <br />
                FAQs
                <br />
                Calculators
                <br />
                Refund Policy
                <br />
                ITR Filing
              </p>
              <p className="absolute top-[112px] left-[972px] [font-family:'Montserrat-Medium',Helvetica] font-medium text-[#333333] text-[14px] tracking-[0] leading-[36.4px]">
                Mobile: +91 9876XXXXXX
                <br />
                Email: loremipsum@gmail.com
                <br />
                Team
                <br />
                News
                <br />
                Blogs
                <br />
                Tax Refund Status
              </p>
              <div className="absolute top-[88px] left-[972px] [font-family:'Montserrat-Bold',Helvetica] font-bold text-purplenormal text-[16px] tracking-[0] leading-[20px] whitespace-nowrap">
                Contact Us
              </div>
              <div className="absolute top-[88px] left-[540px] [font-family:'Montserrat-Bold',Helvetica] font-bold text-purplenormal text-[16px] tracking-[0] leading-[20px] whitespace-nowrap">
                Services
              </div>
              <div className="absolute w-[316px] h-[16px] top-[320px] left-0">
                <img
                  className="absolute w-[12px] h-[12px] top-[2px] left-[65px]"
                  alt="Copyright circle"
                  src="copyright-circle-outlined.svg"
                />
                <div className="absolute top-0 left-0 [font-family:'Montserrat-Medium',Helvetica] font-medium text-[#333333] text-[12px] tracking-[0] leading-[15.6px] whitespace-nowrap">
                  Copyright
                </div>
                <div className="absolute top-0 left-[81px] [font-family:'Montserrat-Medium',Helvetica] font-medium text-[#333333] text-[12px] tracking-[0] leading-[15.6px] whitespace-nowrap">
                  2023&nbsp;&nbsp;TAXJugnoo. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-[423px] left-[82px] [font-family:'Montserrat-SemiBold',Helvetica] font-semibold text-black text-[20px] tracking-[0] leading-[32px] whitespace-nowrap">
            Popular Plans:
          </div>
          <div className="absolute w-[1276px] h-[352px] top-[488px] left-[82px] bg-white rounded-[8px] border border-solid border-divider">
            <div className="absolute top-[22px] left-[23px] [font-family:'Montserrat-Medium',Helvetica] font-medium text-global-colorstext-colorblack text-[16px] tracking-[0.48px] leading-[24px]">
              Trademark Registration
              <br />
              (Individual)
            </div>
            <div className="absolute w-[150px] h-[39px] top-[80px] left-[23px]">
              <div className="absolute top-0 left-0 [font-family:'Montserrat-Medium',Helvetica] font-medium text-purplenormal text-[32px] text-center tracking-[0] leading-[normal]">
                ₹749
              </div>
              <div className="absolute w-[62px] h-[29px] top-[4px] left-[90px]">
                <div className="relative w-[58px] h-[29px]">
                  <div className="absolute top-0 left-0 [font-family:'Inter-Regular',Helvetica] font-normal text-[#319f43] text-[12px] tracking-[0] leading-[14.4px] whitespace-nowrap">
                    50% Off
                  </div>
                  <div className="absolute top-[12px] left-0 [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#33333366] text-[14px] tracking-[0] leading-[16.8px] line-through whitespace-nowrap">
                    ₹ 1499 /-
                  </div>
                </div>
              </div>
            </div>
            <img
              className="absolute w-[1228px] h-px top-[134px] left-[23px] object-cover"
              alt="Vector"
              src="vector-100.svg"
            />
            <div className="inline-flex flex-col items-start gap-[16px] absolute top-[151px] left-[23px]">
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path.svg"
                  />
                </div>
                <p className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Filing of One Trademark application
                </p>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-2.svg"
                  />
                </div>
                <div className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Advice on Class Selection
                </div>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-3.svg"
                  />
                </div>
                <div className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Drafting of documents
                </div>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-4.svg"
                  />
                </div>
                <p className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Expert Support on phone/ Video Call
                </p>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-5.svg"
                  />
                </div>
                <p className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Including Govt. Fee of Rs.4500/-
                </p>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#ebf1ff] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-6.svg"
                  />
                </div>
                <div className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-dark-gray text-[14px] tracking-[0] leading-[normal]">
                  Dedicated onboarding specialist
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[240px] items-center justify-end gap-[10px] absolute top-[74px] left-[1011px]">
              <div className="flex items-center justify-center gap-[10px] px-[25px] py-[15px] relative self-stretch w-full flex-[0_0_auto] bg-white rounded-[3px]">
                <div className="relative w-fit mt-[-2.00px] [font-family:'Montserrat-SemiBold',Helvetica] font-semibold text-yellownormal text-[12px] text-center tracking-[0.36px] leading-[normal]">
                  KNOW MORE
                </div>
              </div>
            </div>
            {/* <Button className="!absolute !left-[1011px] !w-[240px] !top-[23px]" property1="variant-2" /> */}
          </div>
          <div className="absolute w-[1276px] h-[360px] top-[880px] left-[82px] bg-white rounded-[8px] border border-solid border-divider">
            <div className="absolute w-[150px] h-[40px] top-[23px] left-[1103px]">
              <div className="[font-family:'Montserrat-Medium',Helvetica] font-medium text-purplenormal text-[32px] text-center leading-[40px] absolute top-0 left-0 tracking-[0] whitespace-nowrap">
                ₹749
              </div>
              <div className="absolute w-[62px] h-[29px] top-[4px] left-[90px]">
                <div className="relative w-[58px] h-[29px]">
                  <div className="absolute top-0 left-0 [font-family:'Inter-Regular',Helvetica] font-normal text-[#319f43] text-[12px] tracking-[0] leading-[14.4px] whitespace-nowrap">
                    50% Off
                  </div>
                  <div className="absolute top-[12px] left-0 [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#33333366] text-[14px] tracking-[0] leading-[16.8px] line-through whitespace-nowrap">
                    ₹ 1499 /-
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-flex flex-col items-start gap-[16px] absolute top-[159px] left-[23px]">
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-7.svg"
                  />
                </div>
                <p className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Filing of One Trademark application
                </p>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-8.svg"
                  />
                </div>
                <div className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Advice on Class Selection
                </div>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-9.svg"
                  />
                </div>
                <div className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Drafting of documents
                </div>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-10.svg"
                  />
                </div>
                <p className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Expert Support on phone/ Video Call
                </p>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-11.svg"
                  />
                </div>
                <p className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Including Govt. Fee of Rs.4500/-
                </p>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#ebf1ff] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-12.svg"
                  />
                </div>
                <div className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-dark-gray text-[14px] tracking-[0] leading-[normal]">
                  Dedicated onboarding specialist
                </div>
              </div>
            </div>
            {/* <Button
            className="!px-[25px] !py-[16px] !left-[1011px] !absolute !w-[240px] !top-[75px]"
            divClassName="!whitespace-nowrap !leading-[16px]"
            property1="variant-2"
          /> */}
            <div className="inline-flex flex-col items-start gap-[10px] absolute top-[23px] left-[23px]">
              <div className="relative w-fit mt-[-1.00px] [font-family:'Montserrat-Medium',Helvetica] font-medium text-global-colorstext-colorblack text-[16px] tracking-[0.48px] leading-[24px] whitespace-nowrap">
                Proprietorship
              </div>
              <div className="relative w-[165px] h-[16px] mr-[-2.00px]">
                <div className="absolute w-[82px] h-[16px] top-0 left-0">
                  <div className="[font-family:'Montserrat-Bold',Helvetica] font-bold text-[#000000d9] text-[12px] leading-[16px] absolute top-0 left-0 tracking-[0] whitespace-nowrap">
                    4.9
                  </div>
                  <img
                    className="absolute w-[56px] h-[9px] top-[3px] left-[24px]"
                    alt="Group"
                    src="group-349.png"
                  />
                </div>
                <div className="absolute top-0 left-[92px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[12px] tracking-[0] leading-[16px] whitespace-nowrap">
                  500 reviews
                </div>
              </div>
              <div className="inline-flex items-center justify-center gap-[8px] relative flex-[0_0_auto] rounded-[3px]">
                <div className="relative w-fit mt-[-2.00px] [font-family:'Montserrat-SemiBold',Helvetica] font-semibold text-purplenormal text-[12px] text-center tracking-[0.36px] leading-[16px] whitespace-nowrap">
                  KNOW MORE
                </div>
                <img
                  className="relative w-[20px] h-[12px]"
                  alt="Vector"
                  src="vector.svg"
                />
              </div>
            </div>
            <img
              className="w-[1228px] absolute h-px top-[142px] left-[23px] object-cover"
              alt="Line"
              src="line-186.svg"
            />
          </div>
          <div className="absolute w-[952px] h-[360px] top-[1260px] left-[406px] bg-white rounded-[8px] border border-solid border-divider">
            <div className="absolute w-[150px] h-[40px] top-[23px] left-[779px]">
              <div className="[font-family:'Montserrat-Medium',Helvetica] font-medium text-purplenormal text-[32px] text-center leading-[40px] absolute top-0 left-0 tracking-[0] whitespace-nowrap">
                ₹749
              </div>
              <div className="absolute w-[62px] h-[29px] top-[4px] left-[90px]">
                <div className="relative w-[58px] h-[29px]">
                  <div className="absolute top-0 left-0 [font-family:'Inter-Regular',Helvetica] font-normal text-[#319f43] text-[12px] tracking-[0] leading-[14.4px] whitespace-nowrap">
                    50% Off
                  </div>
                  <div className="absolute top-[12px] left-0 [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#33333366] text-[14px] tracking-[0] leading-[16.8px] line-through whitespace-nowrap">
                    ₹ 1499 /-
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-flex flex-col items-start gap-[16px] absolute top-[159px] left-[23px]">
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-13.svg"
                  />
                </div>
                <p className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Filing of One Trademark application
                </p>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-14.svg"
                  />
                </div>
                <div className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Advice on Class Selection
                </div>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-15.svg"
                  />
                </div>
                <div className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Drafting of documents
                </div>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-16.svg"
                  />
                </div>
                <p className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Expert Support on phone/ Video Call
                </p>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#f2f5fb] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-17.svg"
                  />
                </div>
                <p className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[14px] tracking-[0] leading-[16px]">
                  Including Govt. Fee of Rs.4500/-
                </p>
              </div>
              <div className="flex items-start gap-[11px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative w-[15px] h-[15px] bg-[#ebf1ff] rounded-[7.5px]">
                  <img
                    className="absolute w-[8px] h-[7px] top-[4px] left-[4px]"
                    alt="Path"
                    src="path-18.svg"
                  />
                </div>
                <div className="relative flex-1 mt-[-1.00px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-dark-gray text-[14px] tracking-[0] leading-[normal]">
                  Dedicated onboarding specialist
                </div>
              </div>
            </div>
            {/* <Button
            className="!px-[25px] !py-[16px] !left-[687px] !absolute !w-[240px] !top-[75px]"
            divClassName="!whitespace-nowrap !leading-[16px]"
            property1="variant-2"
          /> */}
            <div className="inline-flex flex-col items-start gap-[10px] absolute top-[23px] left-[23px]">
              <div className="relative w-fit mt-[-1.00px] [font-family:'Montserrat-Medium',Helvetica] font-medium text-global-colorstext-colorblack text-[16px] tracking-[0.48px] leading-[24px] whitespace-nowrap">
                Proprietorship
              </div>
              <div className="relative w-[165px] h-[16px] mr-[-2.00px]">
                <div className="absolute w-[82px] h-[16px] top-0 left-0">
                  <div className="[font-family:'Montserrat-Bold',Helvetica] font-bold text-[#000000d9] text-[12px] leading-[16px] absolute top-0 left-0 tracking-[0] whitespace-nowrap">
                    4.9
                  </div>
                  <img
                    className="absolute w-[56px] h-[9px] top-[3px] left-[24px]"
                    alt="Group"
                    src="image.png"
                  />
                </div>
                <div className="absolute top-0 left-[92px] [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#333333] text-[12px] tracking-[0] leading-[16px] whitespace-nowrap">
                  500 reviews
                </div>
              </div>
              <div className="inline-flex items-center justify-center gap-[8px] relative flex-[0_0_auto] rounded-[3px]">
                <div className="relative w-fit mt-[-2.00px] [font-family:'Montserrat-SemiBold',Helvetica] font-semibold text-purplenormal text-[12px] text-center tracking-[0.36px] leading-[16px] whitespace-nowrap">
                  KNOW MORE
                </div>
                <img
                  className="relative w-[20px] h-[12px]"
                  alt="Vector"
                  src="vector-2.svg"
                />
              </div>
            </div>
            <img
              className="w-[904px] absolute h-px top-[142px] left-[23px] object-cover"
              alt="Line"
              src="line-187.svg"
            />
          </div>
          <img
            className="fixed w-[1440px] h-[96px] top-0 left-0"
            alt="Group"
            src="group-367.png"
          />
        </div>
      </div>
    </>
  );
};

export default NewTest;
