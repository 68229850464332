import * as yup from 'yup';
import Breadcrumb from '../../../../components/common/Breadcrumb';
import CustomEditor from '../../../../components/common/CustomEditor';
import CustomPagination from '../../../../components/common/CustomPagination';
import Error from '../../../../components/common/Error';
import NoDataFound from '../../../../components/common/NoDataFound';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Typography } from '@material-tailwind/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { numberValidation } from '../../../../utils/regex';
import { successToast } from '../../../../utils/toast';

import {
  getMembershipPrices,
  getUserMebershipDetails,
  updateMembershipDetails,
} from '../../../../api/membership';
// import deleteicon from "../../../../assets/images/comp_imgs/delete-icon.jpg";
const TABLE_HEAD = ['Effective Date', 'Price Range', 'Price Change'];

const memberSchema = yup.object().shape({
  mail_message: yup.string().required('This field is required'),
  change_date: yup.string().required('This field is required'),
  changed_price: yup.string().required('This field is required'),
});
const AddExistingMemberPriceChange = () => {
  const { loader } = useSelector((state) => state.loader);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { user_id } = useParams();
  const [membershipList, setMembershipList] = useState([]);
  const [membershipId, setMembershipId] = useState('');
  const [skip, setSkip] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [priceList, setPriceList] = useState([]);
  const editRoute =
    location?.pathname?.indexOf(`/prices/membership/existing-member-price-changes/edit`) > -1
      ? true
      : false;

  const viewRoute =
    location?.pathname?.indexOf(`/prices/membership/existing-member-price-changes/view`) > -1
      ? true
      : false;

  useEffect(() => {
    getMembershipList();
    getMembershipPricingList();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tier: [
        {
          price_from: '',
          price_to: '',
        },
      ],
    },
    resolver: yupResolver(memberSchema),
  });

  const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray({
    control,
    name: 'tier',
  });

  const getMembershipList = async () => {
    let payload = {
      id,
    };
    const response = await getUserMebershipDetails(payload);
    if (response?.status_code === 200) {
      setMembershipList(response?.data || []);
    }
  };

  const getMembershipPricingList = async () => {
    let payload = {
      id,
    };
    const response = await getMembershipPrices(payload);
    if (response?.status_code === 200) {
      setPriceList(response?.data?.price_arr || []);
    }
  };

  const updateMembership = async (values) => {
    let payload = {
      id,
      user_id,
      change_date: values.change_date,
      changed_price: values.changed_price,
      mail_message: values.mail_message,
      mail_message: values.mail_message,
      price: membershipList?.price,
    };
    const response = await updateMembershipDetails(payload);
    if (response?.status_code === 200) {
      successToast(response?.message);
      reset();
      navigate(-1);
    }
  };

  const onSubmit = handleSubmit((values) => {
    updateMembership(values);
  });

  console.log(errors, 'error');
  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className='flex flex-col justify-center items-center'>
          <Breadcrumb
            module={'prices'}
            subModule={'Membership'}
            parent='Existing Member Price Change'
            child={'Price change for existing members'}
          />
          <div className='flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4   hide_scroll  overflow-y-auto'>
            <div className='bg-white flex flex-col  w-full  items-start pt-2  rounded-lg '>
              <>
                {/* heading */}
                <div className='bg-white flex flex-col  w-full  items-start pt-2 px-4 rounded-lg '>
                  <b className='unlimited-heading mb-2 text-2xl '>
                    Price change for existing members
                  </b>
                  {/* content */}

                  {/* price change dropdown */}
                  <div className='text-neutral-800 text-sm font-semibold leading-5 max-md:max-w-full mt-4'>
                    Membership Name
                  </div>
                  <div className='mt-2'>
                    <input
                      className='outline-none'
                      type='text'
                      disabled={true}
                      value={membershipList?.name}
                      placeholder='Membership Name'
                    />
                    <Error error={errors?.membership_id} message={errors?.membership_id?.message} />
                  </div>
                  {/* discount period */}
                  <>
                    <div className='text-neutral-800 text-base font-semibold leading-5 max-md:max-w-full mt-3'>
                      Effective date
                    </div>
                    <div className='items-stretch flex gap-4 pr-20 max-md:flex-wrap max-md:pr-5 mt-3'>
                      <span className='justify-center items-stretch flex grow basis-[0%] flex-col max-md:max-w-full'>
                        <div className='text-neutral-800 text-sm font-semibold leading-5 max-md:max-w-full'>
                          Start date
                        </div>
                        <div className='flex justify-between gap-2 mt-2 items-start max-md:max-w-full max-md:flex-wrap'>
                          <div className='flex flex-col'>
                            <div className='items-stretch border border-[color:var(--Grey-mid,#C0C0C0)] bg-white flex grow basis-[0%] flex-col justify-center px-3 py-3 rounded-lg border-solid max-md:px-5'>
                              <span className='flex justify-between gap-2 pr-4 items-start'>
                                <div className='text-neutral-900 text-base'>
                                  <input
                                    className='outline-none'
                                    type='date'
                                    disabled={true}
                                    value={moment(membershipList?.createdAt).format('YYYY-MM-DD')}
                                    placeholder='Start Date'
                                  />
                                </div>
                              </span>
                            </div>
                            <Error
                              error={errors?.start_date}
                              message={errors?.start_date?.message}
                            />
                          </div>
                        </div>
                      </span>
                      <span className='justify-center items-stretch flex grow basis-[0%] flex-col pr-10 max-md:max-w-full max-md:pr-5'>
                        <div className='text-neutral-800 text-sm font-semibold leading-5 max-md:max-w-full'>
                          End date
                        </div>
                        <div className='flex gap-2 mt-2 items-start max-md:max-w-full max-md:flex-wrap'>
                          <div className='flex flex-col'>
                            <div className='items-stretch  border border-[color:var(--Grey-mid,#C0C0C0)] bg-white flex grow basis-[0%] flex-col justify-center px-3 py-3 rounded-lg border-solid'>
                              <span className='flex justify-between gap-2 items-start'>
                                <div className='text-neutral-900 text-base'>
                                  <input
                                    className='outline-none'
                                    type='date'
                                    value={moment(membershipList?.renewal_date).format(
                                      'YYYY-MM-DD',
                                    )}
                                    disabled={true}
                                    placeholder='End Date'
                                  />
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </span>
                      {editRoute && (
                        <span className='justify-center items-stretch flex grow basis-[0%] flex-col max-md:max-w-full'>
                          <div className='text-neutral-800 text-xs font-semibold leading-5 max-md:max-w-full'>
                            Update your date for updated Price
                          </div>
                          <div className='flex justify-between gap-2 mt-2 items-start max-md:max-w-full max-md:flex-wrap'>
                            <div className='flex flex-col'>
                              <div className='items-stretch border border-[color:var(--Grey-mid,#C0C0C0)] bg-white flex grow basis-[0%] flex-col justify-center px-3 py-3 rounded-lg border-solid max-md:px-5'>
                                <span className='flex justify-between gap-2 pr-4 items-start'>
                                  <div className='text-neutral-900 text-base'>
                                    <input
                                      className='outline-none'
                                      type='date'
                                      placeholder='Date'
                                      {...register('change_date')}
                                    />
                                  </div>
                                </span>
                              </div>
                              <Error
                                error={errors?.change_date}
                                message={errors?.change_date?.message}
                              />
                            </div>
                          </div>
                        </span>
                      )}
                    </div>
                  </>

                  {/* price changes */}
                  <>
                    <div className='text-neutral-800 text-base font-semibold leading-5 max-md:max-w-full mt-3'>
                      Price Changes
                    </div>

                    <div className=''>
                      {/* <div className="flex gap-4">
                              {fields?.length > 1 && (
                                <div>
                                  <img
                                    className="w-[20px] h-[20px] cursor-pointer"
                                    alt="next-layer"
                                    src={deleteicon}
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  />
                                </div>
                              )}
                            </div> */}
                      <div className='flex gap-4 mt-3'>
                        <div className='price1'>
                          <div className='flex flex-row gap-1 items-center'></div>
                          <div class=' flex-col justify-start items-start gap-1 inline-flex'>
                            <div class='self-stretch  px-3 py-3 bg-white rounded-lg border border-stone-300 flex-col justify-start items-start gap-2.5 flex'>
                              <div class='self-stretch  justify-start items-center gap-2 inline-flex'>
                                <div class='justify-start items-center gap-1 flex'>
                                  <div class="text-black text-opacity-20 text-base font-normal font-['Hauora']">
                                    $
                                  </div>
                                </div>
                                <div className='text'>
                                  <input
                                    name='effective-time'
                                    placeholder='00:00'
                                    className='text outline-none'
                                    onKeyDown={numberValidation}
                                    value={membershipList?.price}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {editRoute && <div className='self-center mt-2'>to</div>}
                        {editRoute && (
                          <div className='price3'>
                            <div class=' flex-col justify-start items-start gap-1 inline-flex'>
                              <div class='self-stretch  px-3 py-3 bg-white rounded-lg border border-stone-300 flex-col justify-start items-start gap-2.5 flex'>
                                <div class='self-stretch  justify-start items-center gap-2 inline-flex'>
                                  <div class='justify-start items-center gap-1 flex'>
                                    <div class="text-black text-opacity-20 text-base font-normal font-['Hauora']">
                                      $
                                    </div>
                                  </div>
                                  <div className='text'>
                                    <input
                                      name='effective-time'
                                      placeholder=''
                                      className='text outline-none'
                                      onKeyDown={numberValidation}
                                      {...register('changed_price')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>

                  {/* email to customer */}
                  <>
                    <div className='text-neutral-800 text-base font-semibold leading-5 max-md:max-w-full mt-3'>
                      Email to customer
                    </div>
                    <div className='w-3/4 pt-2'>
                      <CustomEditor
                        initialValue={getValues().mail_message}
                        onChange={(value) => {
                          setValue('mail_message', value.target.getContent());
                          trigger('mail_message');
                        }}
                      />
                    </div>
                    <Error error={errors?.mail_message} message={errors?.mail_message?.message} />
                  </>

                  {/* price history */}
                  <div className='text-neutral-800 text-base font-semibold leading-5 max-md:max-w-full mt-3'>
                    Price History
                  </div>
                  {/* price-history-table */}
                  <div className=' w-[-webkit-fill-available] rounded-none commom-react-table-wrap'>
                    <table className='w-full  mx-4 table-auto text-left'>
                      <thead>
                        <tr>
                          {TABLE_HEAD.map((head) => (
                            <th
                              key={head}
                              className='border-b border-blue-gray-100   px-14 text-start py-6'
                            >
                              <Typography
                                variant='small'
                                color='blue-gray'
                                className='font-normal leading-none opacity-70'
                              >
                                {head}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {priceList?.map((item, index) => {
                          const isLast = index === priceList.length - 1;
                          const classes = isLast ? 'py-6 px-14' : 'py-6 px-14 border-none';
                          return (
                            <>
                              {Object.entries(item)?.map(([key, value], ind) => (
                                <>
                                  {value?.map((pricedata, priceInd) => (
                                    <tr key={priceInd}>
                                      {priceInd === 0 && (
                                        <td
                                          rowspan={value?.length}
                                          className={classes + ' cursor-pointer '}
                                        >
                                          <Typography
                                            variant='small'
                                            color='blue-gray'
                                            className='font-normal flex flex-row gap-5'
                                          >
                                            <p>{moment(key).format('YYYY-MM-DD') || 'N/A'}</p>
                                          </Typography>
                                        </td>
                                      )}
                                      <td className={classes}>
                                        <Typography
                                          variant='small'
                                          color='blue-gray'
                                          className='font-normal'
                                        >
                                          {pricedata?.price || 'N/A'}
                                        </Typography>
                                      </td>
                                      <td className={classes}>
                                        <Typography
                                          variant='small'
                                          color='blue-gray'
                                          className='font-normal'
                                        >
                                          {pricedata?.changed_price || 'N/A'}
                                        </Typography>
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ))}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                    {!loader && priceList?.length === 0 && <NoDataFound />}
                    {pageCount > 1 && (
                      <>
                        <div className='bg-white flex flex-col gap-1 w-full  items-end pt-2 px-2   rounded-lg   '>
                          <Card className='py-2 rounded-none'>
                            <span className='bg-[#E6E6E6] py-3  px-2 w-auto items-end ml-3  rounded-xl '>
                              {pageCount > 1 && (
                                <CustomPagination page={skip} setPage={setSkip} count={pageCount} />
                              )}
                            </span>
                          </Card>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </div>

            <div className=' bg-white flex flex-col gap-1 w-full  items-end   rounded-lg mt-4   '>
              <div className='flex flex-row py-3 justify-center items-center px-4 gap-4 '>
                <div className=' bg-white flex flex-col gap-1 w-full  items-start  rounded-lg   '>
                  <div
                    className='text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white text-black px-16 py-3 rounded-lg border-solid   cursor-pointer'
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </div>
                </div>

                <div className='  flex flex-col gap-1 w-full  items-start rounded-lg   '>
                  <button
                    typr='submit'
                    className='text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch  bg-[#8C2323] text-white px-16 py-3 rounded-lg border-solid  cursor-pointer'
                  >
                    Apply Pricing
                  </button>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddExistingMemberPriceChange;
