import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import ContainerHeading from "../../../../components/common/container-heading/ContainerHeading";
import Error from "../../../../components/common/Error";
import UploadImage from "../../../../components/common/uploads/UploadImage";
import CustomEditor from "../../../../components/common/CustomEditor";
import {
  addBlog,
  editBlog,
  getBlog,
} from "../../../../api/content-mgmt/newsAndBlog";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import { errorToast, successToast } from "../../../../utils/toast";
import { imageValidation, uploadImageTemplate } from "../../../../helper";
import { alphabetValues } from "../../../../utils/regex";

// schema
const blogSchema = yup.object().shape({
  blog_title: yup
    .string()
    .required("Title is required")
    .max(120, "Max character limit reached"),
  blog_category: yup.string().required("Category is required"),
  blog_slug: yup
    .string()
    .required("Slug is required")
    .max(160, "Max character limit reached"),
  blog_image: yup
    .mixed()
    .required("Image is required")
    .test("file", "You need to provide a file", imageValidation),
  blog_section: yup
    .string()
    .required("Blog Section is required")
    .max(300, "Max character limit reached"),
  author_name: yup
    .string()
    .required("Author name is required")
    .max(50, "Max character limit reached"),
  author_title: yup
    .string()
    .required("Author Title is required")
    .max(60, "Max character limit reached"),
  author_image: yup.mixed().required("Author Logo is required"),
});

const AddNewsAndBLog = () => {
  // router functions
  const navigate = useNavigate();
  const location = useLocation();
  //  id from params
  const { id } = useParams();
  // check path
  const isEdit = location?.pathname?.indexOf("edit") > -1 ? true : false;

  // local state
  const [uniqueNewsAndBlogDetails, setuniqueNewsAndBlogDetails] = useState();

  // useform functions
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(blogSchema),
  });

  console.log(errors, "errors");
  // sideEffect
  useEffect(() => {
    if (isEdit) {
      getNewsAndBlogs();
    }
  }, []);

  //
  const getNewsAndBlogs = async () => {
    let response = await getBlog(id);
    setValue("blog_title", response?.data?.blog_title);
    setValue("blog_category", response?.data?.blog_category);
    setValue("blog_slug", response?.data?.blog_slug);
    setValue("blog_section", response?.data?.blog_section);
    setValue("author_name", response?.data?.author_name);
    setValue("author_title", response?.data?.author_title);
    setValue("blog_image", response?.data?.blog_image);
    setValue("author_image", response?.data?.author_image);

    setuniqueNewsAndBlogDetails(response?.data);
  };

  // edit legal function
  const handleEditNewsAndBlog = async (values) => {
    console.log(values);

    let formData = new FormData();
    formData.append("id", id);
    formData.append("blog_published_date", "test");

    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "object") {
        formData.append(key, values[key][0]);
      } else {
        formData.append(key, values[key].replace(/<[^>]+>/g, ""));
      }
    });

    const response = await editBlog(formData);
    if (response.status_code === 200) {
      successToast(response?.message);
      navigate("/content/page-mgmt/news&blog");
    } else if (response.status_code === 400) {
      errorToast(response?.message);
    }
  };

  //  add new blog function
  const handleAddNewsAndBlogs = async (values) => {
    let formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "object") {
        formData.append(key, values[key][0]);
      } else {
        formData.append(key, values[key].replace(/<[^>]+>/g, ""));
      }
    });
    const response = await addBlog(formData);
    if (response.status_code === 200) {
      successToast(response?.message);
      navigate("/content/page-mgmt/news&blog");
    } else if (response?.status_code === 400) {
      errorToast(response?.message);
    }
  };

  // form submit function
  const onSubmit = handleSubmit((values) => {
    if (isEdit) handleEditNewsAndBlog(values);
    else handleAddNewsAndBlogs(values);
  });

  return (
    <>
        <div className="flex flex-col justify-center items-center">
          {/* <Breadcrumb
            module={"Content Management"}
            subModule={"Page management"}
            parent={"News & Blog"}
            child={isEdit ? "Edit Blog" : "Add Blog"}
          /> */}
          <div className=" w-full  hide_scroll  overflow-y-auto">
            <div className="mt-0">
              <ContainerHeading
                className={"rounded-lg"}
                title={isEdit ? "Upadte Blog Section" : " Add New Blog Section"}
              />
            </div>
            {/* forms */}
            <form onSubmit={onSubmit}>
              <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4 pb-4 pl-4  ">
                <div className="text-neutral-900 text-sm font-semibold leading-4 mt-6 ">
                  Basic information
                </div>
                <>
                  {/* title */}

                  <div className="text-neutral-900 text-sm font-normal leading-4 mt-6 ">
                    Blog Title
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full outline-none bg-white"
                      placeholder="Blog Title"
                      {...register("blog_title")}
                    />
                  </div>

                  <Error
                    error={errors?.blog_title}
                    message={errors?.blog_title?.message}
                  />
                </>
                <>
                  {/* cateogory */}

                  <div className="text-neutral-900 text-sm font-normal leading-4 mt-6 ">
                    Blog Category
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full outline-none bg-white"
                      placeholder="Blog Category"
                      {...register("blog_category")}
                    />
                  </div>

                  <Error
                    error={errors?.blog_category}
                    message={errors?.blog_category?.message}
                  />
                </>
                <>
                  {/* slug */}

                  <div className="text-neutral-900 text-sm font-normal leading-4 mt-6 ">
                    Blog Slug
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full outline-none bg-white"
                      placeholder="Blog Slug"
                      {...register("blog_slug")}
                    />
                  </div>

                  <Error
                    error={errors?.blog_slug}
                    message={errors?.blog_slug?.message}
                  />
                </>
              </div>
              <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4 pb-4 pl-4  ">
                <div className="text-neutral-900 text-sm font-semibold leading-4 mt-6 ">
                  Further details
                </div>
                <>
                  {/* blog-image */}
                  <UploadImage
                    className=""
                    title="Main Image"
                    inputId={`blog_input_id`}
                    imgId={`blog_img_id`}
                    name={"blog_image"}
                    src={
                      uniqueNewsAndBlogDetails?.blog_image ||
                      uploadImageTemplate
                    }
                    setValue={setValue}
                    register={register}
                    type="upload"
                    trigger={trigger}
                  />
                  <Error
                    error={errors?.blog_image}
                    message={errors?.blog_image?.message}
                  />
                </>
                {/* blog-section */}
                <>
                  <div className="text-neutral-900 text-lg font-normal leading-4 mt-6 ">
                    Section copy
                  </div>
                  <>
                    {" "}
                    <div className="w-3/4 pt-2">
                      <CustomEditor
                        initialValue={getValues().blog_section}
                        onChange={(value) => {
                          setValue("blog_section", value.target.getContent());
                          trigger("blog_section");
                        }}
                      />
                    </div>
                    <Error
                      error={errors?.blog_section}
                      message={errors?.blog_section?.message}
                    />
                  </>
                </>
                {/* author */}
                <div className="text-neutral-900 text-sm font-semibold leading-4 mt-6 ">
                  Author
                </div>
                <>
                  {/* author Name */}

                  <div className="text-neutral-900 text-sm font-normal leading-4 mt-6 ">
                    Author Name
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full outline-none bg-white"
                      placeholder="Author Name"
                      {...register("author_name")}
                      onKeyDown={alphabetValues}
                    />
                  </div>
                  <Error
                    error={errors?.author_name}
                    message={errors?.author_name?.message}
                  />
                </>
                <>
                  {/* author title */}

                  <div className="text-neutral-900 text-sm font-normal leading-4 mt-6 ">
                    Author Title
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full outline-none bg-white"
                      placeholder="Author Title"
                      {...register("author_title")}
                      onKeyDown={alphabetValues}
                    />
                  </div>
                  <Error
                    error={errors?.author_title}
                    message={errors?.author_title?.message}
                  />
                </>
                <>
                  {/* author-author_image */}
                  <>
                    <div>
                      <UploadImage
                        className=""
                        title="Author Logo"
                        inputId={`author_input_id`}
                        imgId={`author_img_id`}
                        name={"author_image"}
                        src={
                          uniqueNewsAndBlogDetails?.author_image ||
                          uploadImageTemplate
                        }
                        setValue={setValue}
                        register={register}
                        type="upload"
                        trigger={trigger}
                      />
                    </div>
                  </>
                  <Error
                    error={errors?.author_image}
                    message={errors?.author_image?.message}
                  />
                </>
              </div>
              {/* footer-button */}
              <div className=" bg-white flex flex-col gap-1 w-full  items-start   rounded-lg mt-4   ">
                <div className="flex flex-row py-3 justify-center items-center ">
                  <div className=" bg-white flex flex-col gap-1 w-full  items-start  rounded-lg   ">
                    <div
                      className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white text-black px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 cursor-pointer"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </div>
                  </div>

                  <div className=" bg-white flex flex-col gap-1 w-full  items-start rounded-lg   ">
                    <button
                      typr="submit"
                      className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-black text-white px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 cursor-pointer"
                    >
                      {isEdit ? "Update" : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
    </>
  );
};

export default AddNewsAndBLog;
