import React from 'react'
import CustomLabel from '../../../dashboard/user-mgmt/CustomLabal'
import Error from '../../../../components/common/Error'
import {
    faAdd,
    faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageUpload from '../../../../components/common/ImageUpload';
import { Button } from "@material-tailwind/react";


const DocumentSecurityClerance = ({ register, errors, handleSubmit, setValue, trigger, control, useFieldArray,onSubmit,setState,switchToNextTab }) => {

    const {
        fields: ScannedCopiesOfLiCert,
        append: ScannedCopiesOfLiCertAppend,
        prepend: ScannedCopiesOfLiCertPrepend,
        remove: ScannedCopiesOfLiCertRemove,
        swap: ScannedCopiesOfLiCertSwap,
        move: ScannedCopiesOfLiCertMove,
        insert: ScannedCopiesOfLiCertInsert,
        update: ScannedCopiesOfLiCertUpdate,
    } = useFieldArray({
        control,
        name: "ScannedCopiesOfLiCert",
    });

    const {
        fields: AnyOtherDocs,
        append: AnyOtherDocsAppend,
        prepend: AnyOtherDocsPrepend,
        remove: AnyOtherDocsRemove,
        swap: AnyOtherDocsSwap,
        move: AnyOtherDocsMove,
        insert: AnyOtherDocsInsert,
        update: AnyOtherDocsUpdate,
    } = useFieldArray({
        control,
        name: "AnyOtherDocs",
    });


    return (
        <div className='h-[600px] overflow-y-auto'>
            <form onSubmit={onSubmit}>
                <div className="space-y-8 mt-8 pb-10">
                    <h2 className="text-[20px] font-bold text-[#303637]">
                        Upload Documents
                    </h2>
                    <div className="flex justify-between w-full gap-8 ">
                        <div className="w-full space-y-8">
                            <div className="w-full form-input">
                                <CustomLabel label={"Scanned Copies of Licenses and Certifications"} />
<div className='row'>
                                {ScannedCopiesOfLiCert?.map((item, index) => (
                                    <div key={item?.id} className="mt-2 col-md-4 relative">
                                        {ScannedCopiesOfLiCert?.length > 1 && (
                                            <button
                                                type="button"
                                                className="btn-cros"
                                                onClick={() => {
                                                    ScannedCopiesOfLiCertRemove(index)
                                                    // setPreview(preview?.filter((item, i) => i !== index));
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faClose} />
                                            </button>
                                        )}
                                        <ImageUpload
                                            inputId={`ScannedCopiesOfLiCertUploadImage${index}`}
                                            imgId={`ScannedCopiesOfLiCertImage${index}`}
                                            src={"https://as1.ftcdn.net/v2/jpg/04/94/12/10/1000_F_494121047_MWi1oEYNest7yl1CardZO8jFtOwS7aRT.jpg"}
                                            // src={productImages[index]?.preview_type == "video" ? defaultVideo : productImages[index]?.image || uploades}
                                            className="round-4"
                                            name={`ScannedCopiesOfLiCert.${index}.files`}
                                            setValue={setValue}
                                            register={register}
                                            type="url"
                                            // name2={`certificates.${index}.preview_type`}
                                            accept="image/*"
                                        />
                                        <div>

                                            {index === ScannedCopiesOfLiCert?.length - 1 && (
                                                <button
                                                    type="button"
                                                    className="common-btn add-user-button me-2"
                                                    onClick={() =>
                                                        // setPreview([...preview, ""])
                                                        ScannedCopiesOfLiCertAppend(index,{files:""})
                                                    }
                                                >
                                                    <FontAwesomeIcon className="add-image " icon={faAdd} />Add More
                                                </button>
                                            )}

                                        </div>
                                    </div>
                                ))}
                                <Error error={errors?.ScannedCopiesOfLiCert} message={errors?.ScannedCopiesOfLiCert?.message} />

                            </div>
                            </div>
                            <div className="w-full">
                                <CustomLabel label={"Any Other Documents"} />
                                <div className='row'>
                                {AnyOtherDocs?.map((item, index) => (
                                    <div key={item?.id} className="mt-2 col-md-4 relative">
                                        {AnyOtherDocs?.length > 1 && (
                                            <button
                                                type="button"
                                                className="btn-cros"
                                                onClick={() => {
                                                    AnyOtherDocsRemove(index)
                                                    // setPreview(preview?.filter((item, i) => i !== index));
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faClose} />
                                            </button>
                                        )}
                                        <ImageUpload
                                            inputId={`AnyOtherDocsUploadImage${index}`}
                                            imgId={`AnyOtherDocsImage${index}`}
                                            src={"https://as1.ftcdn.net/v2/jpg/04/94/12/10/1000_F_494121047_MWi1oEYNest7yl1CardZO8jFtOwS7aRT.jpg"}
                                            // src={productImages[index]?.preview_type == "video" ? defaultVideo : productImages[index]?.image || uploades}
                                            className=""
                                            name={`AnyOtherDocs.${index}.files`}
                                            setValue={setValue}
                                            register={register}
                                            type="url"
                                            // name2={`certificates.${index}.preview_type`}
                                            accept="image/*"
                                        />
                                        <div>

                                            {index === AnyOtherDocs?.length - 1 && (
                                                <button
                                                    type="button"
                                                    className="common-btn add-user-button"
                                                    onClick={() =>
                                                        // setPreview([...preview, ""])
                                                        AnyOtherDocsAppend(index,{files:""})
                                                    }
                                                >
                                                    <FontAwesomeIcon className="add-image me-2" icon={faAdd} /> Add More
                                                </button>
                                            )}

                                        </div>
                                    </div>
                                ))}
                                <Error error={errors?.AnyOtherDocs} message={errors?.AnyOtherDocs?.message} />
                            </div>
                            </div>
                            <div className="w-full">
                                <CustomLabel label={"Passport Copy"} />
                                <div className='row'>
                                <div className="mt-2 col-md-4">
                                    <ImageUpload
                                        inputId={`PassportCopyUploadImage`}
                                        imgId={`PassportCopyImage`}
                                        src={"https://as1.ftcdn.net/v2/jpg/04/94/12/10/1000_F_494121047_MWi1oEYNest7yl1CardZO8jFtOwS7aRT.jpg"}
                                        // src={productImages[index]?.preview_type == "video" ? defaultVideo : productImages[index]?.image || uploades}
                                        className=""
                                        name={`PassportCopy`}
                                        setValue={setValue}
                                        register={register}
                                        type="upload"
                                        // name2={`certificates.${index}.preview_type`}
                                        accept="image/*"
                                    />
                                </div>
                                <Error error={errors?.PassportCopy} message={errors?.PassportCopy?.message} />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-y-8 mt-8 pb-10">
                    <h2 className="text-[20px] font-bold text-[#303637]">
                        Security Clearance
                    </h2>
                    <div className="flex justify-between w-full gap-8 ">
                        <div className="w-full space-y-8">
                            <div className="w-full form-input">
                                <CustomLabel label={"Status of Background Checks"} />

                                {/* <CustomerManagementInput /> */}
                                <input
                                    className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                    type="text"
                                    placeholder='Status of Background Checks                    '
                                    {...register("BackgroundChecksStatus")}
                                // autoFocus="off"
                                />
                                <Error error={errors?.BackgroundChecksStatus} message={errors?.BackgroundChecksStatus?.message} />

                            </div>
                            <div className="w-full">
                                <CustomLabel label={"Security Clearance Level"} />
                                <input
                                    className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                    type="text"
                                    placeholder='Enter Your Total Flight Hours'
                                    {...register("SecurityClearanceLevel")}
                                // autoFocus="off"
                                />
                                <Error error={errors?.SecurityClearanceLevel} message={errors?.SecurityClearanceLevel?.message} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='w-100'>
                    <Button size="sm" variant="filled" className="" onClick={()=>switchToNextTab()}>Next</Button>

                </div> */}
            </form>
        </div>
    )
}

export default DocumentSecurityClerance