import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Box from "../layout/Box";
import { Button } from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Error from "../../components/common/Error";
import Form from "react-bootstrap/Form";
import { logInApi } from "../../api/auth/login";
import { saveAuth } from "../../redux/action/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { errorToast, successToast } from "../../utils/toast";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const schema = yup.object({
  email: yup
    .string()
    .email("This Email is not valid")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one special character, and one number. It should be between 8 and 15 characters."
    ),
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const logIn = async (values) => {
    let payload = {};
    Object.keys(values).forEach((key) => {
      payload[key] = values[key];
    });
    const response = await logInApi(payload);
    if (response.status_code === 200) {
      const permissionsMap = {}
      response?.data?.roles_array?.forEach((role) => {
        permissionsMap[role.role_name] = role.role_status
      })
      dispatch(saveAuth({ ...response?.data, permissionsMap }));
      successToast(response?.message);
      navigate("/dashboard/graph");
    } else {
      errorToast(response?.message || "Something Went Wrong");
    }
  };

  const onSubmit = handleSubmit((values) => {
    logIn(values);
  });

  return (
    <>
      {/* <Navbar navbarContentShow={true} /> */}
      <Box>
        <form onSubmit={onSubmit}>
          <div className="m-0 h-[85vh] row  items-center ">
            <div className="relative logo-sec col-md-6">
              <img src={logo} alt="logo" className=" w-[225px] mb-2" />
            </div>
            <div className="col-md-6 row justify-center items-center">
              <div className="h-12 w-[96] md:w-[440px] input_box_shadow mt-10 flex flex-col justify-center rounded ">
                <div className="flex gap-3 items-center text-center ">
                  <svg
                    className="ms-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M18.2506 7.373V14.5C18.2506 15.8807 17.1313 17 15.7506 17H4.75057C3.36986 17 2.25049 15.8807 2.25049 14.5V7.373L9.99706 11.931C10.1535 12.023 10.3476 12.023 10.5041 11.931L18.2506 7.373ZM15.7506 4C17.0376 4 18.0975 4.9726 18.2354 6.22293L10.2506 10.9199L2.26575 6.22293C2.4036 4.9726 3.4635 4 4.75057 4H15.7506Z"
                      fill="#212121"
                    />
                  </svg>
                  <span>Black Jet email</span>
                </div>
              </div>
              <div className=" h-[340px] w-[696px] md:w-[440px] input_box_shadow mt-8 flex flex-col px-[24px] gap-8 rounded">
                <div className="flex gap-3 items-center text-center mt-4 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="14"
                    viewBox="0 0 26 14"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_603_36086)">
                      <path
                        d="M22.501 14H16.501V10H13.821C12.681 12.42 10.221 14 7.50098 14C3.64098 14 0.500977 10.86 0.500977 7C0.500977 3.14 3.64098 0 7.50098 0C10.221 0 12.671 1.58 13.821 4H24.501V10H22.501V14ZM18.501 12H20.501V8H22.501V6H12.441L12.211 5.33C11.511 3.34 9.61098 2 7.50098 2C4.74098 2 2.50098 4.24 2.50098 7C2.50098 9.76 4.74098 12 7.50098 12C9.61098 12 11.511 10.66 12.211 8.67L12.441 8H18.501V12ZM7.50098 10C5.85098 10 4.50098 8.65 4.50098 7C4.50098 5.35 5.85098 4 7.50098 4C9.15098 4 10.501 5.35 10.501 7C10.501 8.65 9.15098 10 7.50098 10ZM7.50098 6C6.95098 6 6.50098 6.45 6.50098 7C6.50098 7.55 6.95098 8 7.50098 8C8.05098 8 8.50098 7.55 8.50098 7C8.50098 6.45 8.05098 6 7.50098 6Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_603_36086">
                        <rect
                          width="25"
                          height="14"
                          fill="white"
                          transform="translate(0.250488)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Non-Black Jet email</span>
                </div>
                <div className=" flex w-[100%] flex-col items-end gap-8">
                  <input
                    className="form-control !h-[56px]"
                    type="text"
                    label="Email"
                    placeholder="Enter Email"
                    {...register("email")}
                  />
                  <Error
                    error={errors?.email}
                    message={errors?.email?.message}
                  />

                  <div className=" w-full flex flex-column justify-between items-center">
                    <input
                      className="form-control !h-[56px]"
                      type={showPassword ? "text" : "password"}
                      label="Passwoed"
                      placeholder="Enter Password"
                      {...register("password")}
                    />
                    <span className="self-end relative bottom-9 right-3">
                      <FontAwesomeIcon
                        className="edit-btn ms-3"
                        icon={showPassword ? faEye : faEyeSlash}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </span>
                  </div>
                  <Error
                    error={errors?.password}
                    message={errors?.password?.message}
                  />
                </div>

                <div className="flex items-center gap-3  w-full">
                  <Button
                    variant="gradient "
                    size="lg"
                    type="submit"
                    className="!w-[100%] !h-[56px]"
                  >
                    Sign in
                  </Button>
                </div>
                <div className="flex items-center text-center justify-center">
                  <p
                    className="no-underline !hover:underline !decoration-black cursor-pointer"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot password?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Box>
    </>
  );
};

export default Login;
