import React, { useEffect, useState } from 'react';
import { Card, Typography } from '@material-tailwind/react';
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';
import PersonalInfo from './PersonalInfo';
import parsePhoneNumber from 'libphonenumber-js';
import { Country } from 'country-state-city';
import * as yup from 'yup';
import { Button } from '@material-tailwind/react';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useParams, useLocation } from 'react-router-dom';
import Qualifications from './Qualifications';
import DocumentSecurityClerance from './DocumentSecurityClerance';
import Emergency from './Emergency';
import { useNavigate } from 'react-router-dom';
import {
  onAddPilots,
  onUpdatePilots,
  onviewPilots,
} from '../../../../api/flight-management/flight';
import { errorToast } from '../../../../utils/toast';
import { getDateFormat } from '../../../../utils/moment';
let countryCode = 'IN';

const pilotSchema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('First Name is required'),
  // dateOfBirth: yup.string().required('dateOfBirth is required'),
  // phone_code: yup.string(),
  // email: yup.string().email('This email is not valid').required('Email is required'),
  // Address: yup.string().required('Address is required'),
  // Nationality: yup.string().required('Nationality is required'),
  Photo: yup.mixed().required('Profile Image is required'),
  // phone: yup
  //   .string()
  //   .required('Phone is required')
  //   .test('Check Indian Number', function () {
  //     let number = this.parent['phone'];
  //     if (!number) return false;
  //     const digits = number.replace(/\D/g, '') || '';
  //     const phoneNumber = parsePhoneNumber(digits, countryCode);
  //     return !!phoneNumber ? phoneNumber.isValid() : false;
  //   }),
  // // code_phone: yup.string().default(""),
  // // dial_code: yup.string().default(""),
  // Certifications: yup.array().of(
  //   yup.object().shape({
  //     files: yup.mixed(),
  //   }),
  // ),
  // ScannedCopiesOfLiCert: yup.array().of(
  //   yup.object().shape({
  //     files: yup.mixed(),
  //   }),
  // ),
  // AnyOtherDocs: yup.array().of(
  //   yup.object().shape({
  //     files: yup.mixed(),
  //   }),
  // ),
  // SpecialQualifications: yup.array().of(
  //   yup.object().shape({
  //     files: yup.mixed(),
  //   }),
  // ),
  // PassportCopy: yup.string(),
  // LicenseNumber: yup.string(),
  // LicenseType: yup.string(),
  // LiIssuingAuthority: yup.string(),
  // LiDateOfIssue: yup.string(),
  // LiExpirationDate: yup.string().nullable(),
  // FlightSchoolAttended: yup.string(),
  // TotalFlightHr: yup.string().nullable(),
  // FlightHrByAircraftType: yup.string().nullable(),
  // MedicalCertType: yup.string(),
  // MeIssuingDoctor: yup.string(),
  // MeDateOfIssue: yup.string().nullable(),
  // MeExpiryDate: yup.string().nullable(),
  // MeRestrictions: yup.string(),
  // BackgroundChecksStatus: yup.string(),
  // SecurityClearanceLevel: yup.string(),
  // EmergencyName: yup.string(),
  // EmergencyRelation: yup.string(),
  // EmergencyPhone: yup.string(),
  // EmergencyEmail: yup.string(),
  // EmergencyPhoneCode: yup.string(),
});

const PilotTabs = ({ state, setState }) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [pilotData, setPilotData] = useState({});
  const editRoute =
    location?.pathname?.indexOf(`/flights/pilot-profile/edit-pilot`) > -1 ? true : false;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Certifications: [{ files: '' }],
      ScannedCopiesOfLiCert: [{ files: '' }],
      AnyOtherDocs: [{ files: '' }],
      SpecialQualifications: [{ files: '' }],
    },
    resolver: yupResolver(pilotSchema),
  });

  const data = [
    {
      component: PersonalInfo,
      label: 'Personal & Licensing Information',
      value: 'Personal Information',
      desc: `It really matters and then like it really doesn't matter.
              What matters is the people who are sparked by it. And the people
              who are like offended by it, it doesn't matter.`,
    },
    {
      component: Qualifications,
      label: 'Qualifications',
      value: 'Qualifications',
      desc: `Because it's about motivating the doers. Because I'm here
              to follow my dreams and inspire other people to follow their dreams, too.`,
    },
    {
      component: DocumentSecurityClerance,
      label: 'Document & Secrity Clearance',
      value: 'Documents',
      desc: `Because it's about motivating the doers. Because I'm here
      to follow my dreams and inspire other people to follow their dreams, too.`,
    },
    {
      component: Emergency,
      label: 'Emergency Contact',
      value: 'Emergency',
      desc: `We're not always in the position that we want to be at.
          We're constantly growing. We're constantly making mistakes. We're
          constantly trying to express ourselves and actualize our dreams.`,
    },
  ];

  useEffect(() => {
    if (editRoute) {
      viewPilots();
    }
  }, []);

  const addFormData = async (values) => {
    let payload = {};
    Object.keys(values).forEach((key) => {
      if (
        key === 'ScannedCopiesOfLiCert' ||
        key === 'Certifications' ||
        key === 'AnyOtherDocs' ||
        key === 'SpecialQualifications'
      ) {
        payload[key] = values[key]
          ?.filter((item) => item?.files !== '')
          ?.flatMap((item) => item?.files);
      } else {
        payload[key] = values[key];
      }
    });
    const response = await onAddPilots(payload);
    if (response.status_code === 200) {
      navigate(-1);
      console.log('Success');
    } else {
      errorToast(response?.message || 'Something Went Wrong');
    }
  };

  const viewPilots = async () => {
    let payload = {
      id,
    };
    const response = await onviewPilots(payload);
    if (response.status_code === 200) {
      setPilotData(response?.data);
      Object.keys(response?.data).forEach((key) => {
        if (key === 'dateOfBirth') {
          setValue(key, getDateFormat(response?.data[key], 'YYYY-MM-DD'));
        } else {
          setValue(key, response?.data[key]);
        }
        // payload[key] = values[key];
      });
    } else {
      errorToast(response?.message || 'Something Went Wrong');
    }
  };
  console.log(errors);
  const updateFormData = async (values) => {
    let payload = {
      pilot_id: id,
    };
    Object.keys(values).forEach((key) => {
      if (
        key === 'ScannedCopiesOfLiCert' ||
        key === 'Certifications' ||
        key === 'AnyOtherDocs' ||
        key === 'SpecialQualifications'
      ) {
        payload[key] = values[key]
          ?.filter((item) => item?.files !== '')
          ?.flatMap((item) => item?.files);
      } else {
        payload[key] = values[key] || '';
      }
    });
    delete payload.createdAt;
    delete payload.updatedAt;
    delete payload._id;
    delete payload.status;
    const response = await onUpdatePilots(payload);
    if (response.status_code === 200) {
      navigate(-1);
      console.log('Success');
    } else {
      errorToast(response?.message || 'Something Went Wrong');
    }
  };

  const switchToNextTab = () => {
    // Add logic to determine the next tab based on the current state
    // For example, switch to the next index in your `data` array
    const currentIndex = data.findIndex((tab) => tab.value === state);
    const nextIndex = (currentIndex + 1) % data.length;
    const nextTab = data[nextIndex].value;
    setState(nextTab);
  };

  useEffect(() => {
    if (Object.keys(errors)?.length != 0) {
      if (
        errors?.full_name ||
        errors?.Address ||
        errors?.dateOfBirth ||
        errors?.email ||
        errors?.Nationality ||
        errors.Photo
      ) {
        errorToast('Please Fill Persoanl Info');
      }
    }
  }, [errors]);

  const onSubmit = handleSubmit((values) => {
    if (editRoute) {
      updateFormData(values);
    } else {
      addFormData(values);
    }
  });

  return (
    <>
      <Card className='overflow-x-auto hide_scroll   rounded-2xl '>
        <PersonalInfo
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          setValue={setValue}
          trigger={trigger}
          control={control}
          onSubmit={onSubmit}
          state={state}
          setState={setState}
          value={'Personal Information'}
          pilotData={pilotData}
          switchToNextTab={switchToNextTab}
        />
        {/* <Tabs
          value="Personal Information"
          orientation="vertical"
          className="!bg-white"
        >
          <TabsHeader className="h-[100%] w-[200px] !bg-white pt-6 !text-start border-[#C0C0C0] border-r-[1px]">
            {data.map(({ label, value }) => (
              <Tab
                key={value}
                value={value}
                className="py-3"
                onClick={() => {
                  setState(value);
                }}
              >
                <span className="">{label}</span>
              </Tab>
            ))}
          </TabsHeader>

          <TabsBody>
            {data.map(({ value, desc,component }) => (
              <TabPanel key={value} value={value} className="py-0  ">
                {value === "Personal Information" && (
                  <PersonalInfo
                    register={register}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    setValue={setValue}
                    trigger={trigger}
                    control={control}
                    onSubmit={onSubmit}
                    state={state}
                    setState={setState}
                    value={value}
                    pilotData={pilotData}
                    switchToNextTab={switchToNextTab}
                  />
                )}
                 {value === "Qualifications" && (
                  <Qualifications
                    register={register}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    setValue={setValue}
                    trigger={trigger}
                    control={control}
                    onSubmit={onSubmit}
                    state={state}
                    setState={setState}
                    value={value}
                    useFieldArray={useFieldArray}
                    switchToNextTab={switchToNextTab}
                  />
                )}
                 {value === "Documents" && (
                  <DocumentSecurityClerance
                    register={register}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    setValue={setValue}
                    trigger={trigger}
                    control={control}
                    onSubmit={onSubmit}
                    state={state}
                    setState={setState}
                    value={value}
                    useFieldArray={useFieldArray}
                    switchToNextTab={switchToNextTab}
                  />
                )}
                 {value === "Emergency" && (
                  <Emergency
                    register={register}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    setValue={setValue}
                    trigger={trigger}
                    control={control}
                    onSubmit={onSubmit}
                    state={state}
                    setState={setState}
                    value={value}
                    switchToNextTab={switchToNextTab}
                  />
                )}
                  </TabPanel>
                
            ))}
          </TabsBody>
        </Tabs> */}
      </Card>
    </>
  );
};

export default PilotTabs;
