import { Textarea } from "@material-tailwind/react";
import React from "react";

const CustomerManagementTextArea = ({
  placeholder = "Enter details...",
  register = () => {},
  name,
}) => {
  return (
    <>
      <div className="w-full">
        <Textarea
          size="lg"
          placeholder={placeholder}
          className="rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A]  !h-[56px] !text-[#9A9A9A]"
          labelProps={{
            className:
              "before:content-none after:content-none !text-[#9A9A9A] ",
          }}
          containerProps={{
            className: "min-w-0",
          }}
          {...register(name)}
        />
      </div>
    </>
  );
};

export default CustomerManagementTextArea;
