import React, { useEffect, useState } from "react";
import { Card, Tooltip, Typography } from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";
import plussvg from "../../../assets/images/icon.svg";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getUserMembershipList } from "../../../api/membership";
import moment from "moment";

const TABLE_HEAD = [
  "User Name",
  "Membership Name",
  "Membership Price",
  "Effective Date",
  "Action",
];

const UserMembership = ({ getMembershipList, membership }) => {
  const navigate = useNavigate();

  useEffect(() => {
    getMembershipList();
  }, []);

  return (
    <div>
      <Card className="h-[calc(100vh-120px)] overflow-x-scroll hide_scroll rounded-none  rounded-b-2xl w-[calc(100vw-300px)]">
        <table className="w-full min-w-max mx-4 table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className=" leading-none font-semibold"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {membership.map((item, index) => {
              const isLast = index === membership.length - 1;
              const classes = isLast
                ? "py-2 px-3"
                : "py-2 px-3 border-b    border-blue-gray-50";

              return (
                <tr key={index}>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item?.fullName || "N/A"}
                    </Typography>
                  </td>

                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item?.name}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item?.price}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {moment(item?.renewal_date).format("YYYY-DD-MM")}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-row gap-2">
                      <div className="action-icon">
                        <Tooltip content={"View"}>
                          <FontAwesomeIcon
                            onClick={() =>
                              navigate(
                                `/prices/membership/existing-member-price-changes/view/${item?._id}/${item?.user_id}`
                              )
                            }
                            className="edit-btn"
                            icon={faEye}
                          />
                        </Tooltip>
                      </div>
                      <div className="action-icon">
                        <Tooltip content={"Edit"}>
                          <FontAwesomeIcon
                            onClick={() =>
                              navigate(
                                `/prices/membership/existing-member-price-changes/edit/${item?._id}/${item?.user_id}`
                              )
                            }
                            className="edit-btn"
                            icon={faPen}
                          />
                        </Tooltip>
                      </div>
                      {/* <div className="action-icon">
                        <Tooltip content={"Delete"}>
                          <FontAwesomeIcon
                            onClick={() => {
                              // handleLegalDelete(_id);
                            }}
                            className="edit-btn"
                            icon={faTrash}
                          />
                        </Tooltip>
                      </div> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

export default UserMembership;
