import * as yup from 'yup';

import Breadcrumb from '../../../../components/common/Breadcrumb';
import Error from '../../../../components/common/Error';
import Form from 'react-bootstrap/Form';
import NextScheduledPriceChange from '../../../../components/members&pricing/unlimited/next-schedule-price-change/NextScheduledPriceChange';
import React, { useEffect, useState } from 'react';
import addIcon from '../../../../assets/images/comp_imgs/addnewlayer.png';
import deleteicon from '../../../../assets/images/comp_imgs/delete-icon.jpg';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getDateFormat } from '../../../../utils/moment';
import { errorToast, successToast } from '../../../../utils/toast';

import {
  addDiscountApi,
  getDiscountDetails,
  getMembershipData,
  updateDiscountStatus,
} from '../../../../api/membership';

const discountSchema = yup.object().shape({
  discount_alias_name: yup.string().required('This field is required'),
  membership_id: yup.string().required('This field is required'),
  start_date: yup.string().required('This field is required'),
  end_date: yup.string(),
  indefinite_end_date: yup.string(),
  indefinite_seats: yup.string(),

  total_seats: yup.string().min(0, 'Total seats must be greater than 0'),

  initiation_fees: yup.string('Initiation fees must be a number').when('tier', {
    is: (tier) => {
      return tier?.length === 0;
    },
    then: () =>
      yup
        .string()
        .required('Initiation fees are required when no tiers are specified')
        .min(0, 'Initiation fees must be greater than 0'),
    otherwise: () =>
      yup
        .string()
        .typeError('Initiation fees must be a number')
        .min(0, 'Initiation fees must be greater than 0'),
  }),

  tier: yup.array().of(
    yup.object().shape({
      discount_price: yup
        .number('Discount Price must be a number')
        .typeError('Discount Price must be a number')
        .min(0, 'Discount Price must be greater than 0'),
      no_of_seats: yup
        .number('No of seats must be a number')
        .typeError('No of seats must be a number')
        .min(0, 'No of seats must be greater than 0'),
    }),
  ),
});
const AddDiscount3 = () => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    getValues,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tier: [],
    },
    resolver: yupResolver(discountSchema),
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const end_date = watch('noEndDate');
  const [membershipList, setMembershipList] = useState([]);
  const [membershipId, setMembershipId] = useState('');
  const [skip, setSkip] = useState(1);
  const [noEndDate, setNoEndDate] = useState(false);
  const [indefiniteSeats, setIndefiniteSeats] = useState(false);
  const [pageCount, setPageCount] = useState(2);
  const [discountOrInitiationFees, setDiscountOrInitiationFees] = useState(true);
  const editRoute =
    location?.pathname?.indexOf(`/prices/membership/discounts/edit-discount`) > -1 ? true : false;
  const viewRoute =
    location?.pathname?.indexOf(`/prices/membership/discounts/view-discount`) > -1 ? true : false;
  useEffect(() => {
    if (end_date) {
      setValue('indefinite_end_date', ''); // Reset the end_date field to an empty string
    }
  }, [end_date, setValue]);

  useEffect(() => {
    if (editRoute || viewRoute) {
      getDiscountDataById();
    }
    getMembershipList();
  }, []);

  const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray({
    control,
    name: 'tier',
  });

  const getMembershipList = async () => {
    const response = await getMembershipData();
    if (response?.status_code == 200) {
      setMembershipList(response?.data || []);

      // temporary setting the membership id in the form
      setValue('membership_id', response?.data[0]?._id || '');
    }
  };

  const addDiscount = async (values) => {
    console.log(values.end_date, 'this_is_end_date');
    let payload = {
      discount_alias_name: values.discount_alias_name,
      membership_id: values.membership_id,
      start_date: values.start_date || '',
      end_date: values.end_date === 'Invalid date' ? '' : values.end_date || '',
      total_seats: values.total_seats ? values.total_seats : '',
      initiation_fees: values.initiation_fees ? values.initiation_fees : '',
      indefinite_end_date: noEndDate.toString(),
      indefinite_seats: indefiniteSeats.toString(),
      tier: values.tier?.map((item) => ({
        no_of_seats: item?.no_of_seats,
        discount_price: `${item?.discount_price}`,
      })),
    };
    if (editRoute) {
      payload.discount_id = id;
    }
    let response;
    if (editRoute) {
      response = await updateDiscountStatus(payload);
    } else {
      response = await addDiscountApi(payload);
    }
    if (response?.status_code === 200) {
      successToast(response?.message);
      reset();
      navigate(-1);
    } else {
      errorToast(response?.message);
    }
  };

  const getDiscountDataById = async () => {
    let payload = {
      id,
    };
    const response = await getDiscountDetails(payload);
    if (response?.status_code === 200) {
      setValue('discount_alias_name', response?.data?.discount_alias_name);
      setValue('membership_id', response?.data?.membership_id);
      setValue('start_date', moment(response?.data?.start_date).format('YYYY-MM-DD'));
      setValue(
        'end_date',
        response?.data?.indefinite_end_date === true
          ? ''
          : moment(response?.data?.end_date).format('YYYY-MM-DD'),
      );
      setValue(
        'total_seats',
        response?.data?.indefinite_seats === true ? 0 : response?.data?.total_seats,
      );
      setValue('initiation_fees', response?.data?.initiation_fees);
      setValue('indefinite_end_date', response?.data?.indefinite_end_date);
      setValue('indefinite_seats', response?.data?.indefinite_seats);
      setValue('tier', response?.data?.tier);
    }
  };

  const onSubmit = handleSubmit((values) => {
    addDiscount(values);
  });

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className='flex flex-col justify-center items-center px-2 w-[calc(100vw-200px)]mt-1'>
          {/* <Breadcrumb
              module={"Membership&Pricing"}
              subModule={"Discounts"}
              parent={"Discounts"}
              child={"Add Discounts"}
            /> */}
          <div className='flex flex-col gap-1 w-full  items-start pt-2  rounded-lg hide_scroll  overflow-y-auto'>
            <div className='bg-white flex flex-col  w-full  items-start pt-2  rounded-lg '>
              <>
                <div className='bg-white flex flex-col  w-full  items-start pt-2 px-4 rounded-lg '>
                  <b className='unlimited-heading mb-2 text-2xl '>Add new membership discount</b>
                  <div className='text-neutral-900 text-sm font-normal leading-4 mt-6 '>
                    Enter alias of discount
                  </div>

                  <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                    <input
                      className='  w-[697px] max-w-full outline-none bg-white'
                      placeholder='Pre-order discount'
                      {...register('discount_alias_name')}
                      disabled={viewRoute ? true : false}
                    />
                  </div>
                  <Error
                    error={errors?.discount_alias_name}
                    message={errors?.discount_alias_name?.message}
                  />

                  {/*
                  <div class="w-[360px] text-neutral-900 text-sm font-medium mt-3 font-['Hauora'] leading-[17px]">
                    Discount applies to
                  </div>
                  //* Do not remove the code we need into this in future 
                  
                   <div className='mt-2 basic-select-wrap'>
                    <Form.Select
                      className="form-control "
                      aria-label="Account Status"
                      // {...register("membership_id", {
                      //   onChange: (e) => {
                      //     setMembershipId(e.target.value);
                      //   },
                      // })}
                      // value & defaultValue is ulmited
                      {...register('membership_id')}
                      disabled={true}
                      defaultValue={membershipList[0]?._id}
                      value={membershipList[0]?._id}
                    >
                      <option value=''>Baseline Unlimited price</option>
                      {membershipList?.map((item, index) => (
                        <option value={item?._id}>{item?.name}</option>
                      ))}
                    </Form.Select>
                    <Error error={errors?.membership_id} message={errors?.membership_id?.message} />
                  </div> */}
                  {/* discount period */}
                  <h2 className='common-label'>Discount Period</h2>
                  <div className='items-stretch  flex gap-2 pr-20 max-md:flex-wrap max-md:pr-5 mt-3'>
                    <span className='justify-center items-stretch flex grow basis-[0%] flex-col max-md:max-w-full'>
                      <div className='text-neutral-800 text-sm font-semibold leading-5 max-md:max-w-full'>
                        Start date
                      </div>
                      <div className='flex justify-between gap-2 mt-2 items-start max-md:max-w-full max-md:flex-wrap'>
                        <div className='items-stretch border border-[color:var(--Grey-mid,#C0C0C0)] bg-white flex grow basis-[0%] flex-col justify-center px-3 py-3 rounded-lg border-solid max-md:px-5'>
                          <span className='flex justify-between gap-2 pr-4 items-start'>
                            <div className='text-neutral-900 text-base'>
                              <input
                                className='outline-none'
                                type='date'
                                disabled={viewRoute ? true : false}
                                {...register('start_date')}
                                // max={new Date().toISOString().split("T")[0]}
                              />
                            </div>
                          </span>
                        </div>

                        {/* <span className="text-neutral-400 text-base whitespace-nowrap items-stretch self-stretch border border-[color:var(--Grey-mid,#C0C0C0)] bg-white grow justify-center px-3 py-3 rounded-lg border-solid  ">
                            <input
                              className="w-[90%] outline-none"
                              type="time"
                            />
                          </span> */}
                      </div>
                      <Error error={errors?.start_date} message={errors?.start_date?.message} />
                    </span>
                    <span className='justify-center self-baseline flex grow basis-[0%] flex-col pr-10 max-md:max-w-full max-md:pr-5'>
                      <div className='text-neutral-800 text-sm font-semibold leading-5 max-md:max-w-full'>
                        End date
                      </div>
                      <div className='flex gap-2 mt-2 items-start max-md:max-w-full max-md:flex-wrap'>
                        <div className='items-stretch  border border-[color:var(--Grey-mid,#C0C0C0)] bg-white flex grow basis-[0%] flex-col justify-center px-3 py-3 rounded-lg border-solid'>
                          <span className='flex justify-between gap-2 items-start'>
                            <div className='text-neutral-900 text-base'>
                              <input
                                className='outline-none'
                                type='date'
                                // disabled = {viewRoute ? true: false}
                                {...register('end_date')}
                                disabled={viewRoute ? true : noEndDate ? true : false}
                              />
                            </div>
                          </span>
                        </div>
                        {/* <span className="text-neutral-400 text-base whitespace-nowrap items-stretch self-stretch border border-[color:var(--Grey-mid,#C0C0C0)] bg-white grow justify-center px-3 py-3 rounded-lg border-solid w-[120px] ">
                            <input className="w-[90%]" type="time" />
                          </span> */}
                        <span className='items-stretch self-center flex gap-3 my-auto'>
                          <div className='items-center border border-[color:var(--Grey-800,#141414)] bg-neutral-50 flex aspect-[1.04] flex-col justify-center w-[26px] h-[26px] px-2.5 rounded-md border-solid'>
                            <input
                              className='outline-none'
                              disabled={viewRoute ? true : false}
                              type='checkbox'
                              {...register('indefinite_end_date', {
                                onChange: (e) => {
                                  setNoEndDate(e.target.checked);
                                },
                              })}
                            />
                          </div>
                          <div className='text-neutral-900 text-base leading-6 grow whitespace-nowrap self-start'>
                            no end date
                          </div>
                        </span>
                      </div>
                    </span>
                  </div>

                  {/*  */}

                  {/* hrline */}
                  <div className=' my-4 flex border border-black h-[1px] w-full flex-col' />
                  <div className='tiers-data mt-3'>
                    <div class="text-neutral-900 text-lg flex gap-2 font-bold font-['Hauora']">
                      <div>Discount Structure - Tiered Seats</div>
                      {fields.length === 0 && (
                        <img
                          className='add-next-icon'
                          alt='next-layer'
                          src={addIcon}
                          onClick={() => {
                            insert(0, {
                              discount_price: 0,
                              no_of_seats: 0,
                            });
                          }}
                        />
                      )}
                    </div>
                    {fields?.map((field, index) => (
                      <>
                        <div key={field.id} className='flex items-center mt-4'>
                          <div className='mr-4'>
                            <h4 className='font-bold text-[18px]'>Tier {fields.length - index}</h4>
                          </div>
                          <div className='flex gap-4 mr-4'>
                            <div className='price1'>
                              <div className='price2'> Discount Price</div>
                              <div class=' flex-col justify-start items-start gap-1 inline-flex'>
                                <div class='self-stretch  px-3 py-3 bg-white rounded-lg border border-stone-300 flex-col justify-start items-start gap-2.5 flex'>
                                  <div class='self-stretch  justify-start items-center gap-2 inline-flex'>
                                    <div class='justify-start items-center gap-1 flex'>
                                      <div class="text-black text-opacity-20 text-base font-normal font-['Hauora']">
                                        $
                                      </div>
                                    </div>
                                    <div className='text'>
                                      <input
                                        name='effective-time'
                                        placeholder='00:00'
                                        className='text outline-none'
                                        disabled={viewRoute ? true : false}
                                        type='number'
                                        {...register(`tier.${index}.discount_price`)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <Error error={errors?.['tier']?.[index]?.['discount_price']} />
                              </div>
                            </div>
                            <div className='price3'>
                              <div className='price2'>No.of seats</div>
                              <div class=' flex-col justify-start items-start gap-1 inline-flex'>
                                <div class='self-stretch  px-3 py-3 bg-white rounded-lg border border-stone-300 flex-col justify-start items-start gap-2.5 flex'>
                                  <div class='self-stretch  justify-start items-center gap-2 inline-flex'>
                                    <div class='justify-start items-center gap-1 flex'></div>
                                    <div className='text'>
                                      <input
                                        name='effective-time'
                                        placeholder=''
                                        className='text outline-none'
                                        disabled={viewRoute ? true : false}
                                        type='number'
                                        {...register(`tier.${index}.no_of_seats`)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <Error error={errors?.['tier']?.[index]?.['no_of_seats']} />
                              </div>
                              {/* <span className='ml-3'>No. of seats claimed - {field.used_seats}</span> */}
                            </div>
                          </div>
                          <div className='flex gap-4 justify-end min-w-[100px]'>
                            {index === fields?.length - 1 && (
                              <div>
                                {!viewRoute && (
                                  <img
                                    className='add-next-icon'
                                    alt='next-layer'
                                    src={addIcon}
                                    onClick={() => {
                                      insert(0, {
                                        discount_price: 0,
                                        no_of_seats: 0,
                                      });
                                    }}
                                  />
                                )}
                              </div>
                            )}
                            {/* {fields?.length > 1 && ( */}
                            <div>
                              <img
                                className='delete-next-icon'
                                alt='next-layer'
                                src={deleteicon}
                                onClick={() => {
                                  remove(index);
                                }}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  {/* hr line */}
                  <div className=' my-4 flex border border-black h-[1px] w-full flex-col' />
                  <div class="text-neutral-900 text-lg font-bold font-['Hauora'] mt-3">
                    Discount Structure - Initiation Fee
                  </div>
                  <div></div>
                  <div class='justify-start items-center gap-3 inline-flex my-3'>
                    <div class="text-neutral-800 text-sm font-semibold font-['Hauora'] leading-tight">
                      Discounted Initiation Fee
                    </div>
                    <div class=' flex-col justify-start items-start gap-1 inline-flex'>
                      <div class='self-stretch  px-3 py-3 bg-white rounded-lg border border-stone-300 flex-col justify-start items-start gap-2.5 flex'>
                        <div class='self-stretch  justify-start items-center gap-2 inline-flex'>
                          <div class='justify-start items-center gap-1 flex'>
                            <div class="text-black text-opacity-20 text-base font-normal font-['Hauora']">
                              $
                            </div>
                          </div>
                          <div className='text'>
                            <input
                              name='effective-time'
                              disabled={viewRoute ? true : false}
                              placeholder='00:00'
                              className='text outline-none'
                              type='number'
                              {...register('initiation_fees')}
                            />
                          </div>
                        </div>
                      </div>
                      <Error error={errors?.initiation_fees} />
                    </div>
                    <div class="text-neutral-800 text-sm font-semibold font-['Hauora'] leading-tight">
                      No. of seats avaiable
                    </div>
                    <div className='flex gap-1 '>
                      <div class=' flex-col justify-start items-start gap-1 inline-flex'>
                        <div class='self-stretch  px-3 py-3 bg-white rounded-lg border border-stone-300 flex-col justify-start items-start gap-2.5 flex'>
                          <div class='self-stretch  justify-start items-center gap-2 inline-flex'>
                            <div class='justify-start items-center gap-1 flex'></div>
                            <div className='text'>
                              <input
                                name=''
                                placeholder='Enter Seats'
                                className='text outline-none'
                                type='number'
                                min='0'
                                disabled={viewRoute ? true : indefiniteSeats ? true : false}
                                {...register('total_seats')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='items-center border border-[color:var(--Grey-800,#141414)] bg-neutral-50 flex aspect-[1.04] flex-col justify-center w-[26px] h-[26px] px-2.5 rounded-md border-solid self-center'>
                        <input
                          type='checkbox'
                          disabled={viewRoute ? true : false}
                          {...register('indefinite_seats', {
                            onChange: (e) => {
                              setIndefiniteSeats(e.target.checked);
                            },
                          })}
                        />
                      </div>
                      <div className='text-neutral-900 text-base leading-6 grow whitespace-nowrap self-center '>
                        Indefinite seats
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
            {/* footer-button */}

            {!viewRoute && (
              <div className=' bg-white flex flex-col gap-1 w-full  items-end   rounded-lg mt-4   '>
                <div className='flex flex-row py-3 justify-center items-center px-4 gap-4 '>
                  <div className=' bg-white flex flex-col gap-1 w-full  items-start  rounded-lg   '>
                    <div
                      className='text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white text-black px-16 py-3 rounded-lg border-solid   cursor-pointer'
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </div>
                  </div>
                  {/* <div className=' bg-white flex flex-col gap-1 w-full  items-start  rounded-lg   '>
                    <div className='text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white text-black px-16 py-3 rounded-lg border-solid   cursor-pointer'>
                      Save Draft
                    </div>
                  </div> */}

                  <div className='  flex flex-col gap-1 w-full  items-start rounded-lg   '>
                    <button
                      typr='submit'
                      className='text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch  bg-[#8C2323] text-white px-16 py-3 rounded-lg border-solid  cursor-pointer'
                    >
                      {'Apply Discount'}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddDiscount3;
