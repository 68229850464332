import ContainerHeading from '../../../../components/common/container-heading/ContainerHeading';
import React, { useEffect, useState } from 'react';
import { Card, Typography, Tooltip } from '@material-tailwind/react';
import CustomPagination from '../../../../components/common/CustomPagination';
import { useNavigate } from 'react-router-dom';
import {
  deleteLegal,
  updateUserStatus,
  viewAllLegal,
  viewLegal,
} from '../../../../api/content-mgmt/legal';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { getDateFormat } from '../../../../helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import Toggle from 'react-toggle';
import PopUpLayout from '../../../dashboard/user-mgmt/PopUpLayout';
import { generateConfirm } from '../../../../utils/reactConfirmAlert';
import NoDataFound from '../../../../components/common/NoDataFound';
import { errorToast, successToast } from '../../../../utils/toast';
import { useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Stack } from '@mui/material';
import { reOrder } from '../../../../api/categories/categories';
export const TABLE_HEAD = ['Legal Section Title', 'Author', 'Date Published ', 'Status', 'Action'];

const LegalListing = () => {
  const navigate = useNavigate();
  // values from redux
  const { loader } = useSelector((state) => state.loader);

  // local state
  const [legalList, setLegalList] = useState([]);
  const [open, setOpen] = useState(false);
  const [uniqueLegalDetails, setUniqueLegalDetails] = useState();
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(5);
  const [pageCount, setPageCount] = useState();

  // sideEffect
  useEffect(() => {
    getLegalList();
  }, [skip]);

  // legal detail on id
  const legalDetail = async (id) => {
    setOpen(true);
    let response = await viewLegal(id);
    setUniqueLegalDetails(response?.data);
  };

  // status toggle function
  const onActive = async (id, status) => {
    let payload = {
      id,
      status: status === 'active' ? 'inactive' : 'active',
    };
    await updateUserStatus(payload);
    getLegalList();
  };

  // delete legal function
  const handleLegalDelete = async (id) => {
    generateConfirm('Are you sure', 'You want to delete', id, async () => {
      let payload = {
        id,
      };
      const response = await deleteLegal(payload);
      if (response?.status_code === 200) {
        successToast(response?.message);
      } else if (response?.status_code === 400) {
        errorToast(response?.message);
      }
      getLegalList();
    });
  };

  // get all legal list
  const getLegalList = async () => {
    let payload = {
      skip: skip,
      limit,
    };
    let response = await viewAllLegal(payload);
    if (response?.status_code === 200) {
      setLegalList(response?.data);
      let count = Math.ceil(response?.totalItems / limit);
      setPageCount(count);
    }
  };
  return (
    <>
      <div className='flex flex-col justify-center items-center'>
        <div className=' w-full  hide_scroll  overflow-y-auto rounded-lg'>
          <ContainerHeading
            // className={"rounded-t-lg"}
            title={'Legal management'}
            button='Legal'
            redirect={'/content/page-mgmt/legal/add-legal'}
          />

          <div className=' bg-white flex flex-col gap-1 w-full  items-start pt-2   '>
            {/* ṭable */}
            {/* lisitng table */}
            {legalList?.length ? (
              <>
                <div
                  className=' !z-5 
              overflow-x-scroll hide_scroll rounded-none 
             w-full
               '
                >
                  <BaseTableBody
                    legalList={legalList}
                    setLegalList={setLegalList}
                    onActive={onActive}
                    legalDetail={legalDetail}
                    handleLegalDelete={handleLegalDelete}
                  />
                </div>
              </>
            ) : (
              !loader && <NoDataFound />
            )}
            {/* pagination */}
            {pageCount > 1 && (
              <>
                <div className='bg-white flex flex-col gap-1 w-full  items-end pt-2 px-2   rounded-lg   '>
                  <Card className='py-2 rounded-none   '>
                    <span className='bg-[#E6E6E6] py-3  px-2 w-auto items-end ml-3  rounded-xl '>
                      <CustomPagination page={skip} setPage={setSkip} count={pageCount} />
                    </span>
                  </Card>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* view legal modal */}
      {open && (
        <PopUpLayout setShowModal={setOpen}>
          <>
            <div className='bg-white mt-[98px] w-[700px]  p-5 overflow-y-auto hide_scroll rounded-xl '>
              <div className='flex justify-between '>
                <h1 className='taxt-[#303637] text-[20px] font-semibold '>View Legal</h1>
                <svg
                  className='cursor-pointer'
                  onClick={() => {
                    setOpen(false);
                  }}
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    d='M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z'
                    fill='#212121'
                  />
                </svg>
              </div>
              {/* heading */}
              <div className='flex flex-row gap-4'>
                <div className='text-neutral-900 text-base font-bold leading-4 mt-6 '>Title:</div>
                <div className='text-neutral-900 text-sm font-medium leading-4 mt-6 '>
                  {uniqueLegalDetails?.legal_title}
                </div>
              </div>
              {/* body */}
              {uniqueLegalDetails?.legalSubtitles?.map((item) => {
                return (
                  <>
                    <div
                      key={item?.id}
                      className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'
                    >
                      {/* sub-title */}
                      <>
                        <div className='flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full'>
                          <div>Subtitle Heading </div>
                        </div>
                        <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                          <p className='text-balance'>{item?.legalSubtitle}</p>
                        </div>
                      </>
                      {/* content */}
                      <>
                        <div className='text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full'>
                          Subtitle Content
                        </div>
                        <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                          <p className='  w-[697px] max-w-full outline-none bg-white txt-wrap-balance'>
                            {item?.legalContent}
                          </p>
                        </div>
                      </>
                    </div>
                  </>
                );
              })}

              {/*  */}
            </div>
          </>
        </PopUpLayout>
      )}
    </>
  );
};

const BaseTableBody = ({ legalList, setLegalList, onActive, legalDetail, handleLegalDelete }) => {
  console.log(legalList, 'legalList');
  const navigate = useNavigate();
  const onDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) return;
    const updatedItems = [...legalList];
    const [removed] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, removed);
    const _updatedData = updatedItems.map((ele, i) => {
      return {
        ...ele,
        order: i,
      };
    });
    const _updatedDataId = _updatedData.map((ele) => {
      return { _id: ele._id };
    });
    setLegalList(_updatedData);
    await reOrder({
      collectionName: 'legal',
      items: _updatedDataId,
    });
  };
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead className=' px-3 text-start py-3  bg-[#d3d3d3]'>
          <TableRow>
            {TABLE_HEAD.map((head) => (
              <TableCell className='font-semibold leading-none '>{head}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable-sub-category' direction='vertical'>
            {(provided) => {
              return (
                <TableBody className='' {...provided.droppableProps} ref={provided.innerRef}>
                  {legalList.map(({ _id, legalTitle, createdAt, status }, index) => {
                    const isLast = index === legalList.length - 1;
                    const classes = isLast
                      ? 'py-2 px-3 max-w-[300px]'
                      : 'py-2 px-3 max-w-[300px] border-b border-blue-gray-50';

                    return (
                      <Draggable
                        disableInteractiveElementBlocking
                        key={_id}
                        draggableId={`${_id}-sub-category`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              ...provided.draggableProps.style,
                              bgcolor: snapshot.isDragging
                                ? 'background.paper'
                                : 'background.default',
                              zIndex: 2,
                              width: '100%',
                            }}
                          >
                            <TableCell className={classes + ' cursor-pointer'}>
                              <Typography
                                variant='small'
                                color='blue-gray'
                                className='font-normal overflow-hidden whitespace-nowrap text-ellipsis'
                              >
                                {legalTitle}
                              </Typography>
                            </TableCell>
                            <TableCell className={`${classes} flex gap-1`}>
                              <Typography
                                variant='small'
                                color='blue-gray'
                                className={`font-normal bg-[#F5F5F5] px-3 py-1 rounded-[100px] text-center`}
                              >
                                {'Admin'}
                              </Typography>
                            </TableCell>

                            <TableCell className={classes}>
                              <Typography
                                variant='small'
                                color='blue-gray'
                                className='font-normal '
                              >
                                {getDateFormat(createdAt)}
                              </Typography>
                            </TableCell>
                            <TableCell className={classes}>
                              <Typography
                                variant='small'
                                color='blue-gray'
                                className={`font-normal ${
                                  status === 'Scheduled'
                                    ? 'bg-[#90CAF9]'
                                    : status === 'Published'
                                    ? 'bg-[#B9F6CA]'
                                    : status === 'Draft'
                                    ? 'bg-[#FFF8E1]'
                                    : status === 'active'
                                    ? 'bg-[#B9F6CA]'
                                    : 'bg-[#EAEAEA]'
                                }  px-3 py-1 rounded-[100px] text-center`}
                              >
                                {status}
                              </Typography>
                            </TableCell>
                            <TableCell className={classes}>
                              <div className='flex flex-row gap-2'>
                                <div class='action-toggle'>
                                  <Toggle
                                    checked={status === 'active' ? 'active' : ''}
                                    icons={false}
                                    className={status === 'inactive' ? 'isDisable' : 'active'}
                                    onChange={() => onActive(_id, status)}
                                  />
                                </div>
                                {/* It should be use in future */}
                                {/* <div className='action-icon'>
                                  <Tooltip content={'View'}>
                                    <FontAwesomeIcon
                                      onClick={() => {
                                        legalDetail(_id);
                                      }}
                                      className='edit-btn'
                                      icon={faEye}
                                    />
                                  </Tooltip>
                                </div> */}
                                <div className='action-icon'>
                                  <Tooltip content={'Edit'}>
                                    <FontAwesomeIcon
                                      onClick={() => {
                                        navigate(`/content/page-mgmt/legal/edit-legal/${_id}`);
                                      }}
                                      className='edit-btn'
                                      icon={faPen}
                                    />
                                  </Tooltip>
                                </div>
                                <div className='action-icon'>
                                  <Tooltip content={'Delete'}>
                                    <FontAwesomeIcon
                                      onClick={() => {
                                        handleLegalDelete(_id);
                                      }}
                                      className='edit-btn'
                                      icon={faTrash}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            </TableCell>
                            {provided.placeholder}
                          </TableRow>
                        )}
                      </Draggable>
                    );
                  })}
                </TableBody>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};

export default LegalListing;
