import React, { useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import { getAllFlights } from "../../../../../../api/users";
import { useLocation } from "react-router-dom";
import NoDataFound from "../../../../../../components/common/NoDataFound";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

const TABLE_HEAD = [
  "Flight name",
  "From City",
  "To City",
  "Booking Status",
  "Flight TakeOff TIme",
  "Landing Time",
];

const FlightDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const [count, setCount] = useState(0);
  const [flightsList, setFlightsList] = useState([]);
  const [activeTab, setActiveTab] = React.useState("Current Flights");
  const editRoute =
    location?.pathname?.indexOf(
      `/dashboard/user-management/user-list/edit-user-list`
    ) > -1
      ? true
      : false;

  const viewRoute =
    location?.pathname?.indexOf(
      `/dashboard/user-management/user-list/view-user-list`
    ) > -1
      ? true
      : false;

  useEffect(() => {
    if (editRoute || viewRoute) {
      getFligtHistory();
    }
  }, []);

  const getFligtHistory = async () => {
    let payload = {
      id,
      skip: 1,
      limit: 10,
      search: "",
    };
    const response = await getAllFlights(payload);
    if (response?.status_code == 200) {
      setFlightsList(response?.data || []);
      setCount(parseInt(response?.totalItems / payload.limit) || 0);
    }
  };

  const hFlight = [{
    "_id": "65ef04803f022cab0d20c2c9",
    "booking_status": "canceled",
    "Total_pet_price_with_gst": "",
    "status": "active",
    "createdAt": "2024-03-11T13:17:52.305Z",
    "flight_name": "flight2",
    "route_name": "route 2",
    "flight_takeoff_date": "2024-04-02T00:00:00.000Z",
    "takeoff_time": "22:00",
    "landing_time": "23:00",
    "pilot_name": "anamika gautam",
    "copilot_name": "65a4ef9af7bf7d2eece32003",
    "pilot_id": "6581764e7c6dd88dcaef341a",
    "fromCity_name": "Brisbane",
    "fromCity_airport_abbreviation": "ACF",
    "fromCity_lat": "-27.470125",
    "fromCity_long": "153.021072",
    "toCity_name": "Melbourne",
    "toCity_airport_abbreviation": "MEB",
    "toCity_lat": "-37.81400000",
    "toCity_long": "144.96332000"
}]
  const data = [
    {
      label: "Current Flights",
      value: "Current Flights",
      tableData : flightsList
     
    },
    {
      label: "Historic Flights",
      value: "Historic Flights",
      tableData: hFlight
      
    },
  ];
  console.log(flightsList,"flightlist")
  return (

    <>

      {editRoute || viewRoute ? (

        <>
         <Tabs value={activeTab}>
      <TabsHeader 
      // className="w-[500px]"
      className="rounded-none border-b border-blue-gray-50 bg-transparent p-0 w-2/5"
        indicatorProps={{
          className:
            "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
        }}
      >
        {data.map(({ label, value }) => (
          <Tab key={value} value={value}
          onClick={() => setActiveTab(value)}
          className={activeTab === value ? "text-gray-900  text-sm" : "text-sm"}

          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, tableData }) => (
          <TabPanel key={value} value={value}>
              <table className="w-full min-w-max  table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-b border-blue-gray-100   px-8 text-start py-8"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
              {/* <Button type="submit" onClick={()=>addPet()} className='bg-dark text-white p-2 px-4'>Add Pet</Button> */}
            </thead>
            <tbody>
              {tableData?.map((item, index) => {
                const isLast = index === flightsList.length - 1;
                const classes = isLast
                  ? "py-6 px-8 text-center "
                  : "py-6 px-8 border-b  text-center   border-blue-gray-50";

                return (
                  <tr key={index}>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal cursor-pointer"
                      >
                        {item?.flight_name || "N/A"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {item?.fromCity_name || "N/A"}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        // as="a"
                        // href="#"
                        variant="small"
                        color="blue-gray"
                        className="font-medium"
                      >
                        {item?.toCity_name || "N/A"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal capitalize"
                      >
                        {item?.booking_status || "N/A"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {item?.takeoff_time || "N/A"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal px-3 py-1 rounded-[100px] text-center"
                      >
                        {item?.landing_time || "N/A"}
                      </Typography>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
       
          {flightsList.length == 0 && <NoDataFound />}
        </>
      ) : (
        <table className="w-full min-w-max  table-auto text-left text-pet-data">
          You can see user's flight history while editing this user
        </table>
      )}
    </>
  );
};

export default FlightDetails;
