import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { fetchCategoriesList, reOrder } from '../../../../../api/categories/categories';
import { Stack, Typography } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import useFetchCategories from '../../../../../hooks/api/useFetchCategories';

const FaqCategory = () => {
  //   const [categories, setCategories] = useState(null);
  const { categories, isLoading, setCategories } = useFetchCategories();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const onDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) return;
    const updatedItems = [...categories];
    const [removed] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, removed);
    const _updatedData = updatedItems.map((ele, i) => {
      return {
        ...ele,
        order: i,
      };
    });
    const _updatedDataId = _updatedData.map((ele) => {
      return { _id: ele._id };
    });
    await reOrder({
      collectionName: 'category',
      items: _updatedDataId,
    });
    setCategories(_updatedData);
  };

  return (
    <Stack className='bg-white p-2 rounded-md relative'>
      <Typography variant='h5' fontWeight={'bold'} gutterBottom>
        FAQ Categories
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Index</TableCell>
              <TableCell align='left'>Category</TableCell>
            </TableRow>
          </TableHead>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable-sub-category' direction='vertical'>
              {(provided) => {
                return (
                  <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                    {categories.map((ele, index) => {
                      return (
                        <Draggable
                          disableInteractiveElementBlocking
                          key={ele._id}
                          draggableId={`${ele._id}-sub-category`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{
                                ...provided.draggableProps.style,
                                bgcolor: snapshot.isDragging
                                  ? 'background.paper'
                                  : 'background.default',
                                zIndex: 2,
                              }}
                            >
                              <TableCell style={{ width: '50%' }}>{index + 1}</TableCell>
                              <TableCell
                                key={ele?._id}
                                style={{ width: '50%' }}
                                m={2}
                                p={2}
                                className='rounded-md cursor-move select-none'
                                display={'flex'}
                                width={200}
                                justifyContent={'center'}
                                alignItems={'center'}
                              >
                                {provided.placeholder}

                                {ele?.name}
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      );
                    })}
                  </TableBody>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </Stack>
  );
};


export default FaqCategory;
