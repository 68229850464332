import React from "react";

const AccountStatus = () => {
  return <div>AccountStatus
    <div className="account-setting-wrap">
      <div className="space-y-8 mt-8 pb-10">
        <h2 className="text-[20px] font-bold text-[#303637]">
          Password management
        </h2>
        <div className="w-full">
          <label htmlFor="">Old password</label>
          <input
            className="form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] "
            type="password"
            placeholder="New password"
          />
        </div>
        <h2 className="text-[20px] font-bold text-[#303637]">
          New password
        </h2>
        <div className="w-full">
          <label htmlFor="">New password</label>
          <input
            className="form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] "
            type="password"
            placeholder="New password"
          />
        </div>
        <div className="w-full">
          <label htmlFor="">Repeat new password</label>
          <input
            className="form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] "
            type="password"
            placeholder="Repeat new password"
          />
        </div>
        <div className=" bg-white flex flex-col gap-1 w-full  items-end  rounded-lg mb-2  ">
          <button
            type="submit"
            className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-black text-white px-16 py-3 rounded-lg border-solid  ml-[18px] cursor-pointer"
          >
            Change Password
          </button>
        </div>
      </div>
    </div>

  </div>;
};

export default AccountStatus;
