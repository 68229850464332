// imports
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import ContainerHeading from "../../../../components/common/container-heading/ContainerHeading";
import SelectWithChips from "../../../dashboard/user-mgmt/SelectWithChips";
import plus_icon from "../../../../assets/images/icons/plus_icon.png";
import delete_icon from "../../../../assets/images/icons/delete_icon.png";
import grid_icon from "../../../../assets/images/icons/grid_icon.png";
import copy_icon from "../../../../assets/images/icons/copy_icon.png";
import up_arrow_icon from "../../../../assets/images/icons/up_arrow_icon.png";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadFile from "../../../../components/common/uploads/UploadFile";
import {
  editHomeData,
  fetchHomeData,
} from "../../../../api/content-mgmt/homepage";
import Error from "../../../../components/common/Error";
import UploadVideo from "../../../../components/common/uploads/UploadVideo";
import CustomSwitch from "../../../../components/common/CustomSwitch";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import { errorToast, successToast } from "../../../../utils/toast";
import { useSelector } from "react-redux";

// schema
const homepageSchema = yup.object().shape({
  frame1Logo: yup.mixed().required("File is required"),
  frame1Video: yup.mixed().required("File is required"),
  frame2Text: yup
    .string()
    .required("Text is required")
    .max(150, "Max character limit reached"),
  frame2Video: yup.mixed().required("File is required"),
  frame2Card: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .required("Required")
        .max(30, "Max character limit reached"),
      description: yup
        .string()
        .required("Required")
        .max(150, "Max character limit reached"),
    })
  ),
  frame3Logo: yup.mixed().required("File is required"),
  frame3Video: yup.mixed().required("File is required"),
  frame4Text: yup.string().max(150, "Max character limit reached"),
  frame4Card: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .required("Required")
        .max(30, "Max character limit reached"),
      description: yup
        .string()
        .required("Required")
        .max(150, "Max character limit reached"),
    })
  ),
  frame5Video: yup.mixed().required("File is required"),
  frame6Card: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .required("Required")
        .max(30, "Max character limit reached"),
      description: yup
        .string()
        .required("Required")
        .max(30, "Max character limit reached"),
    })
  ),
  frame7Video: yup.mixed().required("File is required"),
  frame7Text: yup
    .string()
    .required("Required")
    .max(150, "Max character limit reached"),
  frame8Text: yup
    .string()
    .required("Required")
    .max(150, "Max character limit reached"),
});

// main parent component
const HomePage = () => {
  // redux statw
  const { loader } = useSelector((state) => state.loader);
  // local state
  const [isEditable, setIsEditable] = useState(false);
  const [homePageData, setHomePageData] = useState([]);

  // usefrom functions
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    // defaultValues: {
    //   frame2Card: [{ title: "", description: "" }],
    //   frame4Card: [{ title: "", description: "" }],
    //   frame6Card: [{ title: "", description: "" }],
    // },
    resolver: yupResolver(homepageSchema),
  });

  // console
  // console.log(errors, "errors");
  // console.log(homePageData, "homedataa");

  // frame2 addcard array
  const {
    fields: frame2Card,
    append: frame2CardAppend,
    prepend: frame2CardPrepend,
    remove: frame2CardRemove,
    swap: frame2CardSwap,
    move: frame2CardMove,
    insert: frame2CardInsert,
    update: frame2CardUpdate,
  } = useFieldArray({
    control,
    name: "frame2Card",
  });

  // frame4 addcard array
  const {
    fields: frame4Card,
    append: frame4CardAppend,
    prepend: frame4CardPrepend,
    remove: frame4CardRemove,
    swap: frame4CardSwap,
    move: frame4CardMove,
    insert: frame4CardInsert,
    update: frame4CardUpdate,
  } = useFieldArray({
    control,
    name: "frame4Card",
  });

  // frame6 addcard array
  const {
    fields: frame6Card,
    append: frame6CardAppend,
    prepend: frame6CardPrepend,
    remove: frame6CardRemove,
    swap: frame6CardSwap,
    move: frame6CardMove,
    insert: frame6CardInsert,
    update: frame6CardUpdate,
  } = useFieldArray({
    control,
    name: "frame6Card",
  });

  // get data on load
  useEffect(() => {
    getHomeData();
  }, []);

  // default value on frame2Card on network call
  useEffect(() => {
    homePageData?.frame2Card?.forEach((field, index) => {
      let obj = {};
      Object.keys(field).forEach((key) => {
        if (key !== "_id") {
          obj[key] = field[key];
        }
      });
      frame2CardUpdate(index, obj);
    });
  }, [homePageData, frame2CardUpdate]);

  // default value on frame4Card  on network call
  useEffect(() => {
    homePageData?.frame4Card?.forEach((field, index) => {
      let obj = {};
      Object.keys(field).forEach((key) => {
        if (key !== "_id") {
          obj[key] = field[key];
        }
      });
      frame4CardUpdate(index, obj);
    });
  }, [homePageData, frame4CardUpdate]);

  // default value on frame6Card  on network call
  useEffect(() => {
    homePageData?.frame6Card?.forEach((field, index) => {
      let obj = {};
      Object.keys(field).forEach((key) => {
        if (key !== "_id") {
          obj[key] = field[key];
        }
      });
      frame6CardUpdate(index, obj);
    });
  }, [homePageData, frame6CardUpdate]);

  //refs
  const frame1LogoRef = useRef(null);
  const frame1VideoRef = useRef(null);
  const frame2VideoRef = useRef(null);
  const frame5VideoRef = useRef(null);
  const frame7VideoRef = useRef(null);

  // function to upload files
  const handleFileUpload = (ref, input_id) => {
    if (ref === "frame1LogoRef" && frame1LogoRef.current) {
      frame1LogoRef.current.children[input_id].click();
    } else if (ref === "frame1VideoRef" && frame1VideoRef.current) {
      frame1VideoRef.current.children[input_id].click();
    } else if (ref === "frame2VideoRef" && frame2VideoRef.current) {
      frame2VideoRef.current.children[input_id].click();
    } else if (ref === "frame5VideoRef" && frame5VideoRef.current) {
      frame5VideoRef.current.children[input_id].click();
    } else if (ref === "frame7VideoRef" && frame7VideoRef.current) {
      frame7VideoRef.current.children[input_id].click();
    }
  };

  // newtwork call to updata data
  const editHomePageDetails = async (values) => {
    let formData = new FormData();
    Object.keys(values).forEach((key) => {
      // files
      if (key === "frame1Logo") {
        formData.append(
          "frame1Logo",
          typeof values.frame1Logo == "object"
            ? values.frame1Logo[0]
            : homePageData?.frame1Logo
        );
      } else if (key === "frame1Video") {
        formData.append(
          "frame1Video",
          typeof values.frame1Video == "object"
            ? values.frame1Video[0]
            : homePageData?.frame1Video
        );
      } else if (key === "frame2Video") {
        formData.append(
          "frame2Video",
          typeof values.frame2Video == "object"
            ? values.frame2Video[0]
            : homePageData?.frame2Video
        );
      } else if (key === "frame3Logo") {
        formData.append("frame3Logo", homePageData?.frame3Logo);
      } else if (key === "frame3Video") {
        formData.append("frame3Video", homePageData?.frame3Video);
      } else if (key === "frame5Video") {
        formData.append(
          "frame5Video",
          typeof values.frame5Video == "object"
            ? values.frame5Video[0]
            : homePageData?.frame5Video
        );
      } else if (key === "frame7Video") {
        formData.append(
          "frame7Video",
          typeof values.frame7Video == "object"
            ? values.frame7Video[0]
            : homePageData?.frame7Video
        );
      }

      //frames
      else if (key === "frame2Card") {
        formData.append("frame2Card", JSON.stringify(values[key]));
      } else if (key === "frame4Card") {
        formData.append("frame4Card", JSON.stringify(values[key]));
      } else if (key === "frame6Card") {
        formData.append("frame6Card", JSON.stringify(values[key]));
      }
      // string
      else {
        formData.append(key, values[key]);
      }
    });
    const response = await editHomeData(formData);
    if (response.status_code === 200) {
      successToast(response?.message);
      setIsEditable(false);
      getHomeData();
    } else if (response.status_code === 400) {
      errorToast(response?.message);
    }
  };

  // network call to get data
  const getHomeData = async () => {
    let response = await fetchHomeData();
    // setdefault values
    if (response?.status_code === 200) {
      setValue("frame1Logo", response?.data?.frame1Logo);
      setValue("frame1Video", response?.data?.frame1Video);
      setValue("frame2Text", response?.data?.frame2Text);
      setValue("frame2Video", response?.data?.frame2Video);
      setValue("frame3Logo", response?.data?.frame3Logo);
      setValue("frame3Video", response?.data?.frame3Video);
      setValue("frame4Text", response?.data?.frame4Text);
      setValue("frame5Video", response?.data?.frame5Video);
      setValue("frame7Video", response?.data?.frame7Video);
      setValue("frame7Text", response?.data?.frame7Text);
      setValue("frame8Text", response?.data?.frame8Text);
    }
    setHomePageData(response?.data);
  };

  //form submit handler
  const onSubmit = handleSubmit((values) => {
    if (isEditable) {
      editHomePageDetails(values);
    } else {
      errorToast("Not editable");
    }
  });

  return (
    <>
        <div className="flex flex-col justify-center items-center">
          <div className=" w-full  hide_scroll  overflow-y-auto">
            {/* <Breadcrumb
              module={"Content Management"}
              subModule={"Page management"}
              parent={"Home page"}
              child={""}
            /> */}
            <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg   ">
              {/* header */}
              <ContainerHeading
                title={"Home Page"}
                chipName={"Published"}
                bgColor="#B9F6CA"
              />
              {/* edit buttons */}
              <div className="flex flex-row gap-2 justify-center mx-4 items-center">
                {/* <div
                className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white px-9 py-3 rounded-lg border-solid  ml-[18px] cursor-pointer"
                onClick={() => {
                  setIsEditable(!isEditable);
                  successToast(
                    isEditable
                      ? "Content is now non editable"
                      : "Content is now  editable"
                  );
                }}
              >
                Edit
              </div> */}

                {/*edit/non-edit switch  */}
                <div className="w-[100px] h-[100px]">
                  <CustomSwitch
                    value={isEditable}
                    setValue={setIsEditable}
                    toast={
                      isEditable
                        ? "Content is now non editable"
                        : "Content is now  editable"
                    }
                  />
                </div>
              </div>

              {/* dropdown chips */}

              {/* <div className="w-2/4 my-4 ml-[18px]">
              <h2 className="text-[#141414] text-[14px] font-bold">
                Navigation Locations
              </h2>

              <SelectWithChips />
            </div> */}
              {/* form */}
              {!loader && (
                <form onSubmit={onSubmit}>
                  {/* frame wrapper */}
                  <>
                    <div
                      className="items-stretch self-stretch bg-white flex flex-col pb-4 px-4 border-solid 
                  w-[calc(100vw-400px)] "
                    >
                      <div
                        className=" border-2 border-[#97A1A9]  w-full py-5 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mr-2.5
              "
                      >
                        <div className="items-start flex gap-4 mx-4 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mr-2.5">
                          <div className="text-neutral-800 text-lg font-bold self-stretch grow max-md:max-w-full">
                            Home page A-Frame
                          </div>
                          {/* <img
                        loading="lazy"
                        src={plus_icon}
                        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
                        alt="add-icon"
                      />
                      <img
                        loading="lazy"
                        src={copy_icon}
                        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
                        alt="copy-icon"
                      />
                      <img
                        loading="lazy"
                        src={grid_icon}
                        className="aspect-square object-contain object-center w-[22px] overflow-hidden self-center shrink-0 
                max-w-full my-auto"
                        alt="grid-icon"
                      />
                     
                      <img
                        loading="lazy"
                        src={delete_icon}
                        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
                        alt="delete-icon"
                      />
                      <img
                        loading="lazy"
                        src={up_arrow_icon}
                        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
                        alt="arrow-icon"
                      /> */}
                        </div>
                      </div>
                      <div className="border-t-[color:var(--Grey-Grey,#97A1A9)] border-2 border-[#97A1A9] flex w-full flex-col  pl-4 pr-20 pt-2.5 pb-12 border-t border-solid items-start max-md:max-w-full max-md:pr-5">
                        {/* frame 1 */}
                        <>
                          <div className="text-neutral-800 text-lg font-bold mt-4 max-md:max-w-full max-md:mt-10">
                            Frame 1
                          </div>
                          <>
                            <div className="items-start border-b-[color:var(--Grey-10,#FFF)] flex w-[697px] max-w-full flex-col mt-6 pb-6 border-b border-solid">
                              <div className="text-neutral-800 text-base font-semibold leading-6 self-stretch max-md:max-w-full">
                                Logo
                              </div>
                              <div
                                loading="lazy"
                                className="aspect-[0.86] object-contain object-center w-[156px] overflow-hidden max-w-full mt-4 self-start"
                              >
                                {/* <video id="my-video" autoplay>
                              <source
                                src={homePageData?.frame2Video}
                                type="video/mp4"
                              />
                            </video> */}

                                <div ref={frame1LogoRef}>
                                  <UploadFile
                                    className=""
                                    inputId={`frame1Logo`}
                                    imgId={`logo_id`}
                                    src={homePageData?.frame1Logo}
                                    name={"frame1Logo"}
                                    setValue={setValue}
                                    register={register}
                                    type="upload"
                                  />
                                </div>
                                <Error
                                  error={errors?.frame1Logo}
                                  message={errors?.frame1Logo?.message}
                                />
                              </div>
                              <div
                                className={`text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-center self-stretch border border-black bg-white mt-4 px-16 py-3 rounded-lg border-solid max-md:max-w-full max-md:px-5  ${
                                  isEditable
                                    ? "cursor-pointer"
                                    : "cursor-not-allowed"
                                }`}
                                onClick={() =>
                                  isEditable &&
                                  handleFileUpload(
                                    "frame1LogoRef",
                                    "frame1Logo"
                                  )
                                }
                              >
                                Change Logo
                              </div>

                              {/* <div className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-center self-stretch border border-black bg-white mt-4 px-16 py-3 rounded-lg border-solid max-md:max-w-full max-md:px-5">
                            <input
                              className="  w-[697px] max-w-full outline-none bg-white"
                              type="file"
                              placeholder=" Change Logo"
                              disabled={isEditable ? false : true}
                            />
                          </div> */}
                            </div>
                          </>
                          {/* video */}
                          <>
                            <UploadVideo
                              className=""
                              inputId={`frame1_video`}
                              imgId={`frame1_video_1`}
                              src={homePageData?.frame1Video}
                              name={`frame1Video`}
                              setValue={setValue}
                              register={register}
                              type="upload"
                              accept="video/mp4"
                              isVideo={true}
                              isEditable={isEditable}
                            />
                          </>
                        </>

                        {/* frame 2 */}
                        <>
                          <div className=" my-4 flex border border-black h-[1px] w-full flex-col" />
                          <div className="text-neutral-800 text-lg font-bold mt-4 max-md:max-w-full max-md:mt-10">
                            Frame 2
                          </div>
                          {/* texxt */}
                          <>
                            <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                              Welcome
                            </div>
                            <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                              <input
                                className="  w-[697px] max-w-full outline-none bg-white"
                                placeholder="Enter Text"
                                disabled={isEditable ? false : true}
                                {...register("frame2Text")}
                              />
                            </div>
                            <Error
                              error={errors?.frame2Text}
                              message={errors?.frame2Text?.message}
                            />
                          </>

                          {/* video */}
                          {/* <>
                        <div className=" mt-4 text-neutral-800 text-base font-semibold leading-6 self-stretch max-md:max-w-full">
                          Video
                        </div>
                        <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5 text-balance">
                          <div ref={frame2VideoRef}>
                            <UploadFile
                              className=""
                              inputId={`frame2_video`}
                              imgId={`frame2_video_1`}
                              src={homePageData?.frame2Video}
                              name={"frame2Video"}
                              setValue={setValue}
                              register={register}
                              type="upload"
                              accept="video/*"
                            />
                          </div>

                          <div
                            className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-center self-stretch border border-black bg-white mt-4 px-16 py-3 rounded-lg border-solid max-md:max-w-full max-md:px-5 cursor-pointer"
                            onClick={() =>
                              isEditable &&
                              handleFileUpload("frame2VideoRef", "frame2_video")
                            }
                          >
                            Change Video
                          </div>
                          <Error
                            error={errors?.frame1Video}
                            message={errors?.frame1Video?.message}
                          />
                        </div>
                      </> */}

                          {/*  */}
                          <UploadVideo
                            className=""
                            inputId={`frame2_video`}
                            imgId={`frame2_video_1`}
                            src={homePageData?.frame2Video}
                            name={`frame2Video`}
                            setValue={setValue}
                            register={register}
                            type="upload"
                            accept="video/mp4"
                            isVideo={true}
                            isEditable={isEditable}
                          />
                          {/*  */}

                          {/* add card */}
                          <>
                            <div className=" flex gap-2 mt-4 text-neutral-800 text-base font-semibold leading-6 self-stretch max-md:max-w-full">
                              <text> Add Card </text>
                              {isEditable && (
                                <>
                                  <img
                                    loading="lazy"
                                    src={plus_icon}
                                    className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start cursor-pointer"
                                    alt="add-icon"
                                    onClick={() =>
                                      frame2CardAppend({
                                        title: "",
                                        description: "",
                                      })
                                    }
                                  />
                                </>
                              )}
                            </div>
                            {/* form array */}
                            {frame2Card?.map((item, index) => {
                              return (
                                <>
                                  <div
                                    key={item}
                                    className=" border border-black p-4 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full mt-2 mb-2"
                                  >
                                    <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                                      <div>Title {index + 1} </div>
                                      {isEditable && frame2Card.length > 1 && (
                                        <img
                                          loading="lazy"
                                          src={delete_icon}
                                          className="aspect-square object-contain object-center w-4 overflow-hidden shrink-0 max-w-full self-start cursor-pointer"
                                          alt="delete-icon"
                                          onClick={() => {
                                            frame2CardRemove(index);
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div className="text-neutral-400 text-base whitespace-nowrap self-stretch border border-[color:var(--Grey-mid,#C0C0C0)] bg-white justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:max-w-full max-md:pr-5">
                                      <input
                                        className="  w-[697px] max-w-full outline-none bg-white"
                                        placeholder=" Add Title Text"
                                        disabled={isEditable ? false : true}
                                        {...register(
                                          `frame2Card.${index}.title`
                                        )}
                                      />
                                    </div>
                                    <Error
                                      error={errors?.frame2Card?.[index]?.title}
                                      message={
                                        errors?.frame2Card?.[index]?.title
                                          ?.message
                                      }
                                    />
                                    <>
                                      <div className="self-stretch text-neutral-900 text-sm font-medium leading-4 mt-4 max-md:max-w-full">
                                        Description{" "}
                                      </div>
                                      <div className="text-neutral-400 text-base whitespace-nowrap self-stretch border border-[color:var(--Grey-mid,#C0C0C0)] bg-white justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:max-w-full max-md:pr-5">
                                        <input
                                          className="  w-[697px] max-w-full outline-none bg-white"
                                          placeholder=" Add Description Text"
                                          disabled={isEditable ? false : true}
                                          {...register(
                                            `frame2Card.${index}.description`
                                          )}
                                        />
                                      </div>
                                      <Error
                                        error={
                                          errors?.frame2Card?.[index]
                                            ?.description
                                        }
                                        message={
                                          errors?.frame2Card?.[index]
                                            ?.description.message
                                        }
                                      />
                                    </>
                                  </div>
                                </>
                              );
                            })}

                            {/*  */}
                          </>
                        </>

                        {/* frame 3 */}
                        <>
                          <div className=" my-4 flex border border-black h-[1px] w-full flex-col"></div>
                          <div className="text-neutral-800 text-lg font-bold mt-4 max-md:max-w-full max-md:mt-10">
                            Frame 3
                          </div>
                          {/* frame 3 data */}
                          {/* logo */}
                          {/* video */}
                        </>

                        {/* frame 4 */}
                        <>
                          <div className=" my-4 flex border border-black h-[1px] w-full flex-col" />
                          <div className="text-neutral-800 text-lg font-bold mt-4 max-md:max-w-full max-md:mt-10">
                            Frame 4
                          </div>
                          <>
                            <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                              Welcome
                            </div>
                            <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                              <input
                                className="  w-[697px] max-w-full outline-none bg-white"
                                placeholder="Enter Text"
                                disabled={isEditable ? false : true}
                                {...register("frame4Text")}
                              />
                            </div>
                            <Error
                              error={errors?.frame4Text}
                              message={errors?.frame4Text?.message}
                            />
                          </>

                          {/* add card */}
                          <>
                            <div className=" flex gap-2 mt-6 text-neutral-800 text-base font-semibold leading-6 self-stretch max-md:max-w-full">
                              <text> Add Card </text>
                              {isEditable && (
                                <>
                                  <img
                                    loading="lazy"
                                    src={plus_icon}
                                    className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start cursor-pointer"
                                    alt="add-icon"
                                    onClick={() =>
                                      frame4CardAppend({
                                        title: "",
                                        description: "",
                                      })
                                    }
                                  />
                                </>
                              )}
                            </div>
                            {/* form array */}
                            {frame4Card?.map((item, index) => {
                              return (
                                <>
                                  <div
                                    key={item}
                                    className=" border border-black p-4 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full mt-2 mb-2"
                                  >
                                    <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                                      <div>Title {index + 1} </div>
                                      {isEditable && frame4Card.length > 1 && (
                                        <img
                                          loading="lazy"
                                          src={delete_icon}
                                          className="aspect-square object-contain object-center w-4 overflow-hidden shrink-0 max-w-full self-start cursor-pointer"
                                          alt="delete-icon"
                                          onClick={() => {
                                            frame4CardRemove(index);
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div className="text-neutral-400 text-base whitespace-nowrap self-stretch border border-[color:var(--Grey-mid,#C0C0C0)] bg-white justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:max-w-full max-md:pr-5">
                                      <input
                                        className="  w-[697px] max-w-full outline-none bg-white"
                                        placeholder=" Add Title Text"
                                        disabled={isEditable ? false : true}
                                        {...register(
                                          `frame4Card.${index}.title`
                                        )}
                                      />
                                    </div>
                                    <Error
                                      error={errors?.frame4Card?.[index]?.title}
                                      message={
                                        errors?.frame4Card?.[index]?.title
                                          ?.message
                                      }
                                    />
                                    <>
                                      <div className="self-stretch text-neutral-900 text-sm font-medium leading-4 mt-4 max-md:max-w-full">
                                        Description{" "}
                                      </div>
                                      <div className="text-neutral-400 text-base whitespace-nowrap self-stretch border border-[color:var(--Grey-mid,#C0C0C0)] bg-white justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:max-w-full max-md:pr-5">
                                        <input
                                          className="  w-[697px] max-w-full outline-none bg-white"
                                          placeholder=" Add Description Text"
                                          disabled={isEditable ? false : true}
                                          {...register(
                                            `frame4Card.${index}.description`
                                          )}
                                        />
                                      </div>
                                      <Error
                                        error={
                                          errors?.frame4Card?.[index]
                                            ?.description
                                        }
                                        message={
                                          errors?.frame4Card?.[index]
                                            ?.description?.message
                                        }
                                      />
                                    </>
                                  </div>
                                </>
                              );
                            })}

                            {/*  */}
                          </>
                        </>

                        {/* frame 5 */}
                        <>
                          <div className=" my-4 flex border border-black h-[1px] w-full flex-col" />

                          {/* video */}
                          <>
                            <div className="text-neutral-800 text-lg font-bold mt-4 max-md:max-w-full max-md:mt-10">
                              Frame 5
                            </div>
                            <UploadVideo
                              className=""
                              inputId={`frame5_video`}
                              imgId={`frame5_video_1`}
                              src={homePageData?.frame5Video}
                              name={`frame5Video`}
                              setValue={setValue}
                              register={register}
                              type="upload"
                              accept="video/mp4"
                              isVideo={true}
                              isEditable={isEditable}
                            />
                          </>
                        </>

                        {/* frame 6 */}
                        <>
                          <div className=" my-4 flex border border-black h-[1px] w-full flex-col" />
                          <div className="text-neutral-800 text-lg font-bold mt-4 max-md:max-w-full max-md:mt-10">
                            Frame 6
                          </div>
                          {/* add card */}
                          <>
                            <div className=" flex gap-2 mt-4 text-neutral-800 text-base font-semibold leading-6 self-stretch max-md:max-w-full">
                              <text> Add Card </text>
                              {isEditable && (
                                <>
                                  <img
                                    loading="lazy"
                                    src={plus_icon}
                                    className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start cursor-pointer"
                                    alt="add-icon"
                                    onClick={() =>
                                      frame6CardAppend({
                                        title: "",
                                        description: "",
                                      })
                                    }
                                  />
                                </>
                              )}
                            </div>
                            {/* form array */}
                            {frame6Card?.map((item, index) => {
                              return (
                                <>
                                  <div
                                    key={item}
                                    className=" border border-black p-4 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full mt-2 mb-2"
                                  >
                                    <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                                      <div>Title {index + 1} </div>
                                      {isEditable && frame6Card.length > 1 && (
                                        <img
                                          loading="lazy"
                                          src={delete_icon}
                                          className="aspect-square object-contain object-center w-4 overflow-hidden shrink-0 max-w-full self-start cursor-pointer"
                                          alt="delete-icon"
                                          onClick={() => {
                                            frame6CardRemove(index);
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div className="text-neutral-400 text-base whitespace-nowrap self-stretch border border-[color:var(--Grey-mid,#C0C0C0)] bg-white justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:max-w-full max-md:pr-5">
                                      <input
                                        className="  w-[697px] max-w-full outline-none bg-white"
                                        placeholder=" Add Title Text"
                                        disabled={isEditable ? false : true}
                                        {...register(
                                          `frame6Card.${index}.title`
                                        )}
                                      />
                                    </div>
                                    <Error
                                      error={errors?.frame6Card?.[index]?.title}
                                      message={
                                        errors?.frame6Card?.[index]?.title
                                          ?.message
                                      }
                                    />

                                    <>
                                      <div className="self-stretch text-neutral-900 text-sm font-medium leading-4 mt-4 max-md:max-w-full">
                                        Description{" "}
                                      </div>
                                      <div className="text-neutral-400 text-base whitespace-nowrap self-stretch border border-[color:var(--Grey-mid,#C0C0C0)] bg-white justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:max-w-full max-md:pr-5">
                                        <input
                                          className="  w-[697px] max-w-full outline-none bg-white"
                                          placeholder=" Add Description Text"
                                          disabled={isEditable ? false : true}
                                          {...register(
                                            `frame6Card.${index}.description`
                                          )}
                                        />
                                      </div>
                                      <Error
                                        error={
                                          errors?.frame6Card?.[index]
                                            ?.description
                                        }
                                        message={
                                          errors?.frame6Card?.[index]
                                            ?.description?.message
                                        }
                                      />
                                    </>
                                  </div>
                                </>
                              );
                            })}

                            {/*  */}
                          </>
                        </>

                        {/* frame 7 */}
                        <>
                          <div className=" my-4 flex border border-black h-[1px] w-full flex-col" />
                          <div className="text-neutral-800 text-lg font-bold mt-4 max-md:max-w-full max-md:mt-10">
                            Frame 7
                          </div>

                          {/* video */}
                          <UploadVideo
                            className=""
                            inputId={`frame7_video`}
                            imgId={`frame7_video_1`}
                            src={homePageData?.frame7Video}
                            name={`frame7Video`}
                            setValue={setValue}
                            register={register}
                            type="upload"
                            accept="video/mp4"
                            isVideo={true}
                            isEditable={isEditable}
                          />

                          {/* text */}
                          <>
                            <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                              url.com
                            </div>
                            <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                              <input
                                className="  w-[697px] max-w-full outline-none bg-white"
                                placeholder="  Destination URL"
                                disabled={isEditable ? false : true}
                                {...register("frame7Text")}
                              />
                            </div>
                            <Error
                              error={errors?.frame7Text}
                              message={errors?.frame7Text?.message}
                            />
                          </>
                        </>

                        {/* frame 8 */}
                        <>
                          <div className=" my-4 flex border border-black h-[1px] w-full flex-col" />
                          <div className="text-neutral-800 text-lg font-bold mt-4 max-md:max-w-full max-md:mt-10">
                            Frame 8
                          </div>

                          {/* text */}
                          <>
                            <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                              Body
                            </div>
                            <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                              <input
                                className="  w-[697px] max-w-full outline-none bg-white"
                                placeholder="  Destination body"
                                disabled={isEditable ? false : true}
                                {...register("frame8Text")}
                              />
                            </div>
                            <Error
                              error={errors?.frame8Text}
                              message={errors?.frame8Text?.message}
                            />
                          </>
                        </>

                        {/*  */}
                      </div>
                    </div>
                  </>
                  {/* footer-button */}
                  <div className=" bg-white flex flex-col gap-1 w-full  items-end mr-2 rounded-lg mb-2  ">
                    <button
                      type="submit"
                      className={`text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-black text-white px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 ${
                        isEditable ? "cursor-pointer" : "cursor-not-allowed"
                      }`}
                    >
                      Save
                    </button>
                  </div>
                  {/* <div className="flex justify-end bg-white px-2 py-2  w-full ">
                <div className="  justify-end items-stretch flex w-1/3  gap-2  max-md:flex-wrap">
                  <div className="text-white bg-black text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-center bg-neutral-900 grow px-16 py-5 rounded-lg max-md:px-5">
                    <button type="submit">Publish to staging</button>
                  </div>
                </div>
              </div> */}
                </form>
              )}

              {/*  */}
            </div>
          </div>
        </div>
    </>
  );
};

export default HomePage;
