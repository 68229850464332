import React from "react";
import frameImage from "../../../../../assets/images/comp_imgs/page_mgmt_homepage.png";
import plus_icon from "../../../../../assets/images/icons/plus_icon.png";
import delete_icon from "../../../../../assets/images/icons/delete_icon.png";
import grid_icon from "../../../../../assets/images/icons/grid_icon.png";
import copy_icon from "../../../../../assets/images/icons/copy_icon.png";
import up_arrow_icon from "../../../../../assets/images/icons/up_arrow_icon.png";

const Testimonail = () => {
  return (
    <div>
      <>
        <div className="flex flex-col justify-center items-center px-4 w-[calc(100vw-264px)]    mt-5   ">
          <div className=" w-full  hide_scroll max-h-[calc(100vh-150px)] overflow-y-auto">
            <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4  ">
              {/* wrapper */}
              <div className="items-stretch self-stretch bg-white flex flex-col py-5 px-4 border-solid">
                {/* wrapper header */}
                <div
                  className=" border-2 border-black  w-full py-5 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mr-2.5
              "
                >
                  <div className="items-start flex gap-4 mx-4 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mr-2.5">
                    <div className="text-neutral-800 text-lg font-bold self-stretch grow max-md:max-w-full">
                      Testimonails
                    </div>
                    <img
                      loading="lazy"
                      src={plus_icon}
                      className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
                      alt="add-icon"
                    />
                    <img
                      loading="lazy"
                      src={copy_icon}
                      className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
                      alt="copy-icon"
                    />
                    <img
                      loading="lazy"
                      src={grid_icon}
                      className="aspect-square object-contain object-center w-[22px] overflow-hidden self-center shrink-0 
                max-w-full my-auto"
                      alt="grid-icon"
                    />
                    <img
                      loading="lazy"
                      src={delete_icon}
                      className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
                      alt="delete-icon"
                    />
                    <img
                      loading="lazy"
                      src={up_arrow_icon}
                      className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
                      alt="arrow-icon"
                    />
                  </div>
                </div>
                {/* wrapper container */}
                <div className="border-t-[color:var(--Grey-Grey,#97A1A9)] border-2 border-black flex w-full flex-col  pl-4 pr-20 pt-2.5 pb-12 border-t border-solid items-start max-md:max-w-full max-md:pr-5">
                  {/*  */}
                  <div className="self-stretch flex gap-4 items-start max-md:flex-wrap max-md:justify-center w-2/4 py-2 mb-4  ">
                    <div className="text-neutral-800 text-base font-bold grow max-md:max-w-full">
                      Testimonials
                    </div>
                    <img
                      loading="lazy"
                      src={plus_icon}
                      className="aspect-square object-contain object-center w-6 overflow-hidden self-stretch shrink-0 max-w-full"
                      alt="delete-icon"
                    />
                    <img
                      loading="lazy"
                      src={grid_icon}
                      className="aspect-square object-contain object-center w-[22px] overflow-hidden shrink-0 max-w-full"
                      alt="delete-icon"
                    />
                    <img
                      loading="lazy"
                      src={delete_icon}
                      className="aspect-square object-contain object-center w-6 overflow-hidden self-stretch shrink-0 max-w-full"
                      alt="del-icon"
                    />
                  </div>
                  {/*  */}

                  {/* wrapper body */}

                  <div className="text-neutral-800 text-lg font-bold max-md:max-w-full">
                    Customer One
                  </div>
                  <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                    Comment
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-5 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full outline-none"
                      placeholder="  Heading"
                    />
                  </div>

                  <div className="items-start border-b-[color:var(--Grey-10,#FFF)] flex w-[697px] max-w-full flex-col mt-6 pb-6 border-b border-solid">
                    <div className="text-neutral-800 text-base leading-6 self-stretch max-md:max-w-full">
                      Image
                    </div>
                    <img
                      loading="lazy"
                      src={frameImage}
                      className="aspect-[0.86] object-contain object-center w-[156px] overflow-hidden max-w-full mt-4 self-start"
                      alt="frame-img"
                    />
                    <div className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-center self-stretch border-2 border-black bg-white mt-4 px-16 py-3 rounded-lg border-solid max-md:max-w-full max-md:px-5">
                      Change
                    </div>
                    <div className="self-stretch text-neutral-900 text-sm font-medium leading-4 mt-4 max-md:max-w-full">
                      Name{" "}
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap self-stretch border border-[color:var(--Grey-mid,#C0C0C0)] bg-white justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:max-w-full max-md:pr-5">
                      <input
                        className="  w-[697px] max-w-full outline-none"
                        placeholder="Lucy P"
                      />
                    </div>
                    <div className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-center self-stretch border-2 border-black bg-white mt-4 px-16 py-3 rounded-lg border-solid max-md:max-w-full max-md:px-5">
                      Add Section
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Testimonail;
