import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function InboxTooltip({ handleEdit = () => { }, handleUpdate = () => { }, id = 0,options=[], handleView=()=>{}, handleStatus=()=>{} }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [innerAnchorEl, setInnerAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const innerOpen = Boolean(innerAnchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInnerClick = (event) => {
        setInnerAnchorEl(event.currentTarget);
    };
    const handleInnerClose = () => {
        setInnerAnchorEl(null);
    };

    const handleAction = (cb) => {
        cb();
        handleClose();
        handleInnerClose()
    }
    return (
        <div>
            <svg
                onClick={handleClick}
                width="32"
                height="32"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.5"
                    width="39"
                    height="39"
                    rx="7.5"
                    fill="white"
                />
                <rect
                    x="0.5"
                    y="0.5"
                    width="39"
                    height="39"
                    rx="7.5"
                    stroke="#141414"
                />
                <path
                    d="M20 15.75C19.0335 15.75 18.25 14.9665 18.25 14C18.25 13.0335 19.0335 12.25 20 12.25C20.9665 12.25 21.75 13.0335 21.75 14C21.75 14.9665 20.9665 15.75 20 15.75ZM20 21.75C19.0335 21.75 18.25 20.9665 18.25 20C18.25 19.0335 19.0335 18.25 20 18.25C20.9665 18.25 21.75 19.0335 21.75 20C21.75 20.9665 20.9665 21.75 20 21.75ZM18.25 26C18.25 26.9665 19.0335 27.75 20 27.75C20.9665 27.75 21.75 26.9665 21.75 26C21.75 25.0335 20.9665 24.25 20 24.25C19.0335 24.25 18.25 25.0335 18.25 26Z"
                    fill="#212121"
                />
            </svg>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleAction(()=>handleStatus(id, "", "true", ""))}>Mark as read</MenuItem>
                <MenuItem onClick={() => handleAction(()=>handleStatus(id, "", "false", ""))}>Mark as new</MenuItem>
                <MenuItem onClick={()=>handleAction(handleView)}>View</MenuItem>
                <MenuItem onClick={() => handleAction(()=>handleStatus(id, "", "", "inactive"))}>Delete</MenuItem>
                <MenuItem onClick={() => {}}>
                    <span onClick={handleInnerClick}>Recategorize</span>
                    <Menu
                        id="basic-menu-nested"
                        anchorEl={innerAnchorEl}
                        open={innerOpen}
                        onClose={handleInnerClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {options.map((option)=>(
                            <>
                                {option?.value ? <MenuItem onClick={()=>handleAction(() => handleStatus(id, option?.value, "", ""))}>{option?.label}</MenuItem> : null}
                            </>
                        ))}
                    </Menu>
                </MenuItem>
            </Menu>
        </div>
    );
}
