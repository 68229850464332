import { combineReducers, createStore } from "redux";
import authReducer from "../reducuer/authReducer";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import loaderReducer from "../reducuer/loaderReducer";

const reducer = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
});

// const store = createStore(
//   reducer,

//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );
const persistConfig = {
  key: "blackjet_admin",
  storage,
  whitelist: ["auth"],
  blacklist: ["loader"],
};

const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer);
const Persistor = persistStore(store);

export { Persistor };

export default store;
