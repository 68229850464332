import React, { useEffect, useState } from "react";
import $ from "jquery";
import defaultVideo from "../../../assets/images/upload.png";

const UploadFile = ({
  title = "",
  inputId = "",
  src = "",
  alt = "",
  imgId = "",
  type = "upload",
  name = "",
  setValue = () => { },
  register,
  accept = "image/png, image/gif, image/jpeg, image/tiff,",
  name2 = "",
  isLoading,
  setLoading,
  extraName = "",
  index = 0,
  preview = [],
  setPreview = () => { },
  trigger = () => { },
}) => {
  useEffect(() => {
    $(`#${inputId}`).change(function () {
      var curElement = $(`#${imgId}`);
      var reader = new FileReader();
      reader.onload = function (e) {
        // if(e?.target?.result?.indexOf("video/")>-1){
        //     curElement.attr("src",defaultVideo);
        if (name2) {
          setValue(name2, "video");
        } else if (e?.target?.result?.indexOf("video/") > -1) {
          curElement.attr("src", defaultVideo);
        }

        // }
        // else if(e?.target?.result?.indexOf("/json")>-1){
        //     curElement.attr("src",json);
        //     if(name2){
        //         setValue(name2,'jsonData')
        //     }
        // }
        else {
          curElement.attr("src", e.target.result);
          if (name2) {
            setValue(name2, "image");
          }
          if (extraName) {
            document.getElementById(extraName).src = e.target.result;
          }
        }
      };
      reader.readAsDataURL(this.files[0]);
    });
  }, []);
  return (
    <>
      <label htmlFor={inputId}>
        <img className="image-upload" id={imgId} src={src} alt={alt} />
        {title}
      </label>

      <input
        type="file"
        className="form-control image-upload-input"
        id={inputId}
        name={name}
        onChange={async (e) => {
          if (type === "single-upload") {
            let tempArr = [];
            preview?.map((item, i) => {
              if (i === index) {
                item = e.target.files[0];
              }
              tempArr.push(item);
            });
            setPreview(tempArr);
          }
          // setLoading(true)
          if (type === "upload") {
            setValue(name, e.target.files);
          } else if (type === "mix-file") {
            setValue(name, e.target.files);
          }
          // else{
          //     let response = await uploadImage(e.target.files[0])
          //     setValue(name,response)
          // }
          // setLoading(false)
          trigger(name);
        }}
        accept={accept}
      />
    </>
  );
};

export default UploadFile;
