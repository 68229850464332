import api from './interceptor';

export const getAllUsers = async (payload) => await api.get(`getAllUsers?skip=${payload.skip}&limit=${payload.limit}&search=${payload.search}&is_member=${payload.isMember}`);

export const onActiveUsers = async (payload) => await api.post(`updateUserStatus`,payload);

export const onAddUsers = async (formData) => await api.post(`addUser`,formData);

export const onEditUsers = async (formData) => await api.post(`editUser`,formData);

export const viewAllUsers = async (payload) => await api.get(`getUser?id=${payload.id}`);

export const getAllExpertise = async () => await api.get(`getIndustry`);

/*pets api*/

export const getAllPets= async (payload) => await api.get(`getUserPets?skip=${payload.skip}&limit=${payload.limit}&search=${payload.search}&id=${payload.id}`);

export const getAllBreeds = async () => await api.get(`breedList`);

export const onAddPets = async (formData) => await api.post(`addPet`,formData);

export const viewPet = async (payload) => await api.get(`getUserPet?id=${payload.id}`);


/*flight history api*/
export const getAllFlights= async (payload) => await api.get(`getUserFlights?skip=${payload.skip}&limit=${payload.limit}&search=${payload.search}&id=${payload.id}`);

export const stateLists = async () => await api.get(`stateList`);

export const onEditPet = async (formData) => await api.post(`editPet`,formData);
