import { useEffect, useState } from "react";
import { Card, Typography, Tooltip } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import Toggle from "react-toggle";
import { faPen, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import "react-toggle/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoDataFound from "../../../components/common/NoDataFound";
import { generateConfirm } from "../../../utils/reactConfirmAlert";
import {
  getAllLocation,
  getAllRoutes,
  ondeleteRoutes,
} from "../../../api/flight-management/flight";
import PopUpLayout from "../../dashboard/user-mgmt/PopUpLayout";
import CustomLabel from "../../dashboard/user-mgmt/CustomLabal";
import AddRoute from "./AddRoute";
import { errorToast, successToast } from "../../../utils/toast";
import CustomPagination from "../../../components/common/CustomPagination";
import ContainerHeading from "../../../components/common/container-heading/ContainerHeading";
import { useSelector } from "react-redux";

const TABLE_HEAD = ["Route Name", "From City", "To City", "Action"];

const RouteList = () => {
  const { loader } = useSelector((state) => state.loader);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [routeList, setRouteList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [editable, setEditable] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(1);
  const [popUpLayoutType, setPopUpLayoutType] = useState("");

  useEffect(() => {
    getLocationList();
  }, []);

  useEffect(() => {
    getRoutessList();
  }, [skip, open]);

  // get location from API
  const getLocationList = async () => {
    let payload = {
      skip: "",
      limit: "",
      search: "",
    };
    const response = await getAllLocation(payload);
    if (response?.status_code === 200) {
      setLocationList(response?.data || []);
    }
  };

  const getRoutessList = async () => {
    let payload = {
      skip,
      limit,
      search: "",
    };
    const response = await getAllRoutes(payload);
    if (response?.status_code == 200) {
      setRouteList(response?.data || []);
      setPageCount(Math.ceil(response?.totalItems / limit) || 0);
    }
  };

  const deleteRoutes = (id) => {
    generateConfirm(
      "Are you sure?",
      `You want to delete this Route`,
      1,
      async () => {
        let payload = {
          id,
        };
        const response = await ondeleteRoutes(payload);
        if (response?.status_code === 200) {
          successToast(response?.message);
          getRoutessList();
        } else {
          errorToast(response?.message);
        }
      }
    );
  };

  const handleButtonClick = () => {
    setEditable(false);
    setOpen(true);
    setPopUpLayoutType("add");
  };
  return (
    <>
        <div className="common-bg rounded-t-2xl">
          {/* <div className="p-3 d-flex justify-content-between">
          <h1 className="common-heading">Routes</h1>
          <button
            className="add-user-button"
            onClick={() => {
              setOpen(true);
              setEditable(false);
              setPopUpLayoutType("add");
            }}
          >
            Add Routes
          </button>
        </div> */}
          <ContainerHeading
            className="rounded-t-lg"
            title={"Routes"}
            button="Routes"
            onButtonClick={handleButtonClick}
          />
          <Card className=" !z-5 overflow-x-auto rounded-none">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b w-25 border-t border-blue-gray-100 px-5 text-start py-3 bg-[#d3d3d3]"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none font-semibold"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {routeList?.map((item, index) => {
                  const isLast = index === routeList.length - 1;
                  const classes = isLast
                    ? "py-2 px-5 w-25 "
                    : "py-2 px-5 w-25  border-b border-blue-gray-50";

                  return (
                    <tr key={index}>
                      <td
                        className={classes + " cursor-pointer"}
                        // onClick={() => {
                        //     navigate("/dashboard/customer-management/view-user-list");
                        // }}
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.route_name || "N/A"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.fromCity_name || "N/A"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.toCity_name || "N/A"}
                        </Typography>
                      </td>
                  
                      <td className={classes}>
                        <div class="action-toggle">
                          <Tooltip content={"View"}>
                            <FontAwesomeIcon
                              onClick={() => {
                                {
                                  setOpen(true);
                                  setEditable(true);
                                  setId(item?._id);
                                  setPopUpLayoutType("view");
                                }
                              }}
                              className="edit-btn"
                              icon={faEye}
                            />
                          </Tooltip>
                          <Tooltip content={"Delete"}>
                            <FontAwesomeIcon
                              onClick={() => {
                                deleteRoutes(item?._id);
                              }}
                              className="delete-btn ms-3"
                              icon={faTrash}
                            />
                          </Tooltip>
                          <Tooltip content={"Edit"}>
                            <FontAwesomeIcon
                              onClick={() => {
                                {
                                  setOpen(true);
                                  setEditable(true);
                                  setId(item?._id);
                                  setPopUpLayoutType("edit");
                                }
                              }}
                              className="edit-btn ms-3"
                              icon={faPen}
                            />
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {routeList.length === 0 && !loader && <NoDataFound />}
            </table>
          </Card>
        </div>
      {open && (
        <PopUpLayout setShowModal={setOpen}>
          <>
            <div className="bg-white w-[500px] p-5  overflow-y-auto hide_scroll rounded-xl ">
              <div className="flex justify-between ">
                <h1 className="taxt-[#303637] text-[20px] font-semibold ">
                  {popUpLayoutType === "view"
                    ? "View Route Details"
                    : popUpLayoutType === "edit"
                    ? "Update Route Details"
                    : " Add New Route"}
                </h1>
                <svg
                  className="cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z"
                    fill="#212121"
                  />
                </svg>
              </div>

              <AddRoute
                setOpen={setOpen}
                editable={editable}
                id={id}
                type={popUpLayoutType}
                locationList={locationList}
              />
            </div>
          </>
        </PopUpLayout>
      )}
      {/* pagination */}
      {pageCount > 1 && (
        <>
          <div className="bg-white flex flex-col gap-1 w-full  items-end pt-2 px-2   rounded-lg   ">
            <Card className="py-2 rounded-none   ">
              <span className="bg-[#E6E6E6] py-3  px-2 w-auto items-end ml-3  rounded-xl ">
                <CustomPagination
                  page={skip}
                  setPage={setSkip}
                  count={pageCount}
                />
              </span>
            </Card>
          </div>
        </>
      )}
    </>
  );
};

export default RouteList;
