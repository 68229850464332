import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import OutletPage from "../components/OutletPage";

const PublicRoute = ({ children }) => {
  const data = useSelector(state => state.auth)
  const isLogin = data?.auth?.token;
  if (isLogin) {
    return <Navigate to="/dashboard" />;
  }
  return <OutletPage/>
};

export default PublicRoute;