import ActionTooltip from "../../../components/members&pricing/ActionTooltip";
import AddBoutique from "./AddBoutique";
import AddIcon from "@mui/icons-material/Add";
import ContainerHeading from "../../../components/common/container-heading/ContainerHeading";
import CustomPagination from "../../../components/common/CustomPagination";
import Toggle from "react-toggle";
import useQueryParams from "../../../hooks/useQueryParams";
import { faDollarSign, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Typography } from "@material-tailwind/react";
import { Fab, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBoutiqueList, getMembershipData, updateBoutiqueStatus } from "../../../api/membership";
import { errorToast, successToast } from "../../../utils/toast";

const TABLE_HEAD = [
  "Name",
  "Price",
  "Next Price",
  // "Effective Date",
  // "End Date",
  "Status",
  "Action",
];

const TABLE_ROWS = [
  {
    name: "John Michael",
    job: "Manager",
    date: "23/04/18",
  },
  {
    name: "Alexa Liras",
    job: "Developer",
    date: "23/04/18",
  },
  {
    name: "John Michael",
    job: "Manager",
    date: "23/04/18",
  },
  {
    name: "Alexa Liras",
    job: "Developer",
    date: "23/04/18",
  },
];

export function Boutique() {
  const [open, setOpen] = useState(false);
  const query = useQueryParams();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [selectedId, setSelectedId] = useState("");
const [skip, setSkip] = useState(1);  
  const limit = 10;
  const [pageCount, setPageCount] = useState(1);
  const [memberships, setMemberships] = useState([]);

  useEffect(() => {
    fetchItemList();
  }, [skip]);

  useEffect(()=>{
    getMembershipValues()
  },[])

  const fetchItemList = async () => {
    let response = await getBoutiqueList({ skip, limit });
    if (response?.status_code === 200) {
      setItems(response?.data || []);
      setPageCount(Math.ceil(response?.totalItems / limit))
    }
  };

  const handleAdd = () => {
    setSelectedId({});
    setOpen(true);
  };

  const handleUpdate = async (id) => {
    let item = items?.find((i) => i._id === id) || {};
    let response = await updateBoutiqueStatus({
      id: item?._id,
      status: item?.status === "active" ? "inactive" : "active",
    });
    if (response?.status_code === 200) {
      successToast(response?.message);
      fetchItemList();
    }
  };
  const onActive = async (id, status) => {
    console.log(id, status, "asdfdsv");
    let payload = {
      id,
      status: status === "active" ? "inactive" : "active",
    };
    const response = await updateBoutiqueStatus(payload);
    if (response?.status_code === 200) {
      successToast(response?.message || "Success");
      fetchItemList();
    } else {
      errorToast(response?.message || "Something Went Wrong");
    }
  };

  const getMembershipValues = async () => {
    let response = await getMembershipData();
    if (response?.status_code === 200) {
        setMemberships(response?.data || [])
    }
}

console.log("memberships",memberships)
  const handleEdit = async (id) => {
    const boutique = items?.find((item) => item._id === id) || {};
    setSelectedId(boutique);
    setOpen(true);
  };

  const handlePriceEdit = (membership) => {
    navigate(`/prices/boutique/${membership?.name}/${membership?._id}`)
  }

  return (
    <Card className="h-[calc(100vh-180px)] overflow-x-scroll hide_scroll rounded-none  rounded-b-2xl w-[calc(100vw-200px)]">
      <ContainerHeading
        title={"Botique "}
        button="Add Product" 
        onButtonClick={handleAdd}
      />
      <table className="w-full min-w-max  table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className=" leading-none font-semibold"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            const isLast = index === TABLE_ROWS.length - 1;
            const classes = isLast
              ? "py-2 px-3"
              : "py-2 px-3 border-b    border-blue-gray-50";

            return (
              <tr key={item?._id}>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item?.name || "NA"}
                  </Typography>
                </td>

                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                     {item?.currentPrice || "NA"}
                  </Typography>
                </td>

                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    $ {item?.nextCurrentPrice || "NA"}
                  </Typography>
                </td>
                {/* <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item?.card_title || "NA"}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item?.product_set || "NA"}
                  </Typography>
                </td> */}
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className={`font-normal ${item.status === "Scheduled"
                      ? "bg-[#90CAF9]"
                      : item.status === "Published"
                        ? "bg-[#B9F6CA]"
                        : item.status === "Draft"
                          ? "bg-[#FFF8E1]"
                          : item.status === "active"
                            ? "bg-[#B9F6CA]"
                            : "bg-[#EAEAEA]"
                      }  px-3 py-1 rounded-[100px] w-[100px] text-center`}
                  >
                    {item.status}
                  </Typography>
                </td>

                <td className={classes}>
                  <div class="action-toggle">
                    <Toggle
                      checked={item?.status === "active" ? "active" : ""}
                      icons={false}
                      className={
                        item?.status === "inactive" ? "isDisable" : "active"
                      }
                      onChange={() => onActive(item?._id, item?.status)}
                    />
                    <Tooltip content={"Edit"}>
                      <FontAwesomeIcon
                        onClick={() => {
                          handleEdit(item?._id);
                        }}
                        className="edit-btn ms-3"
                        icon={faPen}
                      />
                    </Tooltip>
                    <Tooltip content={"Prices"}>
                      <FontAwesomeIcon
                        onClick={() => {
                          handlePriceEdit(item);
                        }}
                        className="edit-btn ms-3"
                        icon={faDollarSign}
                      />
                    </Tooltip>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {pageCount > 1 && (
                                    <CustomPagination
                                      page={skip}
                                      setPage={setSkip}
                                      count={pageCount}
                                    />
                                  )}
      {/* <div className="flex flex-row-reverse p-4">
        <Fab onClick={handleAdd} color="info" aria-label="add">
          <AddIcon />
        </Fab>
      </div> */}
      <AddBoutique
        id={selectedId}
        fetchItemList={fetchItemList}
        open={open}
        setOpen={setOpen}
        memberships={memberships}
        setMemberships={setMemberships}
      />
    </Card>
  );
}
