import api from "./interceptor";
export const getEnquiry = async (payload) =>
  await api.get(
    `/getEnquiry?type=${payload?.type}&skip=${payload?.skip}&limit=${payload.limit}&search=${payload.search}`
  );

export const getSingleEnquiry = async (payload) =>
  await api.get(`/getSingleEnquiry?id=${payload?.id}`);

// update read status
export const updateReadStatus = async (payload) =>
  await api.post(`/updateReadStatus`, payload);

export const getAnnouncements = async (payload) =>
  await api.get(
    `/getAnnouncements?skip=${payload.skip}&limit=${payload.limit}`
  );

// announcements
// add announcement
export const sendAnnouncement = async (payload) =>
  await api.post(`/sendAnnouncement`, payload);

// view announcement on id
export const getAnnouncement = async (payload) =>
  await api.get(`/getAnnouncement?_id=${payload?.id}`);
