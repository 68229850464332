import React, { useEffect, useState } from 'react';
import { Card, Typography } from '@material-tailwind/react';
import NoDataFound from '../../../components/common/NoDataFound';
import ContainerHeading from '../../../components/common/container-heading/ContainerHeading';
import ChatUser from './ChatUser';
import Inbox from './Inbox';
import GroupChat from './GroupChat';
import { socket } from '../../../utils/socket';
import { ADMIN, SUB_ADMIN } from '../../../constants/constant';
import { useSelector } from 'react-redux';

const LiveChat = () => {
  const [conversations, setConversations] = useState([]);
  const sender_type = SUB_ADMIN;
  const { auth } = useSelector((state) => state.auth);
  const sender = auth?._id || '';
  const [conversationList, setConversationList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [group, setGroup] = useState({});

  useEffect(() => {
    socket.on('connection', (data) => console.log('socket_connected', data));
    // console.log(socket)
    getChatList();
    console.log(getConversation());

    socket.on('getConversationList', (data) => {
      console.log('getConversationList', data);
      // if(data?.result){
      //   setConversationList(data?.result || [])
      // }
    });

    socket.on('getChatList', (data) => {
      console.log('getChatList', data);
      if (data?.result) {
        setChatList(data?.result || []);
      }
    });

    // socket.on("getChatList", (data) => setConversations(data || []))

    // return () => socket.
  }, []);

  const getChatList = () => {
    if (socket && sender_type && sender) {
      socket.emit('getChatList', { sender_type, sender });
    }
  };

  console.log({ socket, sender_type, sender });

  const getConversation = () => {
    if (socket && sender_type && sender) {
      socket.emit('getConversationList', { sender_type, sender });
    }
  };
  return (
    <>
      <div className='chat-window'>
        <div className='conversations'>
          <div className='search'>
            <div className='search-bar'>
              <img src='/asset/search.svg' />
              <input placeholder='Search' />
            </div>
          </div>

          <div className='user-name-wrapper'>
            <ChatUser sender={sender} setGroup={setGroup} chatList={chatList} />
            <GroupChat />
          </div>
        </div>
        <div className='chatlist'>
          <div className='chat-header'>
            <div className='user-name-wrap'>
              <div className='user-img'>
                <img src='/asset/chat-user.svg' />
                <div className='green'>
                  <img src='/asset/Oval.svg' />
                </div>
              </div>
              <div className='user-txt'>
                <h5>{group?.users?.find((item) => item?._id !== sender)?.name || 'Guest User'}</h5>
                <p>Online</p>
              </div>
            </div>
            <div className='header-icon'>
              <div className='search'>
                <div className='search-bar'>
                  <img src='/asset/search.svg' />
                  <input placeholder='Search' />
                </div>
              </div>
              <div className='audio'>
                <img src='/asset/audio.svg' />
              </div>
              <div className='audio'>
                <img src='/asset/vedio.svg' />
              </div>
            </div>
          </div>
          <Inbox group={group} sender={sender} sender_type={sender_type} />
        </div>
      </div>
    </>
  );
};

export default LiveChat;
