import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "../components/MainLayout";
import OutletPage from "../components/OutletPage";

const PrivateRouteIndependent = ({ children }) => {
  const data = useSelector(state => state.auth)
  const isLogin = data?.auth?.token;
  if (!isLogin) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default PrivateRouteIndependent;