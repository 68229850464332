import React, { useEffect, useState } from "react";
import Error from "../../../components/common/Error";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { set, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import CustomLabel from "../../dashboard/user-mgmt/CustomLabal";
import {
  getAllLocation,
  onAddRoutes,
  onUpdateRoutes,
  onviewRoutes,
} from "../../../api/flight-management/flight";
import { Button } from "@material-tailwind/react";
import { errorToast, successToast } from "../../../utils/toast";
import PopUpLayout from "./../../dashboard/user-mgmt/PopUpLayout";

const routeSchema = yup.object().shape({
  route_name: yup
    .string()
    .required("Route Name is required")
    .max(50, "Max limit reached"),
  toCity: yup.string().required("This field is required"),
  fromCity: yup.string().required("This field is required"),
});

const AddRoute = ({ setOpen, editable, id, type, locationList }) => {
  useEffect(() => {
    if (editable) {
      viewRoutes();
    }
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(routeSchema),
  });

  const viewRoutes = async () => {
    let payload = {
      id,
    };
    const response = await onviewRoutes(payload);
    if (response.status_code === 200) {
      Object.keys(response?.data).forEach((key) => {
        if (key === "fromCity" || key === "toCity") {
          locationList.forEach((loc) => {
            if (loc?.city_name === response?.data[key]) {
              setValue(key, loc?._id);
            }
          });
        } else {
          setValue(key, response?.data[key]);
        }
      });
    } else {
      errorToast(response?.message || "Something Went Wrong");
    }
  };

  const addRoutes = async (values) => {
    if (values?.fromCity !== values.toCity) {
      let payload = {};
      Object.keys(values).forEach((key) => {
        payload[key] = values[key];
      });
      const response = await onAddRoutes(payload);
      if (response.status_code === 200) {
        // navigate("/dashboard/user-management")
        setOpen(false);
        successToast(response?.message);
      } else {
        errorToast(response?.message || "Something Went Wrong");
      }
    } else {
      errorToast("From city and to city cannot be same");
    }
  };

  const updateRoutes = async (values) => {
    if (values?.fromCity !== values.toCity) {
      let payload = {
        route_id: id,
      };
      Object.keys(values).forEach((key) => {
        if (key === "toCity" || key === "fromCity" || key === "route_name") {
          payload[key] = values[key];
        }
      });
      const response = await onUpdateRoutes(payload);
      if (response.status_code === 200) {
        successToast(response?.message);
        setOpen(false);
      } else {
        errorToast(response?.message || "Something Went Wrong");
      }
    } else {
      errorToast("From city and to city cannot be same");
    }
  };

  const onSubmit = handleSubmit((values) => {
    if (editable) {
      updateRoutes(values);
    } else {
      addRoutes(values);
    }
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="w-[100%] mt-3 ">
          <CustomLabel label={"Route Name"} />
          <input
            className="form-control"
            type="text"
            placeholder="Enter Route Name"
            {...register("route_name")}
            disabled={type === "view" ? true : false}
          />
          <Error
            error={errors?.route_name}
            message={errors?.route_name?.message}
          />
        </div>
        <div className="w-[100%]  items-end mt-3">
          <CustomLabel
            label={type === "view" ? "From City" : "Choose From City"}
          />

          <div className="flex flex-col items-end ">
            <Form.Select
              className="form-control"
              aria-label="Choose From City"
              // onChange={(e) => setStatus(e.target.value)}
              {...register("fromCity")}
              disabled={type === "view" ? true : false}
            >
              <option value="">Choose From City</option>
              {locationList?.map((item, index) => (
                <option value={item?._id}>{item?.city_name}</option>
              ))}
              {/* <option value="Cate">Cat</option> */}
            </Form.Select>
            <Error
              error={errors?.fromCity}
              message={errors?.fromCity?.message}
            />
          </div>
        </div>
        <div className="w-[100%]  items-end mt-3 ">
          <CustomLabel label={type === "view" ? "To City" : "Choose To City"} />

          <div className="flex flex-col items-end ">
            <Form.Select
              className="form-control"
              aria-label="Choose State"
              // onChange={(e) => setStatus(e.target.value)}
              {...register("toCity")}
              disabled={type === "view" ? true : false}
            >
              <option value="">Choose To City</option>
              {locationList?.map((item, index) => (
                <option value={item?._id}>{item?.city_name}</option>
              ))}
              {/* <option value="Cate">Cat</option> */}
            </Form.Select>
            <Error error={errors?.toCity} message={errors?.toCity?.message} />
          </div>
        </div>
        {type !== "view" && (
          <>
            <div className="flex mt-3 px-5">
              <div className="px-2">
                <Button
                  onClick={() => setOpen(false)}
                  size="sm"
                  variant="outlined"
                  className=""
                >
                  Cancel
                </Button>
              </div>
              <div className="w-100 ">
                <Button type="submit" size="sm" variant="filled" className="">
                  Apply
                </Button>
              </div>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default AddRoute;
