import React, { useEffect, useRef, useState } from "react";
import CustomerManagementInput from "../../CustomerManagementInput";
import CustomLabel from "../../CustomLabal";
import SelectWithChips from "../../SelectWithChips";
import { useForm, Controller } from "react-hook-form";
import ReactPhoneInput from "react-phone-input-2";
import parsePhoneNumber from "libphonenumber-js";
import { Country } from "country-state-city";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ImageUpload from "../../../../../../components/common/ImageUpload";
import Error from "../../../../../../components/common/Error";
import Form from "react-bootstrap/Form";
import CustomEditor from "../../../../../../components/common/CustomEditor";
// import Button from "react-bootstrap/Button";
import { Button } from "@material-tailwind/react";
import {
  getAllExpertise,
  onAddUsers,
  onEditUsers,
  viewAllUsers,
} from "../../../../../../api/users";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Select as MUISelect } from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getDateFormat } from "../../../../../../utils/moment";
import { errorToast } from "../../../../../../utils/toast";
import {
  imageValidation,
  uploadImageTemplate,
} from "../../../../../../helper/index";
import { images } from "../../../../../../constants/images/image";
let countryCode = "IN";

const userSchema = yup.object().shape({
  fullName: yup
    .string()
    .matches(
      /^[a-zA-Z\s']+$/,
      "Full Name should only contain letters and spaces"
    )
    .required("Full Name is required")
    .max(50, "Max 50 characters allwoed")
    .min(2, "Min 2 characters allowed"),
  preferredFirstName: yup
    .string()
    .matches(/^[a-zA-Z\s']+$/, "Name should only contain letters and spaces")
    .required("Name is required")
    .max(50, "Max 50 characters allwoed")
    .min(2, "Min 2 characters allowed"),
  gender: yup.string().required("Gender is required"),
  status: yup.string().required("Status is required"),
  email: yup
    .string()
    .email("This email is not valid")
    .required("Email is required"),
  phone_code: yup.string(),
  occupation: yup
    .string()
    .required("This field is required")
    .max(100, "Max 100 characters"),
  industries: yup
    .array()
    .of(yup.string())
    .min(1, "Minimum 1 required")
    .required("Min 1 is required"),
  annual_income: yup
    .number()
    .required("This field is required")
    .transform((originalValue, originalObject) => {
      // Convert empty string to NaN
      return originalObject.annual_income === "" ? NaN : originalValue;
    })
    .typeError("Annual income must be a number")
    .min(100000, "Annual income must be at least 1,00,000")
    .max(500000, "Annual income must not exceed 5,00,000"),
  birthday: yup.string().required("Birthday is required"),
  profile_pic: yup.mixed().required("Profile Image is required"),
  phone: yup
    .string()
    .required("Phone is required")
    .test("Check Indian Number", function () {
      let number = this.parent["phone"];
      if (!number) return false;
      const digits = number.replace(/\D/g, "") || "";
      const phoneNumber = parsePhoneNumber(digits, countryCode);
      return !!phoneNumber ? phoneNumber.isValid() : false;
    }),
  // code_phone: yup.string().default(""),
  // dial_code: yup.string().default(""),
});

const CustomerDetails = ({ setUserDetails }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const phoneInput = useRef(null);
  const { id } = useParams();
  const [statusState, setStatusState] = useState();
  const [genderState, setGenderState] = useState("active");
  const [expertiseData, setExpertiseData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [userData, setUserData] = useState({});
  const editRoute =
    location?.pathname?.indexOf(
      `/dashboard/user-management/user-list/edit-user-list`
    ) > -1
      ? true
      : false;
  const viewRoute =
    location?.pathname?.indexOf(
      `/dashboard/user-management/user-list/view-user-list`
    ) > -1
      ? true
      : false;

  useEffect(() => {
    if (editRoute || viewRoute) {
      viewUsers();
    }
    dropdownExpertise();
  }, []);

  useEffect(() => {
    let tempCountries = Country.getAllCountries();
    let newCountries = [];
    tempCountries.forEach((item) => {
      let obj = {
        value: item.phonecode,
        label: `+${item.phonecode} ${item.name} ${item.flag}`,
      };
      newCountries.push(obj);
    });
    // console.log(tempCountries);
    // setCountries(newCountries);
    // setSelectedCountry(newCountries[0]);
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const addUser = async (values) => {
    let formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === "profile_pic") {
        formData.append("profile_pic", values.profile_pic[0]);
      } else if (key === "phone_code") {
        formData.append(
          "phone_code",
          values?.phone_code?.indexOf("+") > -1
            ? values?.phone_code
            : `+${values?.phone_code}`
        );
      } else if (key === "industries") {
        formData.append("industries", JSON.stringify(values.industries));
      } else {
        formData.append(key, values[key]);
      }
    });
    formData.append("country_code", countryCode);
    const response = await onAddUsers(formData);
    if (response.status_code === 200) {
      navigate("/dashboard/user-management");
    } else {
      errorToast(response?.message || "Something Went Wrong");
    }
  };

  const dropdownExpertise = async () => {
    const response = await getAllExpertise();
    if (response.status_code === 200) {
      setExpertiseData(
        response?.data?.map((item) => ({
          ...item,
          value: item._id,
          label: item.name,
        })) || []
      );
    }
  };

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    let selectedCityData = selected.flatMap((item) => item.value);
    setValue("industries", selectedCityData);
    trigger("industries");
  };

  console.log(getValues());
  const viewUsers = async () => {
    let payload = {
      id,
    };
    const response = await viewAllUsers(payload);
    if (response?.status_code == 200) {
      setUserData(response?.data || {});
      setUserDetails(response?.data);
      setStatusState(response?.data?.status);
      Object.keys(response?.data).forEach((key) => {
        if (key === "industries") {
          let tempArr = [];
          response?.data?.industries?.map((industry, ind) => {
            tempArr.push({ label: industry.name, value: industry.id });
          });
          setSelectedOptions(tempArr);

          setValue(
            "industries",
            response?.data?.industries.flatMap((item) => item.id) || []
          );
        } else if (key === "birthday") {
          setValue(key, getDateFormat(response?.data[key], "YYYY-MM-DD"));
        } else if (key === "gender") {
          if (response?.data[key] === "M") {
            setValue(key, "Male");
          } else if (response?.data[key] === "F") {
            setValue(key, "Female");
          } else {
            setValue(key, "Other");
          }
        } else if (key === "countryCode") {
          setValue("country_code", response?.data[key]);
          countryCode = response?.data[key];
        } else {
          setValue(key, response?.data[key]);
        }
      });
    }
  };

  const editUser = async (values) => {
    let formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === "profile_pic") {
        formData.append(
          "profile_pic",
          typeof values.profile_pic === "string"
            ? values.profile_pic
            : values.profile_pic[0]
        );
      }
      //
      else if (key === "phone_code") {
        formData.append(
          "phone_code",
          values?.phone_code?.indexOf("+") > -1
            ? values?.phone_code
            : `+${values?.phone_code}`
        );
      } else if (key === "industries") {
        formData.append("industries", JSON.stringify(values.industries));
      } else {
        formData.append(key, values[key]);
      }
    });
    const response = await onEditUsers(formData);
    if (response.status_code === 200) {
      // successToast(response?.message || "")
      navigate("/dashboard/user-management");
    } else {
      // errorToast(response?.message || "Something Went Wrong")
    }
  };

  const onSubmit = handleSubmit((values) => {
    if (editRoute) {
      editUser(values);
    } else {
      addUser(values);
    }
  });

  return (
    <>
      <div className="py-10 pt-0 h-[500px] tab_scroll overflow-y-scroll">
        <form onSubmit={onSubmit}>
          <div className="px-2 pt-0">
            <h2 className="text-[20px] font-bold text-[#303637] py-4">
              Customer Details
            </h2>
            <div className="flex justify-between">
              <div>
                <h3 className="text-[#000000] font-bold text-[18px] ml-2">
                  Profile Photo
                </h3>
                {/* <img
                className="rounded-full w-[250px]] h-[250px] bg-cover object-cover"
                src="https://headshots-inc.com/wp-content/uploads/2023/02/Acting-Headshot-Photography-Example-1.jpg"
                alt=""
              /> */}
                <ImageUpload
                  className="profile_Image"
                  inputId={`profileimage`}
                  imgId={`profilepic`}
                  src={
                    userData?.profilePic ||
                    userData?.profile_pic ||
                    images.genericUser
                  }
                  // src={citydetails || uploades}
                  // className="w-100"
                  name={`profile_pic`}
                  setValue={setValue}
                  register={register}
                  type="upload"
                  trigger={trigger}
                />
                <Error
                  error={errors?.profile_pic}
                  message={errors?.profile_pic?.message}
                />
              </div>
              {/* below Anima AI generated code */}
              <div>
                {/* 1st comp */}
                <div className="flex flex-col h-[145px] items-start gap-[12px]  py-0 relative">
                  <div className="relative w-fit mt-[-1.00px] font-h4-bold text-black text-[length:var(--h4-bold-font-size)] tracking-[var(--h4-bold-letter-spacing)] leading-[var(--h4-bold-line-height)] [font-style:var(--h4-bold-font-style)] font-semibold">
                    Current Subscription
                  </div>
                  <div className="w-[401px] items-start gap-[14px] px-[16px] py-[8px] flex-[0_0_auto] mr-[-5.00px] bg-white rounded-[12px] overflow-hidden border border-solid border-greymid flex relative">
                    <div className="flex-col w-[369px] items-start gap-[16px] flex relative">
                      <h3 className="relative text-[#242424] self-stretch mt-[-1.00px] [font-family:'Roboto_Flex-Bold',Helvetica] font-bold text-neutralsdarkest text-[24px] tracking-[0] leading-[normal]">
                        Unlimited
                      </h3>
                      <div className="flex-col items-start gap-[12px] self-stretch w-full flex-[0_0_auto] flex relative">
                        <div className="items-end gap-[4px] self-stretch w-full flex-[0_0_auto] flex relative">
                          <h6 className="relative text-[#242424] w-fit mt-[-1.00px] [font-family:'Roboto_Flex-Bold',Helvetica] font-bold text-neutralsdarkest text-[16px] tracking-[0] leading-[normal] whitespace-nowrap">
                            $2,999
                          </h6>
                          <h6 className="relative flex-1 [font-family:'Roboto-Regular',Helvetica] font-normal text-greymore-midtones text-[12px] tracking-[0] leading-[normal]">
                            per month
                          </h6>
                        </div>
                        <div className="items-end gap-[6px] self-stretch w-full flex-[0_0_auto] flex relative">
                          <h6 className="relative w-fit [font-family:'Roboto_Flex-Regular',Helvetica] font-normal text-greymore-midtones text-[12px] tracking-[0] leading-[normal] whitespace-nowrap">
                            Next renewal date
                          </h6>
                          <h6 className="relative text-[#242424] w-fit mt-[-1.00px] [font-family:'Roboto_Flex-Bold',Helvetica] font-bold text-grey-800 text-[14px] tracking-[0] leading-[normal] whitespace-nowrap">
                            2024/2/01
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 2nd comp */}
                <div className="flex mt-4 flex-col h-[145px] items-start gap-[12px]  py-0 relative">
                  <div className="relative w-fit mt-[-1.00px] font-h4-bold font-[number:var(--h4-bold-font-weight)] text-black text-[length:var(--h4-bold-font-size)] tracking-[var(--h4-bold-letter-spacing)] leading-[var(--h4-bold-line-height)] [font-style:var(--h4-bold-font-style)]">
                    Identity
                  </div>
                  <div className="flex w-[401px] items-start gap-[14px] px-[16px] py-[8px] relative bg-white rounded-[12px] overflow-hidden border border-solid border-greymid">
                    <div className="flex-col w-[369px] items-start gap-[16px] flex relative">
                      <div className="items-center gap-[4px] self-stretch w-full flex-[0_0_auto] flex relative">
                        <span className="px-3 py-1 rounded-[100px] text-center bg-[#B9F6CA] text-[#242424]">
                          Verified
                        </span>

                        <div className="relative flex-1 [font-family:'Roboto-Regular',Helvetica] font-normal text-greymore-midtones text-[12px] tracking-[0] leading-[normal]">
                          on 2023/10/16
                        </div>
                      </div>
                      <div className="items-end gap-[6px] self-stretch w-full flex-[0_0_auto] flex relative">
                        <div className="relative w-fit [font-family:'Roboto_Flex-Regular',Helvetica] font-normal text-greymore-midtones text-[12px] tracking-[0] leading-[normal] whitespace-nowrap">
                          member since
                        </div>
                        <div className="relative text-[#242424] w-fit mt-[-1.00px] [font-family:'Roboto_Flex-Bold',Helvetica] font-bold text-grey-800 text-[14px] tracking-[0] leading-[normal] whitespace-nowrap">
                          2023/10/15
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* inputs  */}
            <div className="flex justify-between w-full gap-8 pt-5">
              <div className="w-full space-y-8">
                <div className="w-full form-input">
                  <CustomLabel label={"Full name"} />

                  {/* <CustomerManagementInput /> */}
                  <input
                    className="form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] "
                    type="text"
                    placeholder="Enter Full Name"
                    {...register("fullName")}
                    disabled={viewRoute ? true : false}
                  />
                  <Error
                    error={errors?.fullName}
                    message={errors?.fullName?.message}
                  />
                </div>
                <div className="">
                  <CustomLabel label={"Phone number"} />
                  <Controller
                    control={control}
                    name="phone"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactPhoneInput
                        ref={phoneInput}
                        disabled={viewRoute ? true : false}
                        value={`${userData?.phone_code}${userData?.phone}`}
                        onChange={(value, data, event, formattedValue) => {
                          // setDialCode(data.dialCode);
                          // setCountryCode(data.countryCode.toUpperCase());
                          // setValue("phone_code", data?.dialCode)
                          onChange(value.slice(data.dialCode.length));
                        }}
                        inputClass="intl-tel-input-custom w-100 country_common_input !h-[60px] rounded-xl"
                        inputExtraProps={{
                          required: true,
                          autoFocus: true,
                        }}
                        isValid={(value, country) => {
                          // setValue("code_phone", country.iso2);
                          // setValue("dial_code", country.dialCode);
                          setValue("phone_code", country.dialCode);
                          countryCode = country.iso2.toUpperCase();
                          if (value.match(/12345/)) {
                            return (
                              "Invalid value: " + value + ", " + country.name
                            );
                          } else if (value.match(/1234/)) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                        country={"in"}
                        countryCodeEditable={false}
                        specialLabel={"Player Mobile Number"}
                        enableSearch={true}
                      />
                    )}
                  />
                  <Error
                    error={errors?.phone}
                    message={errors?.phone?.message}
                  />
                </div>
                {/* <div className="w-full">
                  <CustomLabel label={"Phone number"} />
                  <PhoneInput
                    country={'us'}
                    value={""}
                    onChange={phone => console.log(phone)}
                  />
                  <input
                    className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                    type="text"
                    placeholder='Enter Mobile No.'
                    {...register("phone")}
                    autoFocus="off"
                  />
                  <Error error={errors?.phone} message={errors?.phone?.message} />

                </div> */}
                <div className="w-full">
                  <CustomLabel label={"Birthday"} />
                  <input
                    className="form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] "
                    type="date"
                    placeholder="Enter Your BirthDay"
                    {...register("birthday")}
                    disabled={viewRoute ? true : false}
                    max={new Date().toISOString().split("T")[0]}
                  />
                  <Error
                    error={errors?.birthday}
                    message={errors?.birthday?.message}
                  />
                </div>
              </div>
              <div className="w-full space-y-8">
                <div className="w-full">
                  <CustomLabel label={"Preferred first name"} />

                  <input
                    className="form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] "
                    type="text"
                    placeholder="Enter First Name"
                    {...register("preferredFirstName")}
                    disabled={viewRoute ? true : false}
                  />
                  <Error
                    error={errors?.preferredFirstName}
                    message={errors?.preferredFirstName?.message}
                  />
                </div>
                <div className="w-full">
                  <CustomLabel label={"Email address"} />

                  <input
                    className="form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] "
                    type="text"
                    placeholder="Enter email"
                    {...register("email")}
                    disabled={viewRoute ? true : false}
                    // autoFocus="off"
                  />
                  <Error
                    error={errors?.email}
                    message={errors?.email?.message}
                  />
                </div>{" "}
                <div className="w-full">
                  <CustomLabel label={"Gender"} />
                  <Form.Select
                    className="form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px]"
                    aria-label="Gender"
                    disabled={viewRoute ? true : false}
                    {...register("gender", {
                      onChange: (e) => {
                        setGenderState(e.target.value);
                      },
                    })}
                  >
                    <option value="">Choose Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                  {/* <input
                    className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                    type="text"
                    placeholder='Enter gender'
                    {...register("gender")}
                    autoFocus="off"
                  /> */}
                  <Error
                    error={errors?.gender}
                    message={errors?.gender?.message}
                  />
                </div>{" "}
              </div>
            </div>
            <div
              id="AccountStatus"
              className="flex-row justify-between  h-70 items-start pt-6  rounded-lg"
            >
              {editRoute ? (
                <div className="w-50">
                  <CustomLabel label={"Account Status"} />
                  <Form.Select
                    className="form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px]"
                    aria-label="Account Status"
                    disabled={viewRoute ? true : false}
                    // onChange={(e) => setStatusState(e.target.value)}
                    {...register("status", {
                      onChange: (e) => {
                        setStatusState(e.target.value);
                      },
                    })}
                  >
                    <option value="">Choose Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Form.Select>
                  <Error
                    error={errors?.status}
                    message={errors?.status?.message}
                  />
                </div>
              ) : (
                <div className="w-50">
                  <CustomLabel label={"Account Status"} />
                  <Form.Select
                    className="form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px]"
                    aria-label="Account Status"
                    disabled={viewRoute ? true : false}
                    {...register("status", {
                      onChange: (e) => {
                        setStatusState(e.target.value);
                      },
                    })}
                  >
                    <option value="">Choose Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Form.Select>
                  <Error
                    error={errors?.status}
                    message={errors?.status?.message}
                  />
                </div>
              )}
              {statusState !== "active" && (
                <div className="w-full pt-3">
                  <CustomEditor
                    disabled={viewRoute ? true : false}
                    onChange={(value) => {
                      setValue("reason", value.target.getContent());
                    }}
                  />
                </div>
              )}
            </div>
            <div className="space-y-8 mt-8 pb-10">
              <h2 className="text-[20px] font-bold text-[#303637]">
              Demographic Information
              </h2>
              <div className="w-full">
                <CustomLabel label={"What is your profession?"} />

                <input
                  className="form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] "
                  type="text"
                  disabled={viewRoute ? true : false}
                  {...register("occupation")}
                  // autoFocus="off"
                />
                <Error
                  error={errors?.occupation}
                  message={errors?.occupation?.message}
                />
              </div>{" "}
              {!viewRoute && (
                <div className="w-full">
                  <CustomLabel
                    label={"What industry(ies) is your area of expertise?"}
                  />
                  <Select
                    disabled={viewRoute ? true : false}
                    options={expertiseData}
                    isMulti
                    closeMenuOnSelect={false}
                    value={selectedOptions}
                    onChange={handleChange}
                    placeholder="Select your expertise"
                    className="basic-multi-select multiple-select-2"
                    classNamePrefix="select"
                    defaultValue=""
                    // onKeyDown={handleKeyDown}
                  />
                  <Error
                    error={errors?.industries}
                    message={errors?.industries?.message}
                  />
                  {/* <SelectWithChips /> */}
                </div>
              )}
              <div className="w-full">
                <CustomLabel
                  label={
                    "What do you estimate is your total annual financial intake?"
                  }
                />

                <input
                  disabled={viewRoute ? true : false}
                  className="form-control rounded-xl !border-[#9A9A9A]  !h-[56px] !text-[#9A9A9A] text-[16px] "
                  type="number"
                  {...register("annual_income")}
                  // autoFocus="off"
                />
                <Error
                  error={errors?.annual_income}
                  message={errors?.annual_income?.message}
                />
              </div>{" "}
            </div>
            {!viewRoute && (
              <div className="flex ml-80  px-20">
                <div className="px-3">
                  <Button
                    onClick={() => navigate(-1)}
                    size="sm"
                    variant="outlined"
                    className=""
                  >
                    Cancel
                  </Button>
                </div>
                <div className="w-100">
                  <Button type="submit" size="sm" variant="filled" className="">
                    Apply
                  </Button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default CustomerDetails;
