import api from "../interceptor";

// get all navigations
export const getAllNavLocations = async (payload) => await api.get(`/getAllNavLocations`);

// viewAllSavedLocation
export const viewAllSavedLocation = async (payload) => await api.get(`/viewAllSavedLocation`);

// update nav location
export const addOrUpdateSavedLocation = async (payload) => await api.post(`/addOrUpdateSavedLocation`,payload);

