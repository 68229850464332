import React, { useState } from "react";

export default function PopUpLayout({ showModal, setShowModal, children }) {
  const [name, setName] = useState("");
  const [AlertMsg, setAlertMsg] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
    console.log("name", name);
  };

  const handleSubmit = (e) => {
    setAlertMsg("Please wait");

    setTimeout(() => {
      setAlertMsg("Form Submit Successully");
    }, 1000);
    setTimeout(() => {
      setAlertMsg("");
      setShowModal(false);
    }, 2000);
  };

  return (
    <>
      {!showModal ? (
        <>
          <div className="modal-pop justify-center items-center !z-80 flex overflow-x-hidden pt-[10px] duration-1000 overflow-y-auto fixed inset-0 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl  ">
              {children}
            </div>
          </div>
          {/* <div className="opacity-50 fixed inset-0 z-40 bg-black"></div> */}
        </>
      ) : null}
    </>
  );
}
